import React from 'react'
import firebase from 'firebase/app'
import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Grid } from '@material-ui/core'
import { useState } from 'react'
import { useEffect } from 'react'
import { ValidatorForm } from 'react-material-ui-form-validator'
import BirthInfosForm from '../ConsultantProfile/ConsultantBooking/components/BirthInfosForm'
import { useDispatch, useSelector } from 'react-redux'
import { userState, setUserState } from '../../api/userSlice'

export default function EditBirthInfosDialog(props) {
    const {
      open,
      handleClose,
      bookingID,
      birthInfosState,
      setBirthInfoState,
      sendEmail,
      consultantID,
      clientEmail
    } = props
    const userID = useSelector(userState).user?.userID
    const dispatch = useDispatch()

    const [newBirthInfosState, setNewBirthInfoState] = useState(birthInfosState)
    const [disableButton, setDisableButton] = useState(false)

    useEffect(() => {
      const disable = 
        !newBirthInfosState.birthDate ||
        !newBirthInfosState.city ||
        !newBirthInfosState.country
      setDisableButton(disable)
    }, [newBirthInfosState])

    const handleUpdate = async () => {
        handleClose()

        setBirthInfoState(newBirthInfosState)

        const clientDetailsData = {
          birthDate: Math.trunc(new Date(new Date(newBirthInfosState.birthDate).setMilliseconds(0)).getTime() / 1000),
          address: `${newBirthInfosState.city}, ${newBirthInfosState.state ? `${newBirthInfosState.state}, ` : ''}${newBirthInfosState.country}`,
        }

        const userBirthInfos = {
          birthDate: new Date(newBirthInfosState.birthDate),
          city: newBirthInfosState.city,
          country: newBirthInfosState.country,
          state: newBirthInfosState.state ?? null,
        }
        if (userID) {
          firebase.app().firestore().doc(`/users/${userID}`).update({
            birthInfos: userBirthInfos
          });
        }

        if (bookingID) {
          firebase.functions().httpsCallable('updateClientDetailsBooking')({
            bookingID: bookingID,
            clientDetails: clientDetailsData
          })
        
          if (sendEmail) {
            firebase.functions().httpsCallable('sendEmailWhenUpdateBirthInfos')({
              clientID: userID,
              consultantID: consultantID,
              clientEmail: clientEmail,
              bookingID: bookingID,
              clientDetails: {
                ...clientDetailsData,
                birthDate: new Date(newBirthInfosState.birthDate)
              }
            })
          }
        }

        dispatch(setUserState({
          birthInfos: userBirthInfos
        }))
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Birth Details</DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                <DialogContentText>
                    Fill in these fields to update your birth details:
                </DialogContentText>
                <div className='details_client_form birth_infos_form'>
                  <ValidatorForm
                    onError={errors => console.log(errors)}
                    onSubmit={() => { return }}
                  >
                    <Grid container spacing={1}>
                      <BirthInfosForm birthInfosState={newBirthInfosState} setBirthInfoState={setNewBirthInfoState} />
                    </Grid>
                  </ValidatorForm>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="white">
                    Cancel
                </Button>
                <Button disabled={disableButton} onClick={handleUpdate} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import React, { useState } from 'react';
import 'firebase/firestore'
import { IntakeInformationContent } from './IntakeInformationContent';
import InfoPopover from '../../components/InfoPopover';

const IntakeInformation = (props) => {
  const { bookingID } = props

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)

  const handleInfo = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className='intake_info'>
      <InfoPopover
        open={open}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleInfo={handleInfo}
      >
          <IntakeInformationContent bookingID={bookingID} />
      </InfoPopover>
    </div>
  )
};

export default IntakeInformation;

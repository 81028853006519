import React from 'react';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextValidator } from 'react-material-ui-form-validator';
import { localeToCurrencyName, SYMBOLS } from '../../../../utils/utils';
import Country from '../../../SignIn/components/country/Country';

const AddBankAccount = (props) => {
  const { isUS, isAUS, bankAccountData, setBankAccountdata } = props;

  return (
    <ValidatorForm
      onError={errors => console.log(errors)}
      onSubmit={() => { return }}
    >
      <TextValidator
        validators={['required']}
        errorMessages={['This field is required']}
        autoFocus
        margin="dense"
        label="First Name"
        type="text"
        fullWidth
        value={bankAccountData.firstName}
        onChange={(e) => {
          const { value } = e.target
          setBankAccountdata(oldBankAccountData => ({
            ...oldBankAccountData,
            firstName: value
          }))
        }}
        required
      />
      <TextValidator
        validators={['required']}
        errorMessages={['This field is required']}
        margin="dense"
        label="Last Name"
        type="text"
        fullWidth
        value={bankAccountData.lastName}
        onChange={(e) => {
          const { value } = e.target
          setBankAccountdata(oldBankAccountData => ({
            ...oldBankAccountData,
            lastName: value
          }))
        }}
        required
      />
      <TextField
        margin="dense"
        label={isUS || isAUS ? "Account Number" : "IBAN"}
        type="text"
        fullWidth
        value={bankAccountData.accountNumberIBAN}
        onChange={(e) => {
          const { value } = e.target
          setBankAccountdata(oldBankAccountData => ({
            ...oldBankAccountData,
            accountNumberIBAN: value
          }))
        }}
        required
      />
      {isUS &&
        <TextField
          margin="dense"
          label="ACH Routing Number"
          type="text"
          fullWidth
          value={bankAccountData.routingNumber}
          onChange={(e) => {
            const { value } = e.target
            setBankAccountdata(oldBankAccountData => ({
              ...oldBankAccountData,
              routingNumber: value
            }))
          }}
          required
        />
      }
      <TextField
        margin="dense"
        label="SWIFT"
        type="text"
        fullWidth
        value={bankAccountData.SWIFT}
        onChange={(e) => {
          const { value } = e.target
          setBankAccountdata(oldBankAccountData => ({
            ...oldBankAccountData,
            SWIFT: value
          }))
        }}
        required
      />
      {isUS ?
        <>
          <TextValidator
            validators={['required']}
            errorMessages={['This field is required']}
            margin="dense"
            label="Address"
            type="text"
            fullWidth
            value={bankAccountData.address}
            onChange={(e) => {
              const { value } = e.target
              setBankAccountdata(oldBankAccountData => ({
                ...oldBankAccountData,
                address: value
              }))
            }}
            required
          />
          <TextValidator
            validators={['required']}
            errorMessages={['This field is required']}
            margin="dense"
            label="Bank Name"
            type="text"
            fullWidth
            value={bankAccountData.bankName}
            onChange={(e) => {
              const { value } = e.target
              setBankAccountdata(oldBankAccountData => ({
                ...oldBankAccountData,
                bankName: value
              }))
            }}
            required
          />
        </>
      :
        <>
          <Country
            required={true}
            selectedCountry={bankAccountData.country}
            countrySelected={(country) => {
                const code = country?.code
                setBankAccountdata(oldBankAccountData => ({
                  ...oldBankAccountData,
                  country: country,
                  currency: code && localeToCurrencyName(code.toLowerCase()) ? localeToCurrencyName(code.toLowerCase()) : SYMBOLS.EUR
              }))
            }}
            withoutBackground
            label="Country"
          />
          <TextValidator
            validators={['required']}
            errorMessages={['This field is required']}
            margin="dense"
            label="Currency"
            type="text"
            fullWidth
            value={bankAccountData.currency ?? ''}
            onChange={(e) => {
              const { value } = e.target
              setBankAccountdata(oldBankAccountData => ({
                ...oldBankAccountData,
                currency: value
              }))
            }}
            required
          />
        </>
      }
    </ValidatorForm>
  );
}

export default AddBankAccount
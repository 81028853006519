import { useEffect, useState } from "react";
import firebase from 'firebase/app'
import { COMMON_ROUTES } from "../../utils/routes";
import { useRef } from "react";
import { updateSessionLog } from "../../utils/utils";
import useCookieTheme from "./useCookieTheme";
import { useDispatch, useSelector } from "react-redux";
import { userState } from "../../api/userSlice";
import { sessionState, setActiveSession, setEndCallReason, setIsCallActive } from "../../api/sessionSlice";

export default function useSessionHook() {
  const { changePage } = useCookieTheme()
  const dispatch = useDispatch()
  const dbUser = useSelector(userState).user
  const isConsultant = useSelector(userState).user?.consultant
  const userID = useSelector(userState).user?.userID
  const perMinSession = useSelector(sessionState).activeSession
  const isCallActive = useSelector(sessionState).isCallActive
  const endCallReason = useSelector(sessionState).endCallReason

  const sessionRejected = useRef(null)
  const [curPath, setCurPath] = useState(null);
  const [missedCallAlert, setMissedCallAlert] = useState(null);
  const [isRejected, setIsRejected] = useState(false);

  useEffect(() => {
    if (!isConsultant || perMinSession || (isCallActive && !endCallReason)) {
      return;
    }

    const toRet = firebase
      .app()
      .firestore()
      .collection("sessions")
      .where("consultantID", "==", userID)
      .where("status", "==", "pending")
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs;
        if (docs.length)  {
          if (!perMinSession) {
            setTimeout(() => {
              dispatch(setActiveSession(JSON.stringify({
                ...docs[0].data(),
                id: docs[0].id
              })))
              sessionRejected.current = docs[0]

              firebase.app().firestore().doc("sessions/" + docs[0].id).onSnapshot(session => {
                const status = session.data()?.status
                const bookingID = session.data()?.bookingID
                if (status === 'rejected') {
                  if (bookingID) {
                    dispatch(setActiveSession(null))
                    sessionRejected.current = null
                  } else {
                    setIsRejected(true)
                  }
                } else if (status === 'active') {
                  sessionRejected.current = null
                }
              })
            }, 3000);
          }
        }
      });

    return () => {
      console.log("Cancelling listener!");
      toRet();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallActive, userID, endCallReason, perMinSession, isConsultant]);

  useEffect(() => {
    if (isRejected) {
      if (sessionRejected.current) {
        setCurPath({
          uuid: sessionRejected.current.data().callUUID,
          clientName: sessionRejected.current.data().clientFullName,
          isBooking: Boolean(sessionRejected.current.data().bookingID)
        })
      } else {
        setCurPath(null)
      }
      sessionRejected.current = null
      dispatch(setActiveSession(null))
      setIsRejected(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCallActive, isRejected, perMinSession])

  const acceptPPMCallTapped = () => {
    dispatch(setIsCallActive(true))
    
    updateSessionLog(perMinSession.id, `[SESSION - ${perMinSession.id}] ${new Date()} - Consultant ${userID} answer to ${perMinSession.isChatCall ? 'text chat' : perMinSession.videoEnabled ? 'video' : 'audio'} call`)

    changePage({
      pathname: COMMON_ROUTES.ACTIVE_CALL,
      search: `isConsultant=true&from=${window.location.pathname}`
    });
  };

  useEffect(() => {
    if (curPath?.uuid) {
      showNoMoreCallAlert(curPath);
    }
  }, [perMinSession, curPath]);

  const showNoMoreCallAlert = (details) => {
    console.log("[NDEBUG] NO MORE CALL!!!");
    setMissedCallAlert(details);
  };

  const pingTapped = () => {
    const toSend = {
      consultantName: `${dbUser?.name} ${dbUser?.surname}`,
      consultantExpertise: `${dbUser?.consultancyType}`,
      callUUID: missedCallAlert.uuid,
    };
    console.log("[NDEBUG] sending:");
    console.log(toSend);
    firebase
      .app()
      .functions("europe-west1")
      .httpsCallable("missedCallAvailableNotification")(toSend)
      .then(() => {
        console.log("[NDEBUG] Call success!");
      })
      .catch(() => {
        console.log("[NDEBUG] Call error!");
      });

    setMissedCallAlert(null);
    dispatch(setEndCallReason({
      severity: 'success',
      message: 'The client has been notified that you are now available to be called.',
    }))
    setCurPath(null)
  };

  return {
    acceptPPMCallTapped,
    missedCallAlert,
    setMissedCallAlert,
    setCurPath,
    pingTapped,
  };
}

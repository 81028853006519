import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { FormControlLabel, withStyles } from '@material-ui/core'
import PerMinuteButtons from './perMinuteButtons';
import { BlurOn, StarRate } from '@material-ui/icons/'
import useCookieTheme from '../../App/useCookieTheme';
import ReviewsDetailsDialog from './ReviewsDetailsDialog';
import { userState } from '../../../api/userSlice';
import { useSelector } from 'react-redux';
import { bookingsDisabled } from '../../../utils/utils';
import firebase from "firebase/app";
import SwitchItem from '../../components/SwitchIOS';

const styles = () => ({
  profileImage: {
    borderRadius: '50%',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
  },
  flexCentered: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  }
});

const ProfileBox = (props) => {
  const {
    consultantData,
    consultantProfilePic,
    setSeverity,
    classes,
    makeAppointmentClicked
  } = props
  const { makeAppointmentButtonName, giftButtonName } = useCookieTheme()
  const isConsultant = useSelector(userState).user?.consultant
  const userID = useSelector(userState).user?.userID

  const [openReviewsDialog, setOpenReviewsDialog] = useState(false)
  const [notifyMe, setNotifyMe] = useState(false)

  const makeAppointment = (isGift) => {
    makeAppointmentClicked(isGift)
  }

  const makeAppointmentDisabled = bookingsDisabled(isConsultant, consultantData)
  const numberOfReviews = consultantData.ratingStats ?
    Object.keys(consultantData.ratingStats).reduce((a, b) => a + consultantData.ratingStats[b], 0) : 0

  useEffect(() => {
    if (consultantData && userID) {
      const existsInNotify = consultantData?.notifyWhenOnline?.find(notify => notify === userID)
      setNotifyMe(!!existsInNotify)
    }
  }, [consultantData, userID])

  const onNotifyMe = async (checked) => {
    setNotifyMe(checked);
    await firebase.functions().httpsCallable("addNotifyToExpert")({
      checked,
      consultantID: consultantData?.userID
    });
  }

  const theButton = (
    <Button
      className='home_copy_link_btn'
      size='large'
      disabled={makeAppointmentDisabled}
      onClick={() => makeAppointment(false)}
      color="primary"
      variant="contained"
    >
      {makeAppointmentButtonName ?? 'Make appointment'}
    </Button>
  )

  const giftButton = (
    <Button
      className='home_copy_link_btn gift_button'
      size='large'
      disabled={makeAppointmentDisabled}
      onClick={() => makeAppointment(true)}
      color="primary"
      variant="contained"
    >
      <BlurOn className='icon_gift_button' />
      {giftButtonName}
    </Button>
  )

  return (
    <Box className='consultantProfile_content'>
      <Box className={classes.flexCentered}>
        <div className="profile_pic">
          <img alt="" src={consultantProfilePic ?? require("../../../images/user.png")} className={`${classes.profileImage} profile_pic`} />
        </div>

        <div className="label_type_1 heading">
          {consultantData["name"]} {consultantData["surname"]}
        </div>

        <div className="label_type_2">
          {consultantData["consultancyType"]}
        </div>
        
        {consultantData.generalScore &&
          <div className='reviews_container'>
            <p className='general_score'>{Math.round(consultantData.generalScore * 10) / 10} <StarRate /></p>
            <p className='reviews' onClick={() => setOpenReviewsDialog(true)}>{numberOfReviews} Reviews</p>
          </div>
        }

        {isConsultant &&
          <p className='cannot_access_consultant'>
            You cannot access these services as you are logged in with a consultant account.
          </p>
        }

        <Box pt={2} pb={2}>
          <PerMinuteButtons data={consultantData} setSeverity={setSeverity} />
        </Box>
      </Box>

      {userID && !isConsultant && (!consultantData.online || consultantData.isBusy) &&
        <div style={{ marginBottom: 10 }}>
          <FormControlLabel
            className="navigation_avail"
            control={<SwitchItem checked={notifyMe} set={onNotifyMe} />}
            label='Notify me when consultant becomes available'
            labelPlacement="start"
          />
        </div>
      }

      <>
        <Box className={"actions " + classes.flexCentered} pb={3}>
          {theButton}
        </Box>

        <Box className={"actions " + classes.flexCentered} pb={3}>
          {giftButton}
        </Box>
      </>

      <Box className={`${classes.flexCentered} consultant_details`}>
        {consultantData.aboutMe?.length > 0 &&
          <div className='label_details_div'>
            <span className='label_type_2'>About Me</span>
            <span dangerouslySetInnerHTML={{ __html: consultantData.aboutMe.toString().replaceAll('\n', '<br />') }}></span>
          </div>
        }

        {consultantData.serviceDetails?.length > 0 && 
          <div className='label_details_div'>
            <span className='label_type_2'>Service Details</span>
            <span dangerouslySetInnerHTML={{ __html: consultantData.serviceDetails.toString().replaceAll('\n', '<br />') }}></span>
          </div>
        }
      </Box>

      {openReviewsDialog &&
        <ReviewsDetailsDialog 
          open={openReviewsDialog} 
          setOpen={setOpenReviewsDialog} 
          consultantData={consultantData}
          numberOfReviews={numberOfReviews}
        />
      }
    </Box>
  )
}

export default withStyles(styles)(ProfileBox);
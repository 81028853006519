import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import './styles.css';
import { displayAsCurrency } from '../../../../utils/utils'
import moment from 'moment'

const HistoryWithdrawalRow = (props) => {
    const teamAmount = props.data.amount
    const workerAmount = props.data.workerAmount
    const status = props.data.status
    const locale = props.data.locale

    const displayableMemberAmount = () => {
        if(!workerAmount) { return "Not available" }
        return displayAsCurrency(workerAmount, locale)
    }

    const withdrawalDate = (data) => {
        const dateCreated = data.date
        const utcSeconds = dateCreated["_seconds"]
        const date = new Date(0);
        date.setUTCSeconds(utcSeconds);
        return moment(date).format("MMM Do hh:mm A")
    }

    const themeMainColor = useTheme().palette.primary.main
    const useStyles = makeStyles({
        priceLabel: {
            paddingLeft: 5,
            color: themeMainColor
        },
        workerPriceLabel: {
            paddingLeft: 5,
            color: "#CC6600"
        },
        durationLabel: {
            paddingLeft: 5,
            color: "#5b5b5b"
        },
        intervalLabel: {
            color: "#5b5b5b"
        }
    });
    const styleClasses = useStyles();

    return (
        <div id="wrapper">
            <div id="left">
                <div>
                    <div>Withdrawal ({status})</div>
                    <div>
                        <Typography className={styleClasses.intervalLabel}>
                            {withdrawalDate(props.data)}
                        </Typography>
                    </div>
                </div>
            </div>
            <div id="right">
            <div id="spacer-child" ></div>
            <div id="child">
                <Typography className={styleClasses.priceLabel}>
                    {"Revenue Made By Member: " + displayAsCurrency(teamAmount, locale)}
                </Typography>
                <Typography className={styleClasses.workerPriceLabel}>
                    {"Member Cut: " + displayableMemberAmount()}
                </Typography>
            </div>
            </div>
        </div>
    )
}

export default HistoryWithdrawalRow
import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress'
import firebase from 'firebase/app'
import './styles.css'
import useCookieTheme from '../App/useCookieTheme';
import { downloadRecording } from '../../utils/recordings';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, userState } from '../../api/userSlice';

const DownloadRecording = (props) => {
  const id = props.match.params["id"]
  const dispatch = useDispatch()
  const errorMessage = useSelector(userState).errorMessage
  const themeMainColor = useTheme().palette.primary.main
  const { logo, bookingLogo } = useCookieTheme()

  useEffect(() => {
    const downloadRecordingAsync = async () => {
      let record = await firebase.firestore().doc(`bookings/${id}`).get()
      if (!record.exists) {
        record = await firebase.firestore().doc(`sessions/${id}`).get()
      }
      if (!record.exists) {
        dispatch(setErrorDetails({
          message: 'Invalid data. Please try again!',
        }))
        return
      }

      const recordData = record.data()
      const startDate = recordData.firstPingDate ? recordData.firstPingDate.toDate() : 
                        recordData.dateCreated ? recordData.dateCreated.toDate() : 
                        recordData.startDate.toDate()

      await downloadRecording({
        ...recordData,
        id: record.id
      }, moment(startDate), dispatch)
    }

    downloadRecordingAsync()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <div className='downlaod_container'>
      <div className='logo_container'>
        <img
          alt=""
          src={bookingLogo ?? logo}
        />
      </div>

      <div className='text_container'>
        {errorMessage ?
          <p>{errorMessage}</p>
          :
          <>
            <CircularProgress size={50} />
            <p color={themeMainColor}>Processing your recording...</p>
          </>
        }
      </div>
    </div>
  )
};

export default DownloadRecording;

import React, { useEffect, useRef, useState } from 'react'
import 'firebase/functions'
import { config } from '../../../../config/config'
import ReactDatePicker from 'react-datepicker';
import TimezoneInfoDialog from './TimezoneInfoDialog';
import { Button, CircularProgress, MenuItem, TextField, withStyles } from '@material-ui/core';
import { capitalizeFirstLetter, formattedIntegerLocaleSeparator, localeToCurrency } from '../../../../utils/utils';
import { ReactComponent as ClockIcon } from '../../../../images/clock.svg';
import { ReactComponent as CoinsIcon } from '../../../../images/coins.svg';
import ClearIcon from '@material-ui/icons/Clear'
import moment from 'moment';
import { minsSinceMidnight, toIntMonth, validTime } from './BookingSelectionHelper';
import './react-datepicker.css'
import { indexToLength, typeOptions } from '..';
import firebase from 'firebase/app'
import { useBookingContext } from '../BookingContext';
import ContinueButton from './ContinueButton';
import CheckCouponDialog from './CheckCouponDialog';
import useCookieTheme from '../../../App/useCookieTheme';
import { COMMON_ROUTES } from '../../../../utils/routes';
import { setErrorDetails, userState } from '../../../../api/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const styles = (theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  bigButtonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -35,
    marginLeft: -35,
  },
});

const BookingSelection = (props) => {
  const {
    classes,
    isGift,
    rescheduleBookingID,
  } = props
  const {
    consultantData,
    bookingData,
    setBookingData,
    setContactInfoData,
    setAdditionalInfoData,
    setBirthInfosData,
    couponData,
    discountCouponData,
    setCouponData,
    setDiscountCouponData,
    setIsDetailsForm,
    isTenPercentDiscount
  } = useBookingContext()
  const { appointmentName, changePage } = useCookieTheme()
  const dbUser = useSelector(userState).user

  const dispatch = useDispatch()
  const buffer = consultantData.bufferScheduleAppointment
  const intCurMonth = toIntMonth(buffer ? moment().add(buffer, 'hours') : moment())
  const currency = localeToCurrency(consultantData.locale)
  
  const [startDateState, setStartDateState] = useState(bookingData?.data?.startDate ? moment(bookingData?.data?.startDate).toDate() : null)
  const [allMonthData, setAllMonthData] = useState({})
  const [currentMonth, setCurrentMonth] = useState(intCurMonth)
  const [didSelectTime, setSelectedTime] = useState(Boolean(bookingData?.data?.startDate))
  const [meetingNotes, setMeetingNotes] = useState(bookingData?.data?.notes ?? "")
  const [showBigLoadingIndicator, setShowBigLoadingIndicator] = useState(true)
  const [validInput, setValidInput] = useState(false)
  const [awaitingRefresh, setAwaitingRefresh] = useState(null)
  const [openCouponDialog, setOpenCouponDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [giftID, setGiftID] = useState(null)
  const [noMoreAvailability, setNoMoreAvailability] = useState(false)
  const startDate = useRef(null)
  const isSessionLengthChanged = useRef(false)

  const bAmounts = {
    video: discountCouponData ? 
            consultantData.videoBookingAmounts.map(amount => Math.round(amount * (100 - discountCouponData.percent) / 100)) : 
            consultantData.videoBookingAmounts,
    audio: discountCouponData ? 
            consultantData.audioBookingAmounts.map(amount => Math.round(amount * (100 - discountCouponData.percent) / 100)) : 
            consultantData.audioBookingAmounts,
  }
  if (config.type !== 'astrologer') {
    bAmounts.chat = discountCouponData ? 
                      consultantData.chatBookingAmounts.map(amount => Math.round(amount * (100 - discountCouponData.percent) / 100)) : 
                      consultantData.chatBookingAmounts
  }
  const [sessionTypeId, setSessionTypeId] = useState(bookingData?.data?.callType ?? typeOptions().filter((typeOption) => {
    return bAmounts[typeOption.id]?.filter(each => each !== 0).length > 0
  })[0]?.id)

  const lengthOptions = bAmounts[sessionTypeId] ?? []
  const [sessionLength, setSessionLength] = useState(
    bookingData?.data?.length ?
      indexToLength.indexOf(bookingData?.data?.length)
      :
      lengthOptions.map((value, index) => {
        if (value !== 0) {
          return index
        }
        return null
      }).find(each => each !== null))

  useEffect(() => {
    isSessionLengthChanged.current = true
  }, [sessionLength])

  useEffect(() => {
    const getSlots = async () => {
      setLoading(true)
      setShowBigLoadingIndicator(true)
      
      if (awaitingRefresh?.data.isGift) {
        setBookingData(awaitingRefresh)
        setIsDetailsForm(true)
        return
      }

      if (rescheduleBookingID) {
        const rescheduleBookingData = (await firebase.firestore().doc(`/bookings/${rescheduleBookingID}`).get()).data()
        if (rescheduleBookingData) {
          const lengthOptions = bAmounts[rescheduleBookingData.callType]
          const length = lengthOptions.map((value, index) => {
            if (value !== 0) {
              return index
            }
            return null
          }).find(each => each === indexToLength.indexOf(rescheduleBookingData.length))
          if (length === undefined) {
            changePage({
              pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID),
              search: 'reschedulingError=true'
            })
          }
          setSessionTypeId(rescheduleBookingData.callType)
          setSessionLength(length)
          setGiftID(rescheduleBookingData.giftID)
          setContactInfoData({
            firstName: rescheduleBookingData.clientName,
            lastName: rescheduleBookingData.clientFullName.split(rescheduleBookingData.clientName)[1],
            email: rescheduleBookingData.clientEmail,
            confirmEmail: rescheduleBookingData.clientEmail,
          })
          if (!dbUser) {
            const seconds = rescheduleBookingData.clientDetails?.birthDate?.seconds
            const splitAdress = rescheduleBookingData.clientDetails?.address?.split(',')
            setBirthInfosData({
              birthDate: seconds ? new Date(seconds * 1000) : new Date(rescheduleBookingData.clientDetails?.birthDate),
              city: splitAdress ? splitAdress[0] : null,
              state: splitAdress ? splitAdress.length === 3 ? splitAdress[1] : null : null,
              country: splitAdress ? splitAdress.length === 3 ? splitAdress[2] : splitAdress[1] : null,
            })
          }
          setAdditionalInfoData(rescheduleBookingData.clientDetails)
        }
      }

      console.log("Create booking current month: " + currentMonth)
      if (config.type === 'astrologer' && parseInt(currentMonth) > 202401) {
        setNoMoreAvailability(true)
      }

      let prettyMonth = `${currentMonth % 100}`
      if (prettyMonth.length === 1) {
        prettyMonth = `0${prettyMonth}`
      }

      const stringMonth = Math.floor(currentMonth / 100) + "-" + prettyMonth
      const fullDate = `${stringMonth}-01T10:00:00Z`
      console.log(fullDate)
      const momentMonth = moment(fullDate)
      const startDate = momentMonth.startOf('month').toDate()
      const endDate = momentMonth.endOf('month').toDate()

      const payload = {
        startDay: startDate.toISOString(),
        endDay: endDate.toISOString(),
        consultantID: consultantData.userID
      }

      const resp = await firebase.functions().httpsCallable('getConsultantSlots')(payload)
      const data = resp.data
      if (data.length) {
        var mappedData = {}
        var availableDays = {}
        const febOneForAstro = moment(new Date(2024, 1, 1)).startOf('day').toISOString()
        let shouldContinue = true
        data.forEach(each => {
          const startDay = moment(each.startDate).startOf('day').toISOString()
          if (config.type === 'astrologer' && moment(startDay).toDate().getTime() >= moment(febOneForAstro).toDate().getTime()) {
            shouldContinue = false
            return
          }
          mappedData[each.startDate] = each.maxLength
          if (each.maxLength >= indexToLength[sessionLength]) {
            availableDays[startDay] = true
          }
        })

        if (!shouldContinue) {
          setShowBigLoadingIndicator(false)
          setLoading(false)
          return
        }

        if (awaitingRefresh) {
          const bData = awaitingRefresh.data
          const startDate = bData.startDate
          const selectedLength = indexToLength[sessionLength]

          const isValid = validTime(mappedData, moment(startDate), selectedLength, buffer)
          if (isValid) {
            setBookingData(awaitingRefresh)
            setIsDetailsForm(true)
            return
          } else {
            dispatch(setErrorDetails({
              message: "This field is not free! Please choose another one!"
            }))
          }
        }

        setAllMonthData(allMonthData => ({ ...allMonthData, [currentMonth]: {slots: mappedData, days: availableDays} }))
        const firstDate = Object.keys(mappedData).sort((s1, s2) => {
          const date1 = new Date(s1)
          const date2 = new Date(s2)
          return date1.getTime() - date2.getTime()
        })[0]
        setStartDateState(new Date(firstDate))
        setSelectedTime(true)
      } else {
        setCurrentMonth(toIntMonth(moment(startDate).add(1, 'months')))
      }
      setShowBigLoadingIndicator(false)
      setLoading(false)
    }

    if ((!allMonthData[currentMonth] || isSessionLengthChanged.current) && !noMoreAvailability) {
      getSlots()
      isSessionLengthChanged.current = false
    } else {
      setShowBigLoadingIndicator(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, allMonthData])

  // This checks if startDate.current is set 10 lines above
  // We can't update the state there because it's called during the calendar rendering, so react doesn't allow it
  // so we use this hook to check if it changed every 0.5s, and if it is, update our dateChanged
  useEffect(() => {
    if (startDateState != null || didSelectTime) {
      return
    }
    const intervalId = setInterval(() => {
      //console.log(`Cally called ${startDate.current} ${startDateState} ${didSelectTime}`)
      if (startDate.current && startDateState == null && !didSelectTime) {
        dateChanged(new Date(startDate.current))
        console.log("Attempting to cancel")
        clearInterval(intervalId)
      }

    }, 500) // in milliseconds
    return () => clearInterval(intervalId)
  }, [didSelectTime, startDateState])

  useEffect(() => {
    const selectedLength = indexToLength[sessionLength]
    setValidInput(isGift || (validTimeI(startDateState, selectedLength) && didSelectTime))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateState, didSelectTime, sessionLength, indexToLength, showBigLoadingIndicator])

  const validDateI = date => {
    let curData = allMonthData[currentMonth] 
    if (!curData?.days) {
      return false
    }
    const days = curData.days

    const curMoment = moment(date).startOf('day')

    const toRet = days[curMoment.toISOString()] !== undefined
    if (toRet) {
      //console.log(`Found valid day: ${curMoment.toISOString()}`)
      if (!startDate.current) {
        //Time to find the first available interval (there's gotta be one) and set it as current!
        var copyMoment = moment(curMoment)
        while(curData.slots[copyMoment.toISOString()] === undefined) {
          copyMoment = copyMoment.add(15, 'minutes')
        }
        startDate.current = copyMoment.toDate()
      }
    }
    return toRet
  }

  const validTimeI = (time) => {
    const curData = allMonthData[currentMonth]?.slots // array of god knows what
    const currentDateMins = minsSinceMidnight(moment(time))
    const mTime = moment(startDateState ?? startDate.current).startOf('day').add(currentDateMins, 'minutes')
    const selectedLength = indexToLength[sessionLength]
    return validTime(curData, mTime, selectedLength, buffer)
  }

  const onMonthChange = month => { setCurrentMonth(toIntMonth(moment(month))) }

  const dateChanged = (date) => {
    const newDate = moment(date)
    const isTimeSelection = newDate.seconds() === 0 && newDate.minutes() % 15 === 0
    if (isTimeSelection) {
      setSelectedTime(true)
    }
    setStartDateState(date)
  }

  const handleChangeType = (event) => {
    const st = typeOptions().find(elem => elem.id === event.target.value);
    setSessionTypeId(st ? st.id : '');
    if (st.id) {
      const lengthOptions = bAmounts[st.id]
      setSessionLength(
        lengthOptions.map((value, index) => {
          if (value !== 0) {
            return index
          }
          return null
        }).find(each => each !== null))
    }
  }

  const isTypeDisabled = (option) => {
    return !bAmounts[option.id] || bAmounts[option.id]?.filter(each => each !== 0).length === 0
  }

  const handleChangeLength = (event) => {
    setSessionLength(event.target.value)
  }

  const createBookingTapped = () => {
    const selectedLength = indexToLength[sessionLength]
    const estimatedPrice = lengthOptions[sessionLength]

    const newBookingID = firebase.firestore().collection('/bookings').doc()

    const newBooking = {
      consultantFirstName: consultantData.name,
      consultantName: `${consultantData.name} ${consultantData.surname}`,
      consultantID: consultantData.userID,
      expertise: consultantData.consultancyType,
      length: selectedLength,
      startDate: startDateState ?? startDate.current,
      notes: meetingNotes,
      status: "pending",
      estimatedPrice: isTenPercentDiscount ? Math.round(estimatedPrice * 0.9) : estimatedPrice,
      callType: sessionTypeId.toLowerCase(),
      locale: consultantData.locale,
      bookingID: newBookingID.id,
      createdAt: new Date(),
      corporate: false,
      billGenerated: false,
      webCreated: true,
      clientDetails: bookingData?.data?.clientDetails,
      giftID: giftID,
      isGift: isGift,
      oldPrice: isTenPercentDiscount ? estimatedPrice : 0
    }

    setAwaitingRefresh({ docRef: newBookingID.id, data: newBooking, locale: consultantData.locale, consultantName: newBooking.consultantName })
    setAllMonthData({})
  }

  if (!consultantData) {
    return
  }

  return (
    <div className='item booking_selection_container'>
      {noMoreAvailability &&
        <div style={{ color: 'red', marginTop: 20, fontSize: 20 }}>No more availability</div>
      }
      {!isGift &&
        <div className="createBooking_calendar">
          <ReactDatePicker
            disabledKeyboardNavigation
            selected={startDateState ?? startDate.current}
            onChange={date => dateChanged(date)}
            showTimeSelect
            inline
            disabled={true}
            filterDate={validDateI}
            filterTime={validTimeI}
            timeIntervals={15}
            onMonthChange={onMonthChange}
            dateFormat="MMMM d, yyyy h:mm aa"
            popperPlacement="bottom"
          />
          {showBigLoadingIndicator && <CircularProgress size={50} className={"big_button_progress " + classes.bigButtonProgress} />}
          <TimezoneInfoDialog />
        </div>
      }

      <div className="createBooking_calls_types">
        <div>
          <div className="label_type_7">
            {appointmentName ? capitalizeFirstLetter(appointmentName) : 'Call'} Type
          </div>
          <TextField
            style={{ backgroundColor: 'white', borderRadius: 10, cursor: couponData ? 'default' : 'pointer' }}
            variant='outlined'
            value={sessionTypeId}
            onChange={handleChangeType}
            select={true}
            InputProps={{
              readOnly: Boolean(couponData) || Boolean(rescheduleBookingID),
            }}
          >
            {typeOptions().map((option, index) => (
              <MenuItem disabled={isTypeDisabled(option)} key={index} value={option.id}>
                <div className='audio_video_selection'>
                  <div className='audio_video_selection_icon'>
                    {option.icon}
                  </div>
                  {option.label}
                </div>
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className='info_type_price'>
          <div className="label_type_7">
            {appointmentName ? capitalizeFirstLetter(appointmentName) : 'Call'} Length
          </div>
          <TextField
            style={{ backgroundColor: 'white', borderRadius: 10 }}
            variant='outlined'
            value={sessionLength}
            onChange={handleChangeLength}
            select={true}
            InputProps={{
              readOnly: Boolean(couponData) || Boolean(rescheduleBookingID),
            }}
          >
            {lengthOptions.map((option, index) => (
              <MenuItem style={{ display: option === 0 ? 'none' : 'block' }} key={index} value={index}>
                <div className='audio_video_selection'>
                  <span className='time_selection_icon'><ClockIcon /></span>
                  {indexToLength[index]} MIN
                  {!couponData && !rescheduleBookingID &&
                    <>
                      <span className='time_selection_icon time_selection_icon_second'><CoinsIcon /></span>
                      {option < 0 ?
                        'FREE'
                      :
                        <span className={`${isTenPercentDiscount ? 'cut_text' : ''}`}>{currency}{formattedIntegerLocaleSeparator(option, consultantData.locale)}</span>
                      }
                    </>
                  }
                </div>
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {config.type !== 'astrologer' && !isGift &&
        <div className="createBooking_notes">
          <TextField
            style={{ backgroundColor: 'white' }}
            multiline
            variant='outlined'
            label="Meeting Notes"
            value={meetingNotes}
            onChange={(e) => setMeetingNotes(e.target.value)}
            rows={3}
          />
        </div>
      }

      {config.type === 'astrologer' && isTenPercentDiscount &&
        <div className="ten_percent_discount">
          <p className='cut_text'><span className='bold_text'>REGULAR PRICE:</span> {currency}{formattedIntegerLocaleSeparator(bAmounts[sessionTypeId][sessionLength], consultantData.locale)}</p>
          <p><span className='bold_text'>YOUR PRICE TODAY:</span> {currency}{formattedIntegerLocaleSeparator(bAmounts[sessionTypeId][sessionLength] * 0.9, consultantData.locale)}</p>
        </div>
      }

      <ContinueButton
        disabled={!validInput}
        loading={loading && !validInput}
        onClick={createBookingTapped}
        text='CONTINUE'
      />

      {!rescheduleBookingID &&
        <div className="gift_button_container">
          {couponData || discountCouponData ? 
            <div className='coupon'>
              <p>{couponData ? 'GIFT' : 'DISCOUNT'} COUPON CODE: <b>{couponData?.code || discountCouponData?.code}</b></p>
              <ClearIcon onClick={() => {
                setCouponData(null)
                setDiscountCouponData(null)
              }} />
            </div>
          :
            <div className={classes.wrapper}>
              <Button onClick={() => setOpenCouponDialog(true)} color="primary" variant="outlined">
                I HAVE A {!isGift && 'GIFT OR '}DISCOUNT CODE
              </Button>
            </div>
          }
        </div>
      }
      
      {openCouponDialog &&
        <CheckCouponDialog
          open={openCouponDialog}
          setOpen={setOpenCouponDialog}
          setSessionTypeId={setSessionTypeId}
          setSessionLength={setSessionLength}
          bAmounts={bAmounts}
          rescheduleBookingID={rescheduleBookingID}
        />
      }
    </div>
  )
}

export default withStyles(styles)(BookingSelection);

import { configureStore } from '@reduxjs/toolkit'
import userSlice from './userSlice'
import sessionSlice from './sessionSlice'
import themeSlice from './themeSlice'

export default configureStore({
  reducer: {
    user: userSlice,
    session: sessionSlice,
    theme: themeSlice,
  }
})
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { themeState } from "../../../../api/themeSlice";
import { useTeamContext } from "../teamContext";

const PendingUsersTable = (props) => {
  const { isFetching, data } = usePendingUsersQuery();

  if (isFetching || !data) {
    return <CircularProgress />;
  }

  return (
    <TableContainer component={Paper} className="table_container">
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <PendingUsersTableRow row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const PendingUsersTableRow = ({ row }) => {
  const { fetchConsultantStats } = useTeamContext();

  const queryClient = useQueryClient();
  const { mutate, status } = useMutation({
    mutationFn: updatePendingUser,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries("pendingUsers");
      if (variables.approve) {
        fetchConsultantStats();
      }
    },
  });

  const startMutation = (approve) => {
    mutate({ id: row.id, approve });
  };

  return (
    <TableRow
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell align="right">{row.email}</TableCell>
      <TableCell align="right">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => startMutation(true)}
            disabled={status === "loading"}
            variant="contained"
          >
            Approve
          </Button>
          <Button
            onClick={() => startMutation(false)}
            disabled={status === "loading"}
            style={{ marginLeft: 5 }}
            variant="contained"
          >
            Decline
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

const updatePendingUser = async ({ id, approve }) => {
  console.log("Updating async ", id, approve);
  const res = await firebase
    .functions()
    .httpsCallable("updatePendingTeamMember")({
    id,
    approve,
  });
  console.log("mutation res: ", res);
  return;
};

const usePendingUsersQuery = () => {
  const theme = useSelector(themeState).theme?.id;

  return useQuery(
    ["pendingUsers"],
    async () => {
      return firebase
        .functions()
        .httpsCallable("getPendingTeamMembers")({ theme: theme })
        .then((result) => {
          // console.log("nicdic", result);
          return result.data;
        });
    },
    { refetchOnWindowFocus: false }
  );
};

export default PendingUsersTable;

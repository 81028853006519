import React, { useEffect, useState } from 'react';
import { Button, useMediaQuery } from '@material-ui/core';
import PerMinuteButtons from '../../ConsultantProfile/components/perMinuteButtons';
import { COMMON_ROUTES } from '../../../utils/routes';
import { config } from '../../../config/config';
import useCookieTheme from '../../App/useCookieTheme';
import { useSelector } from 'react-redux';
import { userState } from '../../../api/userSlice';
import firebase from "firebase/app";
import { StarRate } from '@material-ui/icons';
import ReviewsDetailsDialog from '../../ConsultantProfile/components/ReviewsDetailsDialog';

const ConsultantItem = (props) => {
  const { consultant, teamID, onlyOnline, showAnimation } = props
  const dbUserID = useSelector(userState).user?.userID
  const isConsultant = useSelector(userState).user?.consultant
  const { makeAppointmentButtonName, giftButtonName, changePage, tileBg } = useCookieTheme()

  const [consultantData, setConsultantData] = useState(consultant)
  const [openReviewsDialog, setOpenReviewsDialog] = useState(false)
  const numberOfReviews = consultantData.ratingStats ?
    Object.keys(consultantData.ratingStats).reduce((a, b) => a + consultantData.ratingStats[b], 0) : 0

  const makeAppointmentDisabled = isConsultant || !consultantData.bookingsEnabled || (
    !consultantData.audioBookingAmounts?.filter(amount => amount !== 0).length &&
    !consultantData.videoBookingAmounts?.filter(amount => amount !== 0).length &&
    (config.type === 'astrologer' || !consultantData.chatBookingAmounts?.filter(amount => amount !== 0).length))
    
  const widthLessThan800px = useMediaQuery('(max-width:799px)');
  const widthLessThan1175px = useMediaQuery('(max-width:1174px)');
  const widthLessThan1415px = useMediaQuery('(max-width:1414px)');
  const itemPerColumn = widthLessThan800px ? 1 : (dbUserID && widthLessThan1415px ) || (!dbUserID && widthLessThan1175px) ? 2 : 3

  useEffect(() => {
    if (
      showAnimation &&
      parseInt(consultant.newIndex) > -1 && 
      parseInt(consultant.oldIndex) > -1 && 
      consultant.newIndex !== consultant.oldIndex
    ) {
      const oldRow = consultant.oldIndex % itemPerColumn
      const newRow = consultant.newIndex % itemPerColumn
      const transX = (oldRow - newRow) * 100
      const oldCol = Math.floor(consultant.oldIndex / itemPerColumn) - 1
      const newCol = Math.floor(consultant.newIndex / itemPerColumn) - 1
      const diffCol = (oldCol - newCol) < itemPerColumn ? oldCol - newCol : itemPerColumn
      const transY = diffCol * 100
      document.getElementsByClassName(`consultant_item_${consultant.userID}`)[0].animate([{
        transform: `translate(${transX}%, ${transY}%)`
      }, {
        transform: `translate(0, 0)`
      }], {
        duration: 400,
      });
    }
  }, [consultant.newIndex, consultant.oldIndex, consultant.userID, itemPerColumn, onlyOnline, showAnimation])

  useEffect(() => {
    document.getElementsByClassName(`consultant_item_${consultantData.userID}`)[0].animate([{
      opacity: .5
    }, {
      opacity: 1
    }], {
      duration: 400,
    });
  }, [consultantData.userID, onlyOnline])

  useEffect(() => {
    if (teamID) {
      const unsubscribe = firebase.firestore().doc(`users/${consultant.userID}`).onSnapshot(res => {
        setConsultantData({
          ...res.data(),
          profilePic: consultant.profilePic
        })
      })

      return () => unsubscribe()
    } else {
      setConsultantData(consultant)
    }
  }, [consultant, teamID])

  const MakeAppointmentButton = () => {
    return (
      <Button
        className='make_appointment_button_list'
        size='small'
        disabled={makeAppointmentDisabled}
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultantData.userID),
          search: `fromConsultantsList=true${teamID ? `&teamID=${teamID ?? null}` : ''}`
        })}
        color="primary"
        variant="contained"
      >
        {makeAppointmentButtonName ?? 'Make appointment'}
      </Button>
    )
  }

  const GiftAppointmentButton = () => {
    return (
      <Button
        className='make_appointment_button_list'
        size='small'
        disabled={makeAppointmentDisabled}
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultantData.userID),
          search: `isGift=true&fromConsultantsList=true${teamID ? `&teamID=${teamID ?? null}` : ''}`,
        })}
        color="primary"
        variant="contained"
      >
        {giftButtonName}
      </Button>
    )
  }

  const ViewProfileButton = () => {
    return (
      <div className='view_profile_button_container'>
        <Button
          className='make_appointment_button_list'
          size='small'
          onClick={() => changePage({
            pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID),
            search: 'fromList=true'
          })}
          color='primary'
        >
          View profile
        </Button>
      </div>
    )
  }

  return (
    <div
      className={`consultant_item consultant_item_${consultantData.userID}`}
      style={{ backgroundColor: tileBg ?? 'white' }}
    >
      <div
        className='names_container'
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID),
          search: 'fromList=true'
        })}
      >
        <p>{consultantData.name} {consultantData.surname}</p>
        <p className='expertise'>{consultantData.consultancyType}</p>
      </div>
      <div className='rating_container' onClick={() => setOpenReviewsDialog(true)}>
        {consultantData.generalScore && <p className='general_score'>{Math.round(consultantData.generalScore * 10) / 10} <StarRate /></p>}
      </div>
      {consultantData.online && !consultantData.isBusy &&
        <div
          className='online_offline_container'
          style={{ backgroundColor: consultantData.online && !consultantData.isBusy ? '#34D980' : 'gray' }}
        >
          <p>ONLINE</p>
        </div>
      }
      <div
        className='header_container'
        onClick={() => changePage({
          pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID),
          search: 'fromList=true'
        })}
      >
        <img
          src={consultantData.profilePic ?? require("../../../images/user.png")}
          alt=""
          className='profile_pic'
        />
      </div>
      <div className='consultantProfile consultantProfile_content'>
        <PerMinuteButtons
          data={consultantData}
          fromConsultantsList={true}
          teamID={teamID}
        />
        {config.type === 'astrologer' && 
          <div className='heading_book_a_reading'>BOOKED READINGS</div>
        }
        <div className='booked_readings_buttons'>
          <MakeAppointmentButton />
          <GiftAppointmentButton />
        </div>
        <ViewProfileButton />
      </div>

      {openReviewsDialog &&
        <ReviewsDetailsDialog
          open={openReviewsDialog} 
          setOpen={setOpenReviewsDialog} 
          consultantData={consultantData}
          numberOfReviews={numberOfReviews}
        />
      }
    </div>
  )
};

export default ConsultantItem;

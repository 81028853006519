import '../../styles.css';
import React, {  } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';

const TeamTable = (props) => {
  const { tableCells, tableRows, classTable } = props

  return (
    <TableContainer component={Paper} className='table_container'>
      <Table size='small' className={`${classTable ?? ''}`}>
        <TableHead className='table_header'>
          {tableCells}
        </TableHead>
        <TableBody className='table_body'>
          {tableRows}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default TeamTable;

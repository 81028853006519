import React, { useEffect, useState } from "react";
import firebase from "firebase";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { CircularProgress, useMediaQuery } from "@material-ui/core";
import { AUTH_ROUTES_CONSULTANT } from "../../../../utils/routes";
import { displayAsCurrency } from "../../../../utils/utils";
import useCookieTheme from "../../../App/useCookieTheme";
import TeamMemberTable from "../Table/TeamMemberTable";
import WithdrawDialog from "../../../Home/components/WithdrawDialog/WithdrawDialog";
import { useTeamContext } from "../teamContext";
import { useDispatch, useSelector } from "react-redux";
import {
  userState,
  checkTeamMembership,
  setIsTeamPage,
  setErrorDetails,
} from "../../../../api/userSlice";
import PendingUsersTable from "../Table/PendingUsersTable";

const GeneralTab = (props) => {
  const dispatch = useDispatch();
  const masterID = useSelector(userState).user?.userID;
  const {
    dataSource,
    isFetchingData,
    consultants,
    setIsFetching,
    setAlertInfo,
    fetchConsultantStats,
    fetchUserData,
    total,
    userLocale,
    paypalEnabled,
    bankEnabled,
  } = useTeamContext();
  const { changePage } = useCookieTheme();

  const [withdrawTypeDialog, setWithdrawTypeDialog] = useState(null);

  const widthLessThan650px = useMediaQuery("(max-width:649px)");

  useEffect(fetchUserData, [masterID]);

  const withdrawPaypalTapped = () => {
    setWithdrawTypeDialog("paypal");
  };

  const withdrawBankTapped = () => {
    setWithdrawTypeDialog("bank");
  };

  const withdrawBalance = (destination) => {
    const finallyWithdraw = () => {
      setIsFetching(true);

      firebase
        .functions()
        .httpsCallable("withdrawTeamBalance")({ destination: destination })
        .then(function (result) {
          console.log(result);
        })
        .catch((error) => {
          dispatch(
            setErrorDetails({
              message: error.message,
            })
          );
        })
        .finally(() => {
          fetchConsultantStats();
        });
    };

    if (dataSource.find((member) => member.teamMemberWalletBalance < 0)) {
      setAlertInfo({
        open: true,
        title: "",
        description:
          "Some of your members have negative balance. Are you sure you still want to make the withdrawal?",
        onConfirm: () => {
          hideAlert();
          finallyWithdraw();
        },
        onCancel: hideAlert,
        okTitle: "I'm sure!",
      });
    } else {
      finallyWithdraw();
    }
  };

  const canBeRemoved = (data) => {
    const isInvite = data.type === "invite";
    const isConsultant = data.type === "consultant";
    const isMaster = data.consultantID === masterID;
    return (isConsultant && !isMaster) || isInvite;
  };

  const canSetEmployeeCut = (data) => {
    const isConsultant = data.type === "consultant";
    const isMaster = data.consultantID === masterID;
    return isConsultant && !isMaster;
  };

  const handleRemove = (memberData) => {
    setAlertInfo({
      open: true,
      title: "",
      description:
        "Are you sure you want to remove " +
        memberData.name +
        " from the team?",
      onConfirm: () => {
        hideAlert();
        const email =
          memberData.type === "invite" ? memberData.name : undefined;
        removeFromTeam(memberData.consultantID, email);
      },
      onCancel: hideAlert,
      okTitle: "I'm sure!",
    });
  };

  const removeFromTeam = (consultantID, invitedEmail) => {
    setIsFetching(true);
    const toSend = {};
    if (consultantID) {
      toSend["uid"] = consultantID;
    }
    if (invitedEmail) {
      toSend["email"] = invitedEmail;
    }

    firebase
      .functions()
      .httpsCallable("removeFromTeam")(toSend)
      .then(function (result) {
        console.log(result.data);
      })
      .catch((error) => {
        dispatch(
          setErrorDetails({
            message: error.message,
          })
        );
      })
      .finally(() => {
        fetchConsultantStats();
      });
  };

  const deleteTeamTapped = () => {
    setAlertInfo({
      open: true,
      title: "",
      description: "Are you sure you want to delete this team?",
      onConfirm: () => {
        hideAlert();
        handleDeleteTeam();
      },
      onCancel: hideAlert,
      okTitle: "I'm sure!",
    });
  };

  const handleDeleteTeam = () => {
    setIsFetching(true);
    firebase
      .functions()
      .httpsCallable("deleteTeam")()
      .then(async () => {
        console.log("Team deleted");
        await dispatch(checkTeamMembership()).unwrap();
        dispatch(setIsTeamPage(false));
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.HOME,
        });
      })
      .catch((error) => {
        dispatch(
          setErrorDetails({
            message: error.message,
          })
        );
      });
  };

  const hideAlert = () => {
    setAlertInfo((oldAlertInfo) => ({
      ...oldAlertInfo,
      open: false,
    }));
  };

  const themeMainColor = useTheme().palette.primary.main;
  const useStyles = makeStyles({
    gratuityLabel: {
      fontSize: 17,
      paddingLeft: 25,
      paddingRight: 70,
    },
    teamNameLabel: {
      fontSize: 26,
      color: themeMainColor,
      height: "auto !important",
    },
    nameEditButton: {
      paddingLeft: 25,
    },
    totalAmount: {
      paddingLeft: 25,
      fontSize: 24,
      fontWeight: "bold",
      color: "darkslategray",
      margin: 0,
    },
    amount: {
      color: themeMainColor,
    },
    withrawal_button: {
      color: "white",
      backgroundColor: themeMainColor,
      "&:hover": {
        backgroundColor: themeMainColor,
        opacity: 0.7,
      },
    },
    deleteTeamButton: {
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#A45A52",
      },
    },
  });

  const styleClasses = useStyles();

  return (
    <>
      {isFetchingData || !dataSource || !consultants.length ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 30 }}
        >
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <TeamMemberTable
            dataSource={dataSource}
            handleRemove={handleRemove}
            canBeRemoved={canBeRemoved}
            canSetEmployeeCut={canSetEmployeeCut}
          />
          <PendingUsersTable />

          <Typography
            variant="h6"
            display="block"
            gutterBottom
            className={`${styleClasses.totalAmount} totalAmount heading`}
          >
            Total:{" "}
            {
              <span className={styleClasses.amount}>
                {displayAsCurrency(total, userLocale)}
              </span>
            }
          </Typography>
          <Typography
            variant="body1"
            display="block"
            gutterBottom
            className="totalNote"
          >
            This is the total revenue made by the entire team since last
            withdrawal. As team master you are the only one able to withdraw it.
            You'll have to pay the rest of the team members yourself afterwards,
            independent of our platform.
          </Typography>
          <div className="withrawButtons">
            <div>
              <Button
                className={styleClasses.withrawal_button}
                variant="contained"
                onClick={withdrawPaypalTapped}
              >
                {paypalEnabled ? "Withdraw" : "Add"} Paypal
              </Button>
              <Button
                className={styleClasses.withrawal_button}
                variant="contained"
                onClick={withdrawBankTapped}
              >
                {bankEnabled ? "Withdraw" : "Add"} Bank Account
              </Button>
            </div>
            {!widthLessThan650px && (
              <div>
                <Button
                  variant="contained"
                  onClick={deleteTeamTapped}
                  className={`${styleClasses.deleteTeamButton} deleteTeamButton`}
                >
                  Delete Team
                </Button>
              </div>
            )}
          </div>
          {widthLessThan650px && (
            <div className="withrawButtons delete_button">
              <Button
                variant="contained"
                onClick={deleteTeamTapped}
                className={`${styleClasses.deleteTeamButton} deleteTeamButton`}
              >
                Delete Team
              </Button>
            </div>
          )}
        </>
      )}

      {Boolean(withdrawTypeDialog) && (
        <WithdrawDialog
          open={Boolean(withdrawTypeDialog)}
          handleClose={() => setWithdrawTypeDialog(null)}
          fromTeam
          withdrawType={withdrawTypeDialog}
          teamBalance={total}
          withdrawTeamBalance={withdrawBalance}
          isSet={withdrawTypeDialog === "bank" ? bankEnabled : paypalEnabled}
        />
      )}
    </>
  );
};

export default GeneralTab;

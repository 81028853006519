import React, { useEffect, useState } from 'react'
import firebase from 'firebase'
import { PDFObject } from 'react-pdfobject'
import { getDocs } from '../../utils/theme'
import useCookieTheme from '../App/useCookieTheme'

const PDFPage = (props) => {
  const type = props.type
  const { name, docsLocation } = useCookieTheme()
  const appName = name
  const startURL = getDocs(docsLocation)

  const [url, setUrl] = useState(null)

  useEffect(() => {
    const asyncFunct = async () => {
      const fullURL = `${startURL}/${type === "tc" ? "tos" : "privacy_policy"}.pdf`
      const dlURL = await firebase.app().storage().ref(fullURL).getDownloadURL()
      if (dlURL) {
        setUrl(dlURL)
      } else {
        console.log('no file')
      }
    }

    asyncFunct()
  }, [type, appName, startURL])

  return (
    <div style={{ width: '100%' }}>
      {url && <PDFObject height="99vh" url={url} />}
    </div >
  )
}

export const PrivacyPolicyPage = () => {
  return (<PDFPage type="privacy" />)
}

export const TermsAndConditionsPage = () => {
  return (<PDFPage type="tc" />)
}

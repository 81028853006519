import React, { useContext, useRef, useState } from "react";
import {
  Grid,
  Button,
  FormControlLabel,
  useMediaQuery,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import firebase from "firebase";
import "firebase/auth";
import { useTheme } from "@material-ui/core/styles";
import { config } from "../../../config/config";
import {
  AUTH_ROUTES_CLIENT,
  AUTH_ROUTES_CONSULTANT,
  COMMON_ROUTES,
  NO_AUTH_ROUTES,
} from "../../../utils/routes";
import { isValidEmail } from "../../../utils/utils";
import { FirebaseContext } from "../../Firebase";
import RoundCheckbox from "../../components/RoundCheckbox";
import { useGlobalStyles } from "../../App/globalStyles";
import useCookieTheme from "../../App/useCookieTheme";
import { setErrorDetails, userState } from "../../../api/userSlice";
import { useDispatch, useSelector } from "react-redux";
import useBookingHook from "../../ConsultantProfile/useBookingHook";
import "../styles.css";

const LoginComponent = (props) => {
  const {
    setShowLogin,
    fromConsultantBooking,
    setOpenDialog,
    saveBookingData,
    widgetLogin,
  } = props;
  const { getBooking } = useBookingHook();
  const bookingFlow = getBooking();
  const { changePage } = useCookieTheme();
  const dispatch = useDispatch();
  const integration = useSelector(userState).integration;
  const firebaseSignIn = useContext(FirebaseContext);

  const [loading, setLoading] = useState(false);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const heightLessThan1050px = useMediaQuery("(max-height:1049px)");
  const heightLessThan900px = useMediaQuery("(max-height:899px)");
  const heightLessThan720px = useMediaQuery("(max-height:719px)");

  const disableSignUp = loading && !fromConsultantBooking;

  const signInFormRef = useRef();

  const signInDone = (wasSignUp) => {
    if (widgetLogin) {
      return;
    }
    if (fromConsultantBooking) {
      saveBookingData();
    } else {
      if (bookingFlow && bookingFlow !== "null") {
        if (!wasSignUp) {
          changePage({
            pathname: COMMON_ROUTES.CONSULTANT.replace(
              ":consultant",
              bookingFlow.perMinuteConsultant
            ),
          });
        } else {
          changePage({
            pathname: AUTH_ROUTES_CLIENT.PAYMENT,
          });
        }
      } else {
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.HOME,
        });
      }
    }
  };

  const signInTapped = async () => {
    setLoading(true);

    const formOK = await signInFormRef.current.isFormValid(false);
    if (!formOK) {
      setLoading(false);
      return;
    }

    try {
      if (Boolean(integration)) {
        localStorage.setItem("loggedInWidget", true);
        await firebase.auth().signOut();
      }

      await firebaseSignIn.signIn(username, password);

      const authUserUid = firebase.auth().currentUser?.uid;
      const userData = (
        await firebase.firestore().doc(`users/${authUserUid}`).get()
      ).data();
      if (!userData || userData.userID !== authUserUid) {
        firebase.app().auth().signOut();
        dispatch(
          setErrorDetails({
            message: "This user is temporary blocked. Please try again later!",
          })
        );
        setLoading(false);
      } else {
        signInDone(false);
      }
    } catch (e) {
      dispatch(
        setErrorDetails({
          message: e?.message ?? "Error logging in!",
        })
      );
      setLoading(false);
    }
  };

  const rememberMeChange = (event) => {
    firebaseSignIn.setRememberMe(event.target.checked);
    setRememberMe(event.target.checked);
  };

  const usernameChange = (event) => {
    setUsername(event.target.value);
  };

  const passwordChange = (event) => {
    setPassword(event.target.value);
  };

  const forgotPasswordTapped = () => {
    if (isValidEmail(username)) {
      firebase.functions().httpsCallable("sendForgotPassword")({
        email: username,
      });
      dispatch(
        setErrorDetails({
          message: "If your email is valid, a password reset link was sent!",
          severity: "warning",
        })
      );
    } else {
      dispatch(
        setErrorDetails({
          message: "Please insert a valid email!",
          severity: "error",
        })
      );
    }
  };

  const inputPropstextFieldStyle = {
    backgroundColor: "white",
    borderRadius: 10,
    marginTop: 8,
    marginBottom: -7,
    fontSize: 14,
  };
  const inputPropsTextField = heightLessThan720px
    ? {
        style: {
          ...inputPropstextFieldStyle,
          marginTop: 2,
          marginBottom: 0,
        },
      }
    : heightLessThan900px
    ? {
        style: {
          ...inputPropstextFieldStyle,
          marginTop: 3,
          marginBottom: 0,
        },
      }
    : {
        style: inputPropstextFieldStyle,
      };
  const inputLabelPropsTextField = heightLessThan720px
    ? { style: { fontSize: 9 } }
    : heightLessThan900px
    ? { style: { fontSize: 11 } }
    : undefined;

  const themeMainColor = useTheme().palette.primary.main;

  useGlobalStyles();

  return (
    <>
      <div className={`${!fromConsultantBooking ? `signin_login_form` : ""}`}>
        <div className="signin_welcome heading">Login</div>
        <Grid container justify="center">
          {config.type !== "astrologer" && (
            <Button
              className="signin_button signin_button_change_page"
              type="submit"
              disabled={false}
              onClick={() => {
                if (fromConsultantBooking) {
                  changePage({
                    pathname: NO_AUTH_ROUTES.SIGN_UP,
                    search: `?client=true`,
                  });
                } else {
                  setShowLogin(false);
                }
              }}
              variant="outlined"
              color="primary"
            >
              Don't have an account? Sign Up
            </Button>
          )}
        </Grid>
        <ValidatorForm
          ref={signInFormRef}
          onError={(errors) => console.log(errors)}
          onSubmit={() => {
            return;
          }}
        >
          <Grid container spacing={heightLessThan900px ? 1 : 2}>
            <Grid item xs={12}>
              <p>Your Email*</p>
              <TextValidator
                validators={["required", "isEmail"]}
                variant="outlined"
                size={heightLessThan1050px ? "small" : "medium"}
                inputProps={inputPropsTextField}
                InputLabelProps={inputLabelPropsTextField}
                errorMessages={[
                  "This field is required",
                  "E-mail is not valid",
                ]}
                onChange={usernameChange}
                value={username}
                type="email"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <p>Your Password*</p>
              <TextValidator
                validators={["required"]}
                variant="outlined"
                size={heightLessThan1050px ? "small" : "medium"}
                inputProps={inputPropsTextField}
                InputLabelProps={inputLabelPropsTextField}
                errorMessages={["This field is required"]}
                onChange={passwordChange}
                value={password}
                type="password"
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                className="sign_round_check"
                control={
                  <RoundCheckbox
                    color="primary"
                    value={rememberMe ? "on" : "off"}
                    onChange={rememberMeChange}
                  />
                }
                label="Remember me"
              />
            </Grid>
            <Grid item xs={12} className="signin_login_form_submit">
              <Button
                className="signin_button"
                type="submit"
                disabled={disableSignUp}
                onClick={signInTapped}
                variant="contained"
                color="primary"
              >
                {loading ? <CircularProgress size={10} /> : "Login"}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              className="signin_login_form_text forgot_password"
            >
              Forgot password?&nbsp;
              <span
                style={{ color: themeMainColor }}
                className="signin_login_form_forgot_password"
                onClick={forgotPasswordTapped}
              >
                Click here
              </span>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
      {fromConsultantBooking ? (
        <div className="continue_without_login_buttton">
          <Button
            onClick={() => setOpenDialog(false)}
            color="primary"
            variant="outlined"
          >
            Continue without logging in.
          </Button>
        </div>
      ) : (
        <>
          {config.type === "astrologer" && (
            <Grid className="text_below_button" container justify="center">
              <p>
                If you're an IC Member or an Astrology Hub student (have
                purchased any courses from us in the past) please create a new
                account. Your Student Portal is different from the Astrologer
                Connect platform!
              </p>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default LoginComponent;

import React, { useEffect, useRef, useState } from 'react'
import './styles.css'
import { Button, CircularProgress, InputAdornment, TextField } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { ImagePicker } from 'react-file-picker'
import { setErrorDetails, setUserState, userState } from '../../api/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import firebase from 'firebase/app'
import { ChromePicker } from 'react-color';
import { config, defaultTheme } from '../../config/config';
import copy from "copy-to-clipboard";

const WebsiteIntegration = () => {
  const dispatch = useDispatch()
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const teamCode = useSelector(userState).teamCode

  const [loading, setLoading] = useState(true)
  const [name, setName] = useState(null)
  const [logo, setLogo] = useState(null)
  const [base64Logo, setBase64Logo] = useState(null)
  const [fontColor, setFontColor] = useState(null)
  const [backgroundColor, setBackgroundColor] = useState(null)
  const [tileBg, setTileBg] = useState(null)
  const [width, setWidth] = useState(null)
  const [iframeLoaded, setIframeLoaded] = useState(false)
  const testThemeID = useRef(null)
  const [onSaving, setOnSaving] = useState(false)

  useEffect(() => {
    setLoading(true)
    firebase.firestore().doc(`themes/${dbUser?.integrationThemeID ?? dbUser?.themeID}`).get().then((theme) => {
      const themeData = theme.data()
      setName(themeData?.name)
      setLogo(themeData?.logo)
      setFontColor(themeData?.color)
      setBackgroundColor(themeData?.backgroundColor)
      setTileBg(themeData?.tileBg)
      setWidth(themeData?.width ?? 100)
      
      if (dbUser?.testIntegrationThemeID) {
        testThemeID.current = dbUser?.testIntegrationThemeID
        firebase.functions().httpsCallable("updateTheme")({
          themeID: dbUser?.testIntegrationThemeID,
          toUpdate: {
            name: themeData?.name,
            title: themeData?.name,
            logo: themeData?.logo,
            invoiceLogo: themeData?.logo,
            color: themeData?.color,
            backgroundColor: themeData?.backgroundColor,
            tileBg: themeData?.tileBg,
            width: themeData?.width
          },
        })
      } else {
        firebase.functions().httpsCallable("updateTheme")({
          toUpdate: {
            name: themeData?.name,
            title: themeData?.name,
            logo: themeData?.logo,
            invoiceLogo: themeData?.logo,
            color: themeData?.color,
            backgroundColor: themeData?.backgroundColor,
            tileBg: themeData?.tileBg,
            width: themeData?.width,
            isTest: true
          },
        }).then((res) => {
          testThemeID.current = res.data.themeID
          dispatch(setUserState({
            testIntegrationThemeID: res.data.themeID
          }))
        })
      }
    }).catch(() => {
      dispatch(setErrorDetails({
        message: 'Failed updating your theme, please try again later!'
      }))
    }).finally(() => {
      setLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser])

  const changeProfilePic = (base64) => {
      const stor = firebase.app().storage().ref(`logos/integration/test/${userID}.jpg`)

      stor.putString(base64, 'data_url').then(async res => {
          console.log("Uplodated! got res: ", res)
          try {
            const res = await firebase.app().storage().ref(`logos/integration/test/${userID}.jpg`).getDownloadURL()
            setLogo(res)
            setBase64Logo(base64)

            firebase.functions().httpsCallable("updateTheme")({
              themeID: testThemeID.current,
              toUpdate: {
                logo: res,
                invoiceLogo: res
              }
            })
          } catch (_) {}
      })
  }

  const onChangeCompleteFontColor = (e) => {
    setFontColor(e.hex)
    firebase.functions().httpsCallable("updateTheme")({
      themeID: testThemeID.current,
      toUpdate: {
        color: e.hex
      }
    })
  }

  const onChangeCompleteBackgroundColor = (e) => {
    setBackgroundColor(e.hex)
    firebase.functions().httpsCallable("updateTheme")({
      themeID: testThemeID.current,
      toUpdate: {
        backgroundColor: e.hex
      }
    })
  }

  const onChangeCompleteTileBackground = (e) => {
    setTileBg(e.hex)
    firebase.functions().httpsCallable("updateTheme")({
      themeID: testThemeID.current,
      toUpdate: {
        tileBg: e.hex
      }
    })
  }

  const onSaveChanges = async () => {
    if (name?.length < 2) {
      dispatch(setErrorDetails({
        message: 'Name must have min 2 characters!'
      }))
      return
    }
    
    setOnSaving(true)
    let newLogo = logo
    if (base64Logo) {
      const stor = firebase.app().storage().ref(`logos/integration/${userID}.jpg`)
      const res = await stor.putString(base64Logo, 'data_url')
      console.log("Uplodated! got res: ", res)
      try {
        const res = await firebase.app().storage().ref(`logos/integration/${userID}.jpg`).getDownloadURL()
        newLogo = res
      } catch (_) {}
    }
    firebase.functions().httpsCallable("updateTheme")({
      themeID: dbUser?.integrationThemeID,
      toUpdate: {
        name: name,
        title: name,
        logo: newLogo,
        invoiceLogo: newLogo,
        color: fontColor,
        backgroundColor: backgroundColor,
        tileBg: tileBg,
        width: width
      }
    }).then((res) => {
      dispatch(setUserState({
        integrationThemeID: res.data.themeID
      }))
    }).finally(() => {
      dispatch(setErrorDetails({
        message: 'Your new theme for integration site was successfully saved!',
        severity: 'success'
      }))
      setOnSaving(false)
    })
  }

  const website = config.webSite
  const code = teamCode ?? dbUser.code
  const urlName = teamCode ? `team/name` : dbUser.name.split(' ').join('.') + '.' + dbUser.surname.split(' ').join('.')

  const iframeCode = `<div id="widget_container">
  <script type="text/javascript">
  function onLoadIframe() {
  // the iframe won't show until the load is complete, so here you can put a loading or something to finish
  const integrationIframe = document.getElementById('integration_loki')
  if (integrationIframe) {
  integrationIframe.style.visibility = 'visible';
  window.addEventListener('message', function (event) {
  if (event.origin === '${website}') {
  if (event.data.height && event.data.height > window.innerHeight) {
  const height = event.data.height;
  integrationIframe.style.height = height + 'px';
  } else {
  integrationIframe.style.height = window.innerHeight + 'px';
  }
  if (event.data.width !== undefined) {
  const width = event.data.width;
  integrationIframe.style.width = width + '%';
  integrationIframe.style.margin = '0 calc((100% - ' + width + '%) / 2)';
  }
  }
  });
  window.addEventListener('load', function () {
  integrationIframe.src = document.getElementById('integration_loki').src + '&rand=' + Math.round(Math.random() * 10000000);
  })
  const wind = integrationIframe.contentWindow
  if (wind) {
  const offset = document.getElementById('widget_container').offsetTop
  setInterval(function () {
  wind.postMessage({
  scrollHeight: window.scrollY,
  maxHeight: window.innerHeight,
  offset: offset
  }, '*');
  }, 500)
  }
  }
  }
  </script>
  <iframe
  src='${website}/${urlName}/${code}?integration=${code}'
  title='Loki integration'
  id='integration_loki'
  allow='camera;microphone'
  style='visibility:hidden;width:100%;min-height:600px;border:none;'
  onload="onLoadIframe()"
  ></iframe>
  </div>`

  return (
    <>
      <div className='website_form'>
        {loading ? 
          <div className='loading_container'>
            <CircularProgress size={50} />
          </div>
        :
          <>
            {iframeLoaded && testThemeID.current ?
              <>
                <p className='center'>Your Logo (click on it to change)</p>
                <ImagePicker
                    extensions={['jpg', 'jpeg', 'png']}
                    dims={{ minWidth: 10, maxWidth: 1000, minHeight: 10, maxHeight: 1000 }}
                    onChange={base64 => {
                        changeProfilePic(base64)
                    }}
                    onError={() => { 
                      dispatch(setErrorDetails({
                        message: "Error uploading your logo. It must be max 1000x1000!"
                      }))
                    }}
                >
                    <Button className='image_picker_button'>
                        <img className='profile_pic_image profile_pic' alt="" src={logo ?? require("../../images/user.png") } />
                    </Button>
                </ImagePicker>
          
                <p>Website Name*</p>
                <TextField
                  variant="outlined"
                  size='small'
                  onChange={(e) => setName(e.target.value)}
                  onBlur={() => {
                    firebase.functions().httpsCallable("updateTheme")({
                      themeID: testThemeID.current,
                      toUpdate: {
                        name: name,
                        title: name
                      }
                    })
                  }}
                  value={name}
                  fullWidth
                />
          
                <div className='parent_row'>
                  <div className='row'>
                    <div>
                      <p>Buttons Color</p>
                      <ChromePicker color={fontColor ?? defaultTheme.mainColor} onChangeComplete={onChangeCompleteFontColor} />
                    </div>
                    <div>
                      <p>Background Color</p>
                      <ChromePicker color={backgroundColor ?? defaultTheme.backgroundColor} onChangeComplete={onChangeCompleteBackgroundColor}/>
                    </div>
                  </div>
                  <div>
                    <p>Tile Background</p>
                    <ChromePicker color={tileBg ?? 'white'} onChangeComplete={onChangeCompleteTileBackground} />
                  </div>
                </div>
          
                <div className='width_row'>
                  <p>Width (percentage):</p>
                  <TextField
                      margin="dense"
                      id="percentage"
                      type="number"
                      value={width}
                      onChange={(e) => { 
                          let value = parseInt(e.target.value)
                          if (value < 0 || isNaN(value)) {
                              value = 0
                          }
                          if (value > 100) {
                              value = 100
                          }
                          firebase.functions().httpsCallable("updateTheme")({
                            themeID: testThemeID.current,
                            toUpdate: {
                              width: value
                            }
                          })
                          setWidth(value)
                      }}
                  />
                </div>

                <TextField
                  className='integration_code_input'
                  value={iframeCode}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <FileCopyOutlined className='copy_icon' onClick={() => {
                        copy(iframeCode)
                        dispatch(setErrorDetails({
                          message: 'Successfully copied',
                          severity: 'success'
                        }))
                      }} />
                    </InputAdornment>,
                  }}
                  disabled={true}
                />
              </>
            :
              <div className='loading_container'>
                <CircularProgress size={50} />
              </div>
            }
            
          </>
        }
      </div>

      <iframe
        src={`${website}/${urlName}/${code}?integration=${code}&testIntegration=true`}
        title='Loki integration'
        id="integration_loki_preview"
        allow="camera;microphone"
        style={{ visibility: "hidden", width: "calc(100% - 4px)", margin: 'auto', minHeight: 600, border: '2px solid gray' }}
        onLoad={() => {
          // the iframe won't show until the load is complete, so here you can put a loading or something to finish
          const integrationIframe = document.getElementById('integration_loki_preview')
          if (integrationIframe) {
            integrationIframe.style.visibility = 'visible';
            
            window.addEventListener('message', function (event) {
              if (event.origin === website) {
                if (event.data.height && event.data.height > window.innerHeight) {
                  const height = event.data.height;
                  integrationIframe.style.height = height + 'px';
                } else {
                  integrationIframe.style.height = window.innerHeight + 'px';
                }
                if (event.data.width !== undefined) {
                  const width = event.data.width;
                  integrationIframe.style.width = 'calc(' + width + '% - 4px)';
                  integrationIframe.style.margin = '0 calc((100% - ' + width + '%) / 2)';
                }
              }
            });

            window.addEventListener('load', function () {
              integrationIframe.src = document.getElementById("integration_loki_preview").src + '&rand=' + Math.round(Math.random() * 10000000);
            })

            const wind = integrationIframe.contentWindow
            if (wind) {
              setInterval(() => {
                wind.postMessage({
                  scrollHeight: window.scrollY,
                  maxHeight: window.innerHeight
                }, "*");
              }, 500)
            }
          }

          setIframeLoaded(true)
        }}
      />

      {iframeLoaded && testThemeID.current &&
        <div className='website_form'>
          <Button
            className="save_button"
            color='primary'
            variant='contained'
            disabled={onSaving}
            onClick={onSaveChanges}
          >
            Save Changes
          </Button>
        </div>
      }
    </>
  )
}

export default WebsiteIntegration;
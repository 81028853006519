import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

const ConfirmAlert = (props) => {
  const open = props.data.open
  const title = props.data.title
  const description = props.data.description
  const handleConfirm = props.data.onConfirm
  const handleCancel = props.data.onCancel
  const onClose = props.data.onClose
  const okTitle = props.data.okTitle ?? 'I understand'
  const cancelTitle = props.data.cancelTitle ?? 'Cancel'

  const useStyles = makeStyles({
    description: {
      whiteSpace: 'pre-line'
    }
  });
  const styleClasses = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <p className={styleClasses.description}>{description}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className='confirm_alert_ok_button' onClick={handleConfirm} color='primary'>
          {okTitle}
        </Button>
        {handleCancel &&
          <Button onClick={handleCancel} color='primary'>
            {cancelTitle}
          </Button>
        }
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmAlert
import React, { useState } from 'react'
import firebase from 'firebase/app'
import { Slider, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, CircularProgress } from '@material-ui/core'
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons'
import "react-multi-date-picker/styles/colors/green.css"
import { capitalizeFirstLetter } from '../../../utils/utils'
import TimezoneSelect from 'react-timezone-select'
import { marks, miniMarks, timeConvert } from './sliderRangeUtils'
import { setErrorDetails } from '../../../api/userSlice'
import { useDispatch } from 'react-redux'

export default function AvailabilityDialog(props) {
    const {
        user,
        handleClose,
        open,
        daysOfTheWeek,
        daysCheckedStates,
        sliderRanges,
        setDaysCheckedStates,
        setSliderRanges,
        selectedTimezone,
        setSelectedTimezone
    } = props
    const dispatch = useDispatch()

    const [isUpdating, setIsUpdating] = useState(false)

    const widthLessThan500px = useMediaQuery('(max-width:499px)');

    const handleAvailabilityUpdate = () => {
        setIsUpdating(true)

        const newIntervals = []
        sliderRanges.forEach((slider, index) => {
            if (daysCheckedStates[index]) {
                slider.forEach(sliderRange => {
                    const interval = {
                        offset: sliderRange[0] + 1440 * index,
                        duration: sliderRange[1] - sliderRange[0],
                    }
                    newIntervals.push(interval)
                })
            }
        })

        const toSet = {
            intervals: newIntervals,
            timezone: selectedTimezone.value ?? selectedTimezone
        }

        firebase.app().functions().httpsCallable("updateUserProfile")({
            updateData: JSON.stringify(toSet),
            targetUserID: user.userID,
            isSchedules: true
        }).then(() => {
            console.log("Success updating!")
            handleClose()
            setIsUpdating(false)
        }).catch((error) => {
            dispatch(setErrorDetails({
              message: error.message
            }))
            setIsUpdating(false)
        })
    }

    const handleSwitch = (index) => {
        const copiedArray = [...daysCheckedStates]
        copiedArray[index] = !copiedArray[index]
        setDaysCheckedStates(copiedArray)
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className='availability_dialog'>
            <DialogTitle id="form-dialog-title">Availability Schedule</DialogTitle>
            <DialogContent className='availability_content_dialog'>
                <div className='timezones'>
                    <TimezoneSelect
                        value={selectedTimezone}
                        onChange={setSelectedTimezone}
                    />
                </div>
                {daysOfTheWeek.map((each, index) => {
                    return <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={daysCheckedStates[index]}
                                    onChange={() => handleSwitch(index)}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label={capitalizeFirstLetter(each)}
                        />
                        {sliderRanges[index].map((range, indexRange) => (
                            <div key={indexRange} className='sliders_container'>
                                <Slider
                                    min={0}
                                    max={1440}
                                    disabled={!daysCheckedStates[index]}
                                    step={15}
                                    style={{ marginHorizontal: 20 }}
                                    value={range}
                                    onChange={(_, newValue) => {
                                        if (newValue[1] > newValue[0]) {
                                            const copySlider = [...sliderRanges]
                                            const copyRange = [...sliderRanges[index]]
                                            copyRange[indexRange] = newValue
                                            copySlider[index] = copyRange
                                            setSliderRanges(copySlider)
                                        }
                                    }}
                                    valueLabelFormat={(each) => {
                                        return timeConvert(each)
                                    }}
                                    valueLabelDisplay={daysCheckedStates[index] ? "on" : "off"}
                                    marks={widthLessThan500px ? miniMarks : marks}
                                />
                                {indexRange > 0 &&
                                    <RemoveCircleOutline onClick={() => setSliderRanges(oldSliderRanges => {
                                        const copySlider = [...oldSliderRanges];
                                        copySlider[index].splice(indexRange, 1);
                                        return copySlider
                                    })} />
                                }
                            </div>
                        ))}
                        {daysCheckedStates[index] &&
                            <div className='add_slider_button_container'>
                                <AddCircleOutline onClick={() => setSliderRanges(oldSliderRanges => {
                                    const copySlider = [...oldSliderRanges];
                                    copySlider[index].push([480, 1200]);
                                    return copySlider
                                })} />
                            </div>
                        }
                    </div>
                })}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="white">
                    Cancel
                </Button>
                <Button onClick={handleAvailabilityUpdate} color="primary" style={{ width: '70px' }}>
                    {isUpdating ? <CircularProgress size={10} /> : 'Update'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
import { CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import 'firebase/functions'
import firebase from 'firebase/app'
import { Link, useHistory } from 'react-router-dom'
import './styles.css'
import Header from './components/Header';
import ConsultantDetails from './components/ConsultantDetails'
import BookingInfo from './components/BookingInfo'
import BookingSelection from './components/BookingSelection'
import { ReactComponent as AudioIcon } from '../../../images/audio.svg';
import { ReactComponent as VideoIcon } from '../../../images/video.svg';
import ChatIcon from '@material-ui/icons/Chat'
import { config } from '../../../config/config'
import ReviewBooking from './components/ReviewBooking'
import ContactInformation from './components/ContactInformation'
import { useBookingContext } from './BookingContext'
import PaymentInformation from './components/PaymentInformation'
import BirthInformation from './components/BirthInformation'
import CreateAccountOptional from './components/CreateAccountOptional'
import { bookingsDisabled, isValidEmail } from '../../../utils/utils'
import ConfirmBooking from './components/ConfirmBooking'
import ContinueButton from './components/ContinueButton'
import CongratulationsBookingSent from './components/CongratulationsBookingSent'
import AdditionalInformation from './components/AdditionalInformation'
import SaveAdditionalInformation from './components/SaveAdditionalInformation'
import YoureAllSet from './components/YoureAllSet'
import { useGlobalStyles } from '../../App/globalStyles'
import GiftForm from './components/GiftForm'
import UserInformation from './components/UserInformation'
import UserPaymentInformation from './components/UserPaymentInformation'
import YoureAlmostDone from './components/YoureAlmostDone'
import ConfirmBookingButton from './components/ConfirmBookingButton'
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../../utils/routes'
import moment from 'moment'
import useCookieTheme from '../../App/useCookieTheme'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorDetails, userState } from '../../../api/userSlice'
import useBookingHook from '../useBookingHook'

export const typeOptions = () => {
  const types = [
    {
      label: "Video Call",
      id: "video",
      icon: <VideoIcon />
    },
    {
      label: "Audio Call",
      id: "audio",
      icon: <AudioIcon />
    }
  ];
  if (config.type !== 'astrologer') {
    types.push({
      label: "Text Chat",
      id: "chat",
      icon: <ChatIcon className='chat_icon' />
    })
  }
  return types
}

export const indexToLength = [15, 30, 45, 60, 75, 90]; //FIXME check if can delete

const ConsultantBooking = (props) => {
  const { consultant } = props.match.params
  const { changePage } = useCookieTheme()
  const dispatch = useDispatch()
  const history = useHistory()
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const isConsultant = useSelector(userState).user?.consultant
  const isSignUpInBookingProcess = useSelector(userState).isSignUpInBookingProcess
  const integration = useSelector(userState).integration
  const isGift = JSON.parse((new URL(document.location)).searchParams.get("isGift") ?? null)
  const rescheduleBookingID = (new URL(document.location)).searchParams.get("rescheduleBookingID");
  
  const {
    consultantData,
    setConsultantData,
    isDetailsForm,
    setIsDetailsForm,
    isFinishBooking,
    setIsFinishBooking,
    contactInfoData,
    paymentInfoData,
    birthInfosData,
    createAccountData,
    openForm,
    couponData,
    discountCouponData,
    setDiscountCouponData,
    giftData,
    isCongrats,
    isAlmostDone,
    isAllSet,
    toPayment,
    setIsTenPercentDiscount,
    setBookingData,
    submittedAdditionalInfoData
  } = useBookingContext()
  const { removeBooking } = useBookingHook()
  
  if (consultantData) {
    const bookingsDisabledRes = bookingsDisabled(isConsultant, consultantData)
    if (bookingsDisabledRes) {
      history.push(COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID))
    }
  }

  const [disableContinueInfoButton, setDisableContinueInfoButton] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [emailUsed, setEmailUsed] = useState(false)

  useEffect(() => {
    return firebase.app().firestore().doc(`/users/${consultant}`).onSnapshot((snapshot) => {
      const data = snapshot.data()

      if (!data?.consultant) {
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.HOME
        })
      } else {
        setConsultantData(data)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultant, setConsultantData])

  useEffect(() => {
    if (consultantData && userID && !isConsultant) {
      if (integration && consultantData.integrationThemeID && consultantData.integrationThemeID !== dbUser?.integrationThemeID) {
        firebase.app().firestore().doc(`/users/${userID}`).update({
          integrationThemeID: consultantData.integrationThemeID
        });
      }
      if (consultantData.themeID !== dbUser?.themeID) {
        firebase.app().firestore().doc(`/users/${userID}`).update({
          themeID: consultantData.themeID
        });
      }
    }
  }, [consultantData, integration, isConsultant, userID, dbUser])

  useEffect(() => {
    window.onbeforeunload = function() {
        return true;
    };
    const toPaymentRef = toPayment

    return () => {
        window.onbeforeunload = null;
        if (!toPaymentRef.current) {
          removeBooking()
        }
    };
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  useEffect(() => {
    const checkDiscountCoupon = async () => {
      if (discountCouponData && userID && consultantData && config.type !== 'astrologer') {
        const res = await firebase.functions().httpsCallable("checkDiscountCouponForClient")({
          code: discountCouponData.code,
          consultantID: consultantData.userID,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          rescheduleBookingID
        })
        if (res.data.status === 'used') {
          setDiscountCouponData(null)
          setIsDetailsForm(false)
          setIsFinishBooking(false)
          dispatch(setErrorDetails({
            message: 'Discount coupon code already used'
          }))
        }
      }
    }

    checkDiscountCoupon()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountCouponData, consultantData])

  useEffect(() => {
    const checkTenPercentDiscount = async () => {
      firebase.app().firestore().collection(`/bookings`)
                        .where('clientEmail', '==', contactInfoData.email)
                        .where('consultantID', '==', consultantData.userID)
                        .where('status', '==', 'accepted')
                        .where('startDate', '<', new Date())
                        .orderBy('startDate', 'desc')
                        .onSnapshot(res => {
        const booking = res.docs[0]?.data()
        if (booking) {
          const startDate = booking.startDate.toDate()
          const date72hoursAgo = moment().add(-3, 'days').add(booking.length, 'minutes').toDate()
          const now = moment().add(-(booking.length), 'minutes').toDate()
          if (startDate.getTime() > date72hoursAgo.getTime() && startDate.getTime() < now.getTime()) {
            setIsTenPercentDiscount(true)
            if (!userID) {
              setBookingData(oldBookingData => {
                if (oldBookingData) {
                  return {
                    ...oldBookingData,
                    data: oldBookingData.data.oldPrice ? {
                        ...oldBookingData.data
                      }
                    :
                      {
                        ...oldBookingData.data,
                        estimatedPrice: Math.round(oldBookingData.data.estimatedPrice * 0.9),
                        oldPrice: oldBookingData.data.estimatedPrice
                    }
                  }
                } else {
                  return oldBookingData
                }
              })
            }
          } else {
            setIsTenPercentDiscount(false)
            if (!userID) {
              setBookingData(oldBookingData => {
                if (oldBookingData) {
                  return {
                    ...oldBookingData,
                    data: oldBookingData.data.oldPrice ? {
                        ...oldBookingData.data,
                        estimatedPrice: oldBookingData.data.oldPrice,
                        oldPrice: null
                      }
                    :
                      {
                        ...oldBookingData.data
                    }
                  }
                } else {
                  return oldBookingData
                }
              })
            }
          }
        } else {
          setIsTenPercentDiscount(false)
          if (!userID) {
            setBookingData(oldBookingData => {
              if (oldBookingData) {
                return {
                  ...oldBookingData,
                  data: oldBookingData.data.oldPrice ? {
                      ...oldBookingData.data,
                      estimatedPrice: oldBookingData.data.oldPrice,
                      oldPrice: null
                    }
                  :
                    {
                      ...oldBookingData.data
                  }
                }
              } else {
                return oldBookingData
              }
            })
          }
        }
      })
    }

    if (config.type === 'astrologer') {
      if (discountCouponData || couponData) {
        setIsTenPercentDiscount(false)
      } else if (contactInfoData?.email && consultantData?.userID) {
        checkTenPercentDiscount()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfoData, discountCouponData, couponData, consultantData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [isDetailsForm, isFinishBooking, isCongrats, isAllSet])

  useEffect(() => {
    let disable = false
    if (!rescheduleBookingID) {
      disable = !contactInfoData || !contactInfoData.firstName || !contactInfoData.lastName || 
                !contactInfoData.email || !isValidEmail(contactInfoData.email)}
    if (isGift) {
      disable = disable || !giftData || !giftData.name || !isValidEmail(giftData.email) || 
                !isValidEmail(giftData.confirmEmail) || giftData.email !== giftData.confirmEmail || !giftData.deliveryDate
    } else {
      if (!couponData && !rescheduleBookingID) {
        disable = disable || !paymentInfoData
      }
      if (consultantData?.needBirthForm) {
        disable = disable ||
          !birthInfosData || !birthInfosData.birthDate || !birthInfosData.city || !birthInfosData.country
      }
    }

    const disableCreateAccount = !createAccountData || !createAccountData.password || !createAccountData.confirmPassword || createAccountData.password !== createAccountData.confirmPassword
    setDisableContinueInfoButton(disable || (openForm && disableCreateAccount))
  }, [contactInfoData, paymentInfoData, birthInfosData, openForm, createAccountData, isGift, giftData, couponData, consultantData, rescheduleBookingID])

  const signUpTapped = () => {
    let hasErrors = false;
    dispatch(setErrorDetails({
      message: null
    }))

    // We got here, so that means all of the above checks are k, time for a few more
    if (createAccountData.password.length < 6) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "The password should have minimum 6 characters!"
      }))
    }
    if (contactInfoData.lastName.length > 20) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "Invalid first name size. At most 20 chars."
      }))
    }
    if (contactInfoData.firstName.length > 20) {
      hasErrors = true;
      dispatch(setErrorDetails({
        message: "Invalid last name size. At most 20 chars."
      }))
    }

    if (!hasErrors) {
      setIsFinishBooking(true)
      setIsDetailsForm(false)
    }
  }

  const checkEmail = async () => {
    setIsLoading(true)
    const existsRes = await firebase.app().functions().httpsCallable("checkIfUserExists")({ email: contactInfoData.email })
    if (existsRes.data?.exists) {
      dispatch(setErrorDetails({
        message: 'This email is already in use. Either login or enter another email as guest!'
      }))
    } else {
      setIsFinishBooking(true)
      setIsDetailsForm(false)
    }
    setIsLoading(false)
  }

  useGlobalStyles()

  if (isConsultant && consultantData) {
    changePage({
      pathname: COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID)
    })
  }

  return (
    <>
      {consultantData ?
        <div className='consultant_booking_content'>
          <Header />
          <ConsultantDetails />
          
          {isDetailsForm &&
            <>
              <ReviewBooking isGift={isGift} rescheduleBookingID={rescheduleBookingID} />
              {userID ? 
                <>
                  <UserInformation />
                  {isGift &&
                    <GiftForm />
                  }
                  {!couponData && !rescheduleBookingID &&
                    <UserPaymentInformation />
                  }
                  <div className='item continue_info_button'>
                    <ConfirmBookingButton
                      rescheduleBookingID={rescheduleBookingID}
                      isGift={isGift}
                    />
                  </div>
                </>
              :
                <>
                  {!rescheduleBookingID &&
                    <ContactInformation
                      setEmailUsed={setEmailUsed}
                    />
                  }
                  {!couponData && !rescheduleBookingID &&
                    <PaymentInformation />
                  }

                  {isGift ?
                    <GiftForm />
                  :
                    <>
                      {consultantData.needBirthForm && <BirthInformation />}
                    </>
                  }

                  <CreateAccountOptional />
                  <div className='item continue_info_button'>
                    <ContinueButton
                      disabled={disableContinueInfoButton || isLoading}
                      loading={isLoading}
                      onClick={() => {
                        if (emailUsed) {
                          dispatch(setErrorDetails({
                            message: 'This email is already in use. Either login or enter another email as guest!'
                          }))
                          return
                        }
                        if (contactInfoData.email !== contactInfoData.confirmEmail) {
                          dispatch(setErrorDetails({
                            message: "The emails do not match!"
                          }))
                          return
                        }
          
                        if (openForm) {
                          signUpTapped()
                        } else {
                          if (isGift) {
                            setIsFinishBooking(true)
                            setIsDetailsForm(false)
                          } else {
                            checkEmail()
                          }
                        }
                      }}
                      text={userID ? 'CONFIRM' : 'CONTINUE'}
                    />
                  </div>
                </>
              }
            </>
          }

          {isFinishBooking && 
            <ConfirmBooking
              rescheduleBookingID={rescheduleBookingID}
              isGift={isGift}
            />
          }

          {isCongrats && 
            <>
              <CongratulationsBookingSent />
              <AdditionalInformation />
              {!userID && !isSignUpInBookingProcess && submittedAdditionalInfoData &&
                <SaveAdditionalInformation />
              }
            </>
          }

          {isAlmostDone && 
            <>
              <YoureAlmostDone />
              <AdditionalInformation />
            </>
          }

          {isAllSet && 
            <YoureAllSet
              isGift={isGift}
              rescheduleBookingID={rescheduleBookingID}
            />
          }

          {!isDetailsForm && !isFinishBooking && !isCongrats && !isAlmostDone && !isAllSet &&
            <>
              <BookingInfo isGift={isGift} />
              <BookingSelection
                isGift={isGift}
                rescheduleBookingID={rescheduleBookingID}
              />
            </>
          }

          {config.type === 'astrologer' &&
            <div className='item footer'>
              <i>Contact customer service at <Link to="#" onClick={() => window.open('mailto:support@astrologyhub.com', '_blank')}>support@astrologyhub.com</Link></i>
            </div>
          }
        </div>
      :
        <div className='consultant_booking_loading'>
          <CircularProgress size={50} />
        </div>
      }
    </>
  )
}

export default ConsultantBooking;

import React from 'react';
import { useState } from 'react';
import firebase from 'firebase';
import useCookieTheme from '../../App/useCookieTheme';
import { useEffect } from 'react';
import { setErrorDetails } from '../../../api/userSlice';
import { useDispatch } from 'react-redux';

const TeamContext = React.createContext();

function TeamProvider(props) {
  console.log('RENDER TEAM CONTEXT')
  const authUser = firebase.auth().currentUser
  const { consultantTitle } = useCookieTheme()
  const dispatch = useDispatch()

  const [dataSource, setDataSource] = useState(null)
  const [consultants, setConsultants] = useState([])
  const [invites, setInvites] = useState([])
  const [isFetchingData, setIsFetching] = useState(true)
  const [paypalEnabled, setPaypalEnabled] = useState(false)
  const [bankEnabled, setBankEnabled] = useState(false)
  const [total, setTotal] = useState(0)
  const [userLocale, setUserLocale] = useState("")
  const [alertInfo, setAlertInfo] = useState({ open: false })

  useEffect(() => {
    if (dataSource) {
    let totalBalance = 0
    dataSource.forEach(each => {
      const balance = each["walletBalance"]
      totalBalance = totalBalance + balance
    })
    setTotal(totalBalance)
    }
  }, [dataSource])

  const fetchUserData = () => {
    if (authUser.uid) {
      firebase.firestore().collection("/users").doc(authUser.uid).get().then(snapshot => {
        const userData = snapshot.data()
        setBankEnabled(Boolean(userData["bank_account"]))
        setPaypalEnabled(Boolean(userData["paypal_account"]))
        setUserLocale(userData["locale"])
        fetchConsultantStats()
      }).catch(error => {
        console.log(error)
        dispatch(setErrorDetails({
          message: "Error fetching user data!"
        }))
      })
    } else {
      dispatch(setErrorDetails({
        message: "You're not authenticated!"
      }))
    }
  }

  const fetchConsultantStats = () => {
    firebase.functions().httpsCallable('getTeamWorkersStats')().then(function (result) {
      const resultData = JSON.parse(result.data)
      const stats = resultData.stats

      setConsultants(stats)
      rebuildDataSource()
    }).catch(error => {
      console.log(error)
      dispatch(setErrorDetails({
        message: `Cannot get ${consultantTitle}s!`
      }))
    }).finally(() => {
      setIsFetching(false)
    })
  }

  const addInvitesListener = () => {
    firebase.firestore().collection("/team_invites").where("masterId", "==", authUser.uid).where("status", "==", "pending")
      .onSnapshot((snapshot) => {
        const invitesFetched = snapshot.docs.map(doc => { return doc.data() });
        setInvites(invitesFetched)
      });
  }

  const dataSourcedConsultants = () => {
    return consultants.map(each => {
      return {
        name: each.name,
        email: each.email,
        profileLink: each.profileLink,
        walletBalance: each.walletBalance,
        timeSpentInCall: each.timeSpentInCall,
        locale: each.consultantLocale,
        status: "active",
        type: "consultant",
        consultantID: each.consultantID,
        memberCut: each.memberCut,
        teamMemberWalletBalance: each.teamMemberWalletBalance
      }
    })
  }

  const dataSourcedInvites = () => {
    return invites.map(each => {
      return {
        name: each.email,
        profileLink: "",
        walletBalance: 0,
        timeSpentInCall: 0,
        locale: each.locale,
        status: "pending",
        type: "invite",
        teamMemberWalletBalance: 0,
        consultantID: each.userId,
      }
    })
  }

  const rebuildDataSource = () => {
    const dataSource = dataSourcedConsultants().concat(dataSourcedInvites())
    setDataSource(dataSource)
  }

  useEffect(rebuildDataSource, [invites, consultants])

  return (
      <TeamContext.Provider value={{
        addInvitesListener,
        setIsFetching,
        alertInfo,
        setAlertInfo,
        fetchConsultantStats,
        consultants,
        dataSource,
        isFetchingData,
        fetchUserData,
        total,
        userLocale,
        paypalEnabled,
        bankEnabled
       }}>
          {props.children}
      </TeamContext.Provider>
  );
}

function useTeamContext() {
    const teamContext = React.useContext(TeamContext);
    if (!teamContext) {
        throw new Error('useTeamContext must be used within a TeamProvider');
    }
    return teamContext;
}

export { TeamProvider, useTeamContext };
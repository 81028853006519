import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { TextValidator } from 'react-material-ui-form-validator';
import ReactDatePicker from 'react-datepicker';
import { forwardRef } from 'react';

const BirthInfosForm = (props) => {
  const { birthInfosState, setBirthInfoState } = props

  const range = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }
  const years = range(1900, (new Date().getFullYear()) + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  
  const widthLessThan600px = useMediaQuery('(max-width:599px)');
  const heightLessThan750px = useMediaQuery('(max-height:749px)');

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <TextValidator
      variant="outlined"
      size='small'
      onClick={onClick}
      value={value}
      fullWidth
      autoComplete='off'
      placeholder='Birth Date *'
    />
  ));
  
  return (
    <>
      <Grid item xs={widthLessThan600px ? 12 : 6}>
        <ReactDatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button className='arrow_button' onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                {"<"}
              </button>
              <select
                className='select_input_date'
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                className='select_input_date'
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <button className='arrow_button' onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                {">"}
              </button>
            </div>
          )}
          selected={birthInfosState?.birthDate}
          onChange={(date) => setBirthInfoState(oldBirthInfosState => {
            return {
              ...oldBirthInfosState,
              birthDate: date
            }
          })}
          showTimeSelect
          timeIntervals={1}
          dateFormat="MMMM d yyyy, h:mm aa"
          popperPlacement='bottom'
          popperModifiers={{
            preventOverflow: {
              enabled: heightLessThan750px,
            },
          }}
          disabledKeyboardNavigation
          customInput={<CustomInput />}
        />
      </Grid>
      <Grid item xs={widthLessThan600px ? 12 : 6}>
        <TextValidator
          variant="outlined"
          size='small'
          onChange={(e) => {
            const { value } = e.target
            setBirthInfoState(oldBirthInfosState => ({
              ...oldBirthInfosState,
              city: value
            }))
          }}
          value={birthInfosState?.city}
          label="Birth City"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={widthLessThan600px ? 12 : 6}>
        <TextValidator
          variant="outlined"
          size='small'
          onChange={(e) => {
            const { value } = e.target
            setBirthInfoState(oldBirthInfosState => ({
              ...oldBirthInfosState,
              state: value
            }))
          }}
          value={birthInfosState?.state}
          label="Birth State"
          fullWidth
        />
      </Grid>
      <Grid item xs={widthLessThan600px ? 12 : 6}>
        <TextValidator
          variant="outlined"
          size='small'
          onChange={(e) => {
            const { value } = e.target
            setBirthInfoState(oldBirthInfosState => ({
              ...oldBirthInfosState,
              country: value
            }))
          }}
          value={birthInfosState?.country}
          label="Birth Country"
          fullWidth
          required
        />
      </Grid>
    </>
  )
};

export default BirthInfosForm;

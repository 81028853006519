import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, TextField, capitalize } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useBookingContext } from '../BookingContext';
import firebase from 'firebase/app'
import useCookieTheme from '../../../App/useCookieTheme';
import { useSelector } from 'react-redux';
import { userState } from '../../../../api/userSlice';

const ClientDetailsForm = () => {
  const {
    bookingID,
    birthInfosData,
    additionalInfoData,
    setAdditionalInfoData,
    setIsAlmostDone,
    setIsAllSet,
    setIsCongrats,
    submittedAdditionalInfoData,
    setSubmittedAdditionalInfoData
  } = useBookingContext()
  const userID = useSelector(userState).user?.userID
  const { consultantTitle, appointmentName } = useCookieTheme()

  const [disableButton, setDisableButton] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    const disable = !additionalInfoData?.questionsText || isSaving;

    setDisableButton(disable)
  }, [additionalInfoData, isSaving])

  const handleSave = async () => {
    if (submittedAdditionalInfoData) {
      setIsAllSet(true)
      if (userID) {
        setIsAlmostDone(false)
      } else {
        setIsCongrats(false)
      }
    } else {
      setIsSaving(true)
      const clientDetailsData = {
        birthDate: Math.trunc(new Date(new Date(birthInfosData.birthDate).setMilliseconds(0)).getTime() / 1000),
        address: `${birthInfosData.city}, ${birthInfosData.state ? `${birthInfosData.state}, ` : ''}${birthInfosData.country}`,
      }
      if (additionalInfoData?.questionsText) {
        clientDetailsData.questionsText = additionalInfoData.questionsText
      }
      if (additionalInfoData?.readingInThePast) {
        clientDetailsData.readingInThePast = additionalInfoData.readingInThePast
      }
      if (additionalInfoData?.anythingElseText) {
        clientDetailsData.anythingElseText = additionalInfoData.anythingElseText
      }
      await firebase.functions().httpsCallable('updateClientDetailsBooking')({
        bookingID: bookingID,
        clientDetails: clientDetailsData
      })

      setSubmittedAdditionalInfoData(true)
      setIsSaving(false)
    }
  }

  return (
    <div className='details_client_form'>
      <ValidatorForm
        onError={errors => console.log(errors)}
        onSubmit={() => { return }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              multiline
              variant='outlined'
              label={`What question would you like to ask your ${capitalize(consultantTitle)}?`}
              value={additionalInfoData?.questionsText}
              onChange={(e) => {
                const { value } = e.target
                setAdditionalInfoData(oldAdditionalInfoData => ({
                  ...oldAdditionalInfoData,
                  questionsText: value
                }))
                setSubmittedAdditionalInfoData(false)
              }}
              rows={3}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              variant='outlined'
              label={`Have you ever had ${appointmentName ? `a ${appointmentName}` : 'an appointment'} in the past? (optional)`}
              value={additionalInfoData?.readingInThePast}
              onChange={(e) => {
                const { value } = e.target
                setAdditionalInfoData(oldAdditionalInfoData => ({
                  ...oldAdditionalInfoData,
                  readingInThePast: value
                }))
                setSubmittedAdditionalInfoData(false)
              }}
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              variant='outlined'
              label="Anything else we should know? (optional)"
              value={additionalInfoData?.anythingElseText}
              onChange={(e) => {
                const { value } = e.target
                setAdditionalInfoData(oldAdditionalInfoData => ({
                  ...oldAdditionalInfoData,
                  anythingElseText: value
                }))
                setSubmittedAdditionalInfoData(false)
              }}
              rows={3}
            />
          </Grid>
        </Grid>
      </ValidatorForm>
      <div className='button_container'>
         <p className='thank_you_text'>{submittedAdditionalInfoData ? 'Thank you! Your answers have been shared with your astrologer.' : ''}</p>
        <Button
          disabled={disableButton}
          onClick={handleSave}
          color="primary"
          variant="contained"
        >
          {isSaving ? <CircularProgress size={24} /> : submittedAdditionalInfoData ? 'CONTINUE' : 'SEND'}
        </Button>
      </div>
    </div >
  )
};

export default ClientDetailsForm;

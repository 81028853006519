import React, { useState } from 'react';
import { MoreVert } from '@material-ui/icons';
import { Menu, MenuItem } from '@material-ui/core';
import firebase from 'firebase/app'
import ConfirmAlert from '../../../components/ConfirmAlert';
import useCookieTheme from '../../../App/useCookieTheme';
import { COMMON_ROUTES } from '../../../../utils/routes';
import { setErrorDetails } from '../../../../api/userSlice';
import { useDispatch } from 'react-redux';

const BookingCardActions = (props) => {
  const {
    isClient,
    bookingID,
    consultantID,
    setAllBookingsLoaded,
    widthMoreThan725px,
    setOpenIntakeInfos,
    clientDetails
  } = props;
  const dispatch = useDispatch()
  const { consultantTitle, changePage } = useCookieTheme()

  const [alertInfo, setAlertInfo] = useState({ open: false })
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleReschedule = () => {
    setAlertInfo({
      open: true, title: "",
      description: `Are you sure you want to reschedule this booking? Once you confirm, your old booking will be cancelled and the new one should be accepted again by ${consultantTitle}.`,
      onConfirm: () => {
        changePage({
          pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', consultantID),
          search: `rescheduleBookingID=${bookingID}`
        })
      }, onCancel: hideAlert, okTitle: "I'm sure!"
    })
  }

  const handleCancel = () => {
    setAlertInfo({
      open: true, title: "",
      description: "Are you sure you want to cancel this booking?",
      onConfirm: () => {
        setAllBookingsLoaded(false)
        hideAlert()
        firebase.functions().httpsCallable("cancelBooking")({ bookingID: bookingID, isConsultant: !isClient }).catch((e) => {
          dispatch(setErrorDetails({
            message: e.message
          }))
        }).finally(_ => {
          setAllBookingsLoaded(true)
        })
      },
      onCancel: hideAlert,
      okTitle: "I'm sure!",
      cancelTitle: "Dismiss"
    })
  }

  const hideAlert = () => {
    setAlertInfo(oldAlertInfo => ({
      ...oldAlertInfo,
      open: false
    }))
    setOpenIntakeInfos(null)
  }

  const handleIntakeInformation = (event) => {
    setOpenIntakeInfos(event.currentTarget)
  }

  return (
    <div className='booking_card_actions'>
      <MoreVert onClick={handleClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {isClient &&
          <MenuItem onClick={handleReschedule}>
            Reschedule
          </MenuItem>
        }
        {!isClient && !widthMoreThan725px && clientDetails &&
          <MenuItem onClick={handleIntakeInformation}>
            Intake information
          </MenuItem>
        }
        <MenuItem onClick={handleCancel}>
          Cancel
        </MenuItem>
      </Menu>
      
      {alertInfo.open && <ConfirmAlert data={alertInfo} />}
    </div>
  );
}

export default BookingCardActions
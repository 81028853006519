import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import "./styles.css";
import { Button, CircularProgress, DialogActions, TextField } from '@material-ui/core';
import { useState } from 'react';
import firebase from 'firebase/app'
import { useBookingContext } from '../../BookingContext';
import { indexToLength } from '../..';
import { capitalizeFirstLetter } from '../../../../../utils/utils';
import useCookieTheme from '../../../../App/useCookieTheme';
import { useDispatch } from 'react-redux';
import { setErrorDetails } from '../../../../../api/userSlice';

const CheckCouponDialog = (props) => {
  const {
    open,
    setOpen,
    setSessionTypeId,
    setSessionLength,
    bAmounts,
    rescheduleBookingID
  } = props
  const dispatch = useDispatch()
  const { consultantData, setCouponData, setDiscountCouponData } = useBookingContext()
  const [code, setCode] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const { consultantTitle } = useCookieTheme()

  const onCheckCoupon = async () => {
    setLoading(true)

    if (code.length === 4) {
      const res = await firebase.app().firestore().collection('/gifts').where("code", "==", code).get()
      const data = res.docs[0]?.data()
      if (data?.consultantID === consultantData.userID) {
        if (data.isUsed) {
          dispatch(setErrorDetails({
            message: 'Gift code already used'
          }))
          setLoading(false)
          return
        }

        const lengthOptions = bAmounts[data.callType]
        const length = lengthOptions.map((value, index) => {
          if (value !== 0) {
            return index
          }
          return null
        }).find(each => each === indexToLength.indexOf(data.length))
        if (length === undefined) {
          dispatch(setErrorDetails({
            message: `${consultantTitle ? capitalizeFirstLetter(consultantTitle) : 'Consultant'} is not able to accept this type of booking for moment. Please try again later!`
          }))
          setLoading(false)
          return
        }

        setCouponData({
          id: res.docs[0].id,
          code: data.code
        })
        setSessionTypeId(data.callType)
        setSessionLength(length)
        setOpen(false)
      } else {
        dispatch(setErrorDetails({
          message: 'Invalid gift code'
        }))
      }
    } else {
      const res = await firebase.functions().httpsCallable("checkDiscountCouponForClient")({
        code: code,
        consultantID: consultantData.userID,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        rescheduleBookingID
      })
      if (res.data?.error) {
        dispatch(setErrorDetails({
          message: res.data?.error
        }))
        setLoading(false)
        return
      }

      setDiscountCouponData({
        id: res.data?.id,
        code: code.toUpperCase(),
        percent: res.data?.percent
      })
      setOpen(false)
    }

    setLoading(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogContent>
        <DialogContentText>
          <TextField
            className='input_code'
            variant='outlined'
            margin="dense"
            label="Code"
            value={code}
            onChange={(event) => setCode(event.target.value.replace(/\s/g, ''))}
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className='check_button' disabled={!code || code.length < 4} onClick={onCheckCoupon} color="primary" variant="outlined">
          {loading ? <CircularProgress size={20} /> : 'Check code'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CheckCouponDialog

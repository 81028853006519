import React, { useEffect } from 'react';
import "./styles.css";
import 'firebase/functions'
import { Button, CircularProgress, Collapse, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Step, StepContent, StepLabel, Stepper, useMediaQuery } from '@material-ui/core';
import useTestAgoraHook from './useTestAgoraHook';
import ReactAudioPlayer from 'react-audio-player';
import { useState } from 'react';
import { Check, ExpandLess, ExpandMore, Warning } from '@material-ui/icons';
import Countdown from 'react-countdown';
import moment from 'moment';
import { useGlobalStyles } from '../App/globalStyles';

const TestCall = (props) => {
  const startCall = props.location.startCall
  const videoEnabled = JSON.parse((new URL(document.location)).searchParams.get("videoEnabled") ?? null)
  const fromBookings = JSON.parse((new URL(document.location)).searchParams.get("fromBookings") ?? null)

  const [opens, setOpens] = useState([false, false, false, false, false])
  const [startingCall, setStartingCall] = useState(false)
  const portrait = useMediaQuery('(orientation: portrait)');

  const {
    activeStep,
    finishTest,
    testSuites,
    startTest,
    volume,
    checkSpeaker,
    stopLocalCamera,
    profileStatus,
    stopAll
  } = useTestAgoraHook(setOpens, videoEnabled)

  const steps = [
    {
      id: 0,
      stepLabel: 'Browser Compatibility',
      title: 'Browser Check',
      descriptionTest: `Checking ${navigator.appVersion || "Current Browser"}`,
      functionality: <LinearProgress />
    },
    {
      id: 1,
      stepLabel: 'Microphone',
      title: 'Microphone Check',
      descriptionTest: 'Say something to your microphone and see if the progress bar below changes.',
      functionality: <LinearProgress variant="determinate" value={volume} />
    },
    {
      id: 2,
      stepLabel: 'Speaker',
      description: 'Play the sample sound below. Can you hear it?',
      descriptionActions: (
        <div className='speaker_buttons'>
          <Button
            color="primary"
            variant="contained"
            onClick={() => checkSpeaker(true)}
          >
            YES
          </Button>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => checkSpeaker(false)}
          >
            NO
          </Button>
        </div>
      ),
      descriptionTest: 'Sample Sound',
      functionality: <ReactAudioPlayer src={process.env.PUBLIC_URL + "/shortring.wav"} controls />
    },
  ]

  if (videoEnabled) {
    steps.push({
      id: 3,
      stepLabel: 'Camera',
      title: 'Camera Check',
      functionality: <div>
          {profileStatus.current === 'error' && <p className='not_support_text'>The device does not support the selected resolution.</p>}
          <div id="testVideo" style={{
            width: portrait ? '170px' : '320px',
            height: '240px',
            margin: '20px auto',
            display: profileStatus.current === 'error' ? 'none' : 'block'
          }} />
          <div className='done_button_container'>
            <Button
              className='start_button'
              onClick={stopLocalCamera}
              color="primary"
              variant="contained"
            >
              DONE
            </Button>
          </div>
        </div>
    })
  }

  useEffect(() => {
    return () => {
      stopAll()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countRenderer = ({ seconds }) => {
        return <span>{seconds}</span>
  };

  useGlobalStyles()

  return (
    <div className='test_page'>
      {finishTest ?
        <div className='finish_test'>
          <List component="nav" >
            {testSuites.map((testSuite, index) => (
              <>
                <ListItem button onClick={() => {
                  setOpens(oldOpens => {
                    const newOpens = [...oldOpens]
                    newOpens[index] = !newOpens[index]
                    return newOpens
                  })
                }}
                >
                  <ListItemIcon>
                    {testSuite.notError ? <Check className='primary_color_text' /> : <Warning className='red_icon' />}
                  </ListItemIcon>
                  <ListItemText primary={steps[index].stepLabel} />
                  {opens[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={opens[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button>
                      <ListItemText>
                        <div className='message_result' dangerouslySetInnerHTML={{ __html: testSuite.message }} />
                      </ListItemText>
                    </ListItem>
                  </List>
                </Collapse>
              </>
            ))}
          </List>
          <div className='start_button_container'>
            <Button
              className='start_button'
              onClick={() => {
                if (startCall) {
                  setStartingCall(true)
                  startCall()
                } else {
                  startTest(setOpens)
                }
              }}
              color="primary"
              variant="contained"
            >
              {startCall ? startingCall ? <CircularProgress size={20} /> : fromBookings ? 'BACK TO BOOKINGS' : 'START CALL' : 'START AGAIN'}
            </Button>
            {startCall && !testSuites.find(test => test.notError === false) &&
              <h4>You're all set. Have a great session!</h4>
            }
          </div>
        </div>
      :
        <>
          {activeStep === -1 ?
          <>
            <h1>Testing below items</h1>
            <p>Browser Compatibility</p>
            <p>Microphone</p>
            <p>Speaker</p>
            {videoEnabled && <p>Camera</p>}
            {startCall ?
              <h2 className='starting_in_text primary_color_text'>Your test will start in&nbsp;
                <Countdown
                  renderer={countRenderer}
                  date={moment(new Date()).add(5, 'seconds').toDate()}
                  onComplete={() => startTest(setOpens)}
                />
              </h2>
            :
              <Button
                className='start_button'
                onClick={() => startTest(setOpens)}
                color="primary"
                variant="contained"
              >
                START
              </Button>
            }
          </>
        :
          <div>
            <p>
              Please hold on while we complete the tests below, and answer the questions when prompted.
            </p>
            <Stepper activeStep={activeStep} orientation="vertical">
                {
                  steps.map((step, index) => (
                    <Step key={step.id}>
                      <StepLabel error={!testSuites[index].notError}>{step.stepLabel}</StepLabel>
                      <StepContent>
                        <div className='title'>{step.title}</div>
                        {step.description && <div className='description'>{step.description}</div>}
                        {step.descriptionActions}
                        <div className='description_test'>{step.descriptionTest}</div>
                        {step.functionality}
                      </StepContent>
                    </Step>
                  ))
                }
            </Stepper>
            <div id='localVideo' style={{ width: '100%', height: '100%', display: 'none' }} />
            <div id='sendVideo' style={{ width: '100%', height: '100%', display: 'none' }} />
          </div>
          }
        </>
      }
    </div>
  )
};

export default TestCall;

import React, { Fragment, useEffect, useState } from 'react'
import firebase from 'firebase/app'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tooltip } from '@material-ui/core'
import { useGlobalStyles } from '../../App/globalStyles'
import { StarRate } from '@material-ui/icons'
import { typeOptions } from '../ConsultantBooking'
import moment from 'moment'

const ReviewsDetailsDialog = (props) => {
  const { open, setOpen, consultantData, numberOfReviews }= props

  const [reviewsData, setReviewsData] = useState(null)
  const [isFetching, setIsFetching] = useState(true)

  const arrayStars = Object.keys(consultantData.ratingStats).map((ratingStat) => consultantData.ratingStats[ratingStat])
  const maxStars = arrayStars.reduce((a, b) => Math.max(a, b), 0)

  useEffect(() => {
    const getSessionDataAsync = async () => {
      const reviewsDataRes = 
        (await firebase.firestore().collection('reviews').where('consultantId', '==', consultantData.userID).get()).docs
      const reviewsDataResWithClientImage = await Promise.all(reviewsDataRes.map(async reviewData => {
        try {
          const url = await firebase.app().storage().ref(`images/${reviewData.data()?.reviewerId}.jpg`).getDownloadURL()
          return {
            ...reviewData.data(),
            clientImage: url
          }
        } catch {
          return reviewData.data()
        }
      }))
      const sortedReviews = reviewsDataResWithClientImage.sort((a, b) => b.date.toDate().getTime() - a.date.toDate().getTime())
      setReviewsData(sortedReviews)
      setIsFetching(false)
    }

    getSessionDataAsync()
  }, [consultantData])

  useGlobalStyles()

  return (
    <Dialog
      className='reviews_dialog'
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle className='reviews_details_dialog_title'>
        <p>Reviews</p>
        <Divider variant='middle' />
      </DialogTitle>
      <DialogContent>
        <div className='reviews_details_dialog_content'>
          <div className='header_reviews_details'>
            <div className='general_score_details'>
              <p>General Score</p>
              <p className='general_score'>{Math.round(consultantData.generalScore * 10) / 10} <StarRate /></p>
              <p className='reviews'>{numberOfReviews} Reviews</p>
            </div>
            <Divider orientation='vertical' variant='middle' />
            <div className='score_overview'>
              <p className='score_overview_title'>Score overview</p>
              <div>
                5
                <Tooltip title={consultantData.ratingStats['fiveStars']} arrow>
                  <p
                    className='rating_graph'
                    style={{ width: `calc((100% - 25px) / ${maxStars} * ${consultantData.ratingStats['fiveStars']})` }} 
                  />
                </Tooltip>
              </div>
              <div>
                4
                <Tooltip title={consultantData.ratingStats['fourStars']} arrow>
                  <p
                    className='rating_graph'
                    style={{ width: `calc((100% - 25px) / ${maxStars} * ${consultantData.ratingStats['fourStars']})` }}
                  />
                </Tooltip>
              </div>
              <div>
                3
                <Tooltip title={consultantData.ratingStats['threeStars']} arrow>
                  <p
                    className='rating_graph'
                    style={{ width: `calc((100% - 25px) / ${maxStars} * ${consultantData.ratingStats['threeStars']})` }}
                  />
                </Tooltip>
              </div>
              <div>
                2
                <Tooltip title={consultantData.ratingStats['twoStars']} arrow>
                  <p
                    className='rating_graph'
                    style={{ width: `calc((100% - 25px) / ${maxStars} * ${consultantData.ratingStats['twoStars']})` }}
                  />
                </Tooltip>
              </div>
              <div>
                1
                <Tooltip title={consultantData.ratingStats['oneStar']} arrow>
                  <p
                    className='rating_graph'
                    style={{ width: `calc((100% - 25px) / ${maxStars} * ${consultantData.ratingStats['oneStar']})` }}
                  />
                </Tooltip>
              </div>
            </div>
          </div>
          <Divider variant='middle' />
          <div className='reviews'>
            {isFetching ?
              <CircularProgress size={30} />
            :
              <>
                {reviewsData.map((review, index) => (
                  <Fragment key={index}>
                    <div className='review_item'>
                      <img alt="" src={review.clientImage} />
                      <div>
                        <p className='type_date'>
                          {typeOptions(false, true).find(elem => elem.id === review.callType).label} | 
                          {moment(review.date.toDate()).format('MMM DD')}
                        </p>
                        <p className='client_name'>{review.reviewerName}</p>
                        {review.content && <p className='review_message'>{review.content}</p>}
                      </div>
                      <p className='general_score'>{review.rating} <StarRate /></p>
                    </div>
                    <Divider variant='fullWidth' />
                  </Fragment>
                ))}
              </>
            }
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReviewsDetailsDialog;

import React, {  } from 'react'
import 'firebase/functions'
import { Button } from '@material-ui/core'
import { useBookingContext } from '../BookingContext';
import moment from 'moment';
import { useState } from 'react';
import EditBirthInfosDialog from '../../../AccountDetailsClient/EditBirthInfosDialog';
import Confirmation from '../../../components/Confirmation';
import { useDispatch } from 'react-redux';
import { executeLogout } from '../../../../api/userSlice';

const UserInformation = () => {
  const { consultantData, contactInfoData, birthInfosData, setBirthInfosData } = useBookingContext()
  const dispatch = useDispatch()
  
  const [birthInfosDialog, setBirthInfosDialog] = useState(false)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  return (
    <div className='item'>
      <div className='margin_item'>
        <div className='header_info'>
          <p>YOUR INFORMATION</p>
          <Button
            className='dismiss_buttton have_an_account_button'
            onClick={() => setShowLogoutConfirmation(true)}
            color='primary'
            variant="outlined"
          >
            LOGOUT
          </Button>
        </div>

        <div className='border_left user_information'>
          {contactInfoData.firstName} {contactInfoData.lastName}<br />
          {contactInfoData.email}
          {consultantData.needBirthForm &&
            <>
              {birthInfosData?.city ?
                <>
                  <br /><br />
                  {moment(birthInfosData.birthDate).format('MMMM D, YYYY')}
                  <Button
                    className='dismiss_buttton'
                    onClick={() => setBirthInfosDialog(true)}
                    color="primary"
                    variant="outlined"
                  >
                    <i>edit</i>
                  </Button>
                  <br />
                  {moment(birthInfosData.birthDate).format('@ h:mma')}<br />
                  {birthInfosData.city}, {birthInfosData.state ? `${birthInfosData.state}, ` : ''}{birthInfosData.country}
                </>
              :
                <>
                  <br /><br />
                  Please add your birth information here <i>(required)</i>
                  <Button
                    className='dismiss_buttton'
                    onClick={() => setBirthInfosDialog(true)}
                    color="primary"
                    variant="outlined"
                  >
                    <i>add</i>
                  </Button>
                </>
              }
            </>
          }
        </div>
      </div>
        
        {birthInfosDialog && 
          <EditBirthInfosDialog
            handleClose={() => setBirthInfosDialog(false)}
            open={birthInfosDialog}
            birthInfosState={birthInfosData}
            setBirthInfoState={setBirthInfosData}
          />
        }

        {showLogoutConfirmation &&
          <Confirmation
            content="Are you sure you want to logout ?"
            onClose={setShowLogoutConfirmation}
            onAction={async () => {
              setShowLogoutConfirmation(false)
              await dispatch(executeLogout()).unwrap()
            }}
          />
        }
    </div>
  )
}

export default UserInformation;

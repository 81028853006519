import React, { useEffect } from "react";
import useCookieTheme from "./useCookieTheme";
import { useRef } from "react";
import { Button } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import firebase from "firebase/app";
import { isAndroid } from "react-device-detect";
import { useSelector } from "react-redux";
import { themeState } from "../../api/themeSlice";
import { userState } from "../../api/userSlice";

const Banner = (props) => {
  const { setBannerOn } = props;
  const themeID = useSelector(themeState).theme?.id;
  const consultantID = useSelector(userState).consultantID;
  const { name, universalLink } = useCookieTheme();

  const shouldOpenApp = useRef(true);

  useEffect(() => {
    window.addEventListener("focus", () => {
      shouldOpenApp.current = true;
    });
    window.addEventListener("blur", () => {
      shouldOpenApp.current = false;
    });
  }, []);

  const handleSwitchToApp = async () => {
    const universalLinkConsultant = await firebase
      .functions()
      .httpsCallable("getUniversalLink")({
      themeID: themeID,
      consultantID: consultantID,
    });
    console.log("Got result: ", universalLinkConsultant, themeID, consultantID);
    return;
    if (isAndroid) {
      if (consultantID) {
        const universalLinkConsultant = await firebase
          .functions()
          .httpsCallable("getUniversalLink")({
          themeID: themeID,
          consultantID: consultantID,
        });
        window.open(universalLinkConsultant.data);
      } else {
        window.open(universalLink);
      }
    } else {
      window.open(
        consultantID
          ? `${universalLink}?consultantID=${consultantID}`
          : universalLink
      );
    }
  };

  return (
    <div className="banner">
      <p>Get more out of {name} with our app</p>
      <div className="actions_banner">
        <Button
          size="small"
          color="primary"
          onClick={handleSwitchToApp}
          variant="contained"
        >
          Switch to app
        </Button>
        <ClearIcon
          onClick={() => {
            setBannerOn(false);
          }}
        />
      </div>
    </div>
  );
};

export default Banner;

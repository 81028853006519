import React from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextValidator } from 'react-material-ui-form-validator';

const AddPaypal = (props) => {
  const { email, setEmail } = props;

  return (
    <ValidatorForm
      onError={errors => console.log(errors)}
      onSubmit={() => { return }}
    >
      <TextValidator
        validators={['required', 'isEmail']}
        errorMessages={['This field is required', '']}
        autoFocus
        margin="dense"
        label="Email"
        type="text"
        fullWidth
        value={email}
        onChange={(e) => { setEmail(e.target.value) }}
        required
      />
    </ValidatorForm>
  );
}

export default AddPaypal
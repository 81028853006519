import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const TeamAvailableRow = (props) => {
    const { data, isLastOne } = props

    return (
      <>
        {data.empty ? 
          <TableRow key={data.id}>
              <TableCell align='left' colSpan={7}>.</TableCell>
              <TableCell align='right' colSpan={8}>.</TableCell>
          </TableRow>
        :
          <TableRow key={data.id} style={{ backgroundColor: isLastOne ? 'yellow' : 'white' }}>
              <TableCell align='right' className='heading' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', borderRight: '1px solid lightgray' }}>{data.name}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalToday}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedToday}{isLastOne && '%'}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalTomorrow}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedTomorrow}{isLastOne && '%'}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalThisWeek}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedThisWeek}{isLastOne && '%'}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalNextWeek}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedNextWeek}{isLastOne && '%'}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalThisMonth}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedThisMonth}{isLastOne && '%'}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalNextMonth}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedNextMonth}{isLastOne && '%'}</TableCell>
              <TableCell align='center' style={{ fontWeight: data.nameBold ? 'bold' : 'normal', display: isLastOne ? 'none' : 'table-cell' }}>{data.totalYear}</TableCell>
              <TableCell align='center' colSpan={isLastOne ? 2 : 1} style={{ fontWeight: data.nameBold ? 'bold' : 'normal', backgroundColor: isLastOne ? 'yellow' : 'lightgray', borderRight: isLastOne ? '1px solid lightgray' : 'none' }}>{data.bookedYear}{isLastOne && '%'}</TableCell>
          </TableRow>
        }
      </>
    )
}

export default TeamAvailableRow
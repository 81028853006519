import React, { useEffect } from 'react'
import 'firebase/functions'
import { Button, Grid, useMediaQuery } from '@material-ui/core';
import { useBookingContext } from '../BookingContext';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import useCookieTheme from '../../../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, userState } from '../../../../api/userSlice';

const CreateAccountOptional = () => {
  const testIntegration = useSelector(userState).testIntegration
  const dispatch = useDispatch()
  const { consultantData, createAccountData, setCreateAccountData, openForm, setOpenForm } = useBookingContext()
  const { consultantTitle, appointmentName } = useCookieTheme()

  useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== createAccountData.password) {
        return false;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch')
    }
  }, [createAccountData])

  const widthLessThan600px = useMediaQuery('(max-width:599px)');

  const onClickSignin = () => {
    if (testIntegration) {
      dispatch(setErrorDetails({
        message: 'Cannot sign in preview widget because you\'re already logged as consultant!',
        severity: 'warning',
        verticalAnchor: 'bottom'
      }))
    } else {
      setOpenForm(true)
    }
  }

  return (
    <div className='item form_information form_create_account'>
      <div className='margin_item'>
        <Button
          className='dismiss_buttton have_an_account_button'
          disabled={openForm}
          onClick={onClickSignin}
          color={openForm ? 'default' : 'primary'}
          variant="outlined"
        >
          CREATE AN ACCOUNT (FOR EASY ACCESS & FUTURE BOOKINGS)<br/>
        </Button>
        <br />
        <div className='optional_italic'>
          <i className='heading'>optional</i>
        </div>
      
        {openForm &&
          <div className='form_passwords'>
            <ValidatorForm
              className={"form_items"}
              onError={errors => console.log(errors)}
              onSubmit={() => { return }}
            >
              <Grid className='details_client_form' container spacing={2}>
                <Grid item xs={widthLessThan600px ? 12 : 6}>
                  <TextValidator
                    validators={['required']}
                    variant="outlined"
                    size={'small'}
                    errorMessages={['This field is required']}
                    onChange={(e) => {
                      const { value } = e.target
                      setCreateAccountData(oldCreateAccountData => ({
                        ...oldCreateAccountData,
                        password: value
                      }))
                    }}
                    value={createAccountData?.password}
                    type='password'
                    label="SET PASSWORD" fullWidth required
                  />
                </Grid>
                <Grid item xs={widthLessThan600px ? 12 : 6}>
                  <TextValidator
                    validators={['required', 'isPasswordMatch']}
                    variant="outlined"
                    size={'small'}
                    errorMessages={['This field is required', 'Password mismatch']}
                    onChange={(e) => {
                      const { value } = e.target
                      setCreateAccountData(oldCreateAccountData => ({
                        ...oldCreateAccountData,
                        confirmPassword: value
                      }))
                    }}
                    value={createAccountData?.confirmPassword} 
                    type='password'
                    label="CONFIRM PASSWORD" fullWidth required
                  />
                </Grid>
              </Grid>
            </ValidatorForm>
            
            <div className='astrologer_text heading'>
              By creating a secure account:<br />
              <ul>
                <li>We'll safely store your {consultantData.needBirthForm && 'birth & '}payment information so you don't have to enter it again;</li>
                <li>You'll be able to bookmark your favorite {consultantTitle}s for future {appointmentName ?? 'appointment'}s;</li>
                <li>And you'll be able to access the downloadable recordings of your {appointmentName ?? 'appointment'}s in one convenient place!</li>
              </ul>
            </div>
    
            <Button
              className='dismiss_buttton'
              onClick={() => setOpenForm(false)}
              color="primary"
              variant="outlined"
            >
              Dismiss
            </Button>
          </div>
        }
      </div>
    </div>
  )
}

export default CreateAccountOptional;

import React, { useEffect, useState } from 'react';
import 'firebase/firestore'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab'
import { agoraObject } from '../config'
import firebase from 'firebase/app'
import { updateSessionLog } from '../../../utils/utils';

const CallTicker = (props) => {
  const {
    isConsultant,
    isChatCall,
    otherHandle,
    endsIn5Mins,
    onClose,
    firstPingDate,
    sessionStarted,
    sessionData,
    isEndingCall,
    bookingID,
    setEndsIn5Mins,
    wasClosed,
    sessionID,
    paymentFailed,
    videoEnabled,
    agoraClientRef,
    setSlowConnection,
    stopCallSync,
    startEndSession,
    setSessionPrice
  } = props
  
  const [counter, setCounter] = useState(-1);
  const [lengthSession, setLengthSession] = useState(null)
  const [localVideoShowed, setLocalVideoShowed] = useState(false)
  const [remoteAudioShowed, setRemoteAudioShowed] = useState(false)
  const [remoteVideoShowed, setRemoteVideoShowed] = useState(false)

  useEffect(() => {
    if (!firstPingDate) {
      return
    }
    const timer = setInterval(() => {
      const newCounter = moment().diff(firstPingDate, 'seconds')
      setCounter(newCounter)
    }, 1000);
    return () => clearInterval(timer);
  }, [firstPingDate]);

  useEffect(() => {
    if (counter > 0) {
      if (agoraObject.localTracks.videoTrack && !localVideoShowed) {
        agoraObject.localTracks.videoTrack.play('localVideo')
        setLocalVideoShowed(true)
      }
      if (agoraObject.remoteUser) {
        if (agoraObject.remoteUser.audioTrack && !remoteAudioShowed) {
          agoraObject.remoteUser.audioTrack.play();
          setRemoteAudioShowed(true)
        }
        if (agoraObject.remoteUser.videoTrack && !remoteVideoShowed) {
          agoraObject.remoteUser.videoTrack.play('remoteVideo', { fit: "contain" });
          setRemoteVideoShowed(true)
        }
      }
      if (!sessionStarted.current) {
        sessionStarted.current = true
      }
    }
  }, [counter, remoteAudioShowed, localVideoShowed, remoteVideoShowed, sessionStarted])

  useEffect(() => {
    if (sessionData && !isEndingCall) {
      const chargeAmount = sessionData.salesTax && !isConsultant ? sessionData.price + sessionData.salesTax : sessionData.price
      
      if (Boolean(bookingID)) {
        if (!lengthSession) {
          firebase.app().firestore().doc(`/bookings/${sessionData.bookingID}`).get().then(booking => {
            const bookingData = booking.data()
            setLengthSession(bookingData.length)
          })
        }
        if (lengthSession && lengthSession * 60 - 300 < counter && !wasClosed && !endsIn5Mins) {
          setEndsIn5Mins(true)
        }
        return
      } else {
        if (isConsultant) {
          if (counter > 0 && (counter + 10) % 60 === 0) {
            const params = {
              session: sessionData.callUUID
            }
            firebase.app().functions().httpsCallable("isSessionPaid")(params).catch((err) => {
              endCallFromGetPayment('Error on isSessionPaid')
              updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - isSessionPaid return an error - ${err}`)
            }).then(res => {
              if (res?.data.status !== "succeeded") {
                endCallFromGetPayment('Error on isSessionPaid')
                updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - isSessionPaid return an error`)
              } else {
                updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - success with isSessionPaid!`)
              }
            })
          }
        } else {
          if ((counter - 10) % 60 === 0 && !paymentFailed) {
            const params = {
              session: sessionData.callUUID
            }
            firebase.app().functions().httpsCallable("getPaymentIntentNew")(params).catch((err) => {
              endCallFromGetPayment('Error on getPaymentIntentNew')
              updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - getPaymentIntentNew return an error - ${err}`)
            }).then(res => {
              if (res?.data.status !== "succeeded") {
                endCallFromGetPayment('Error on getPaymentIntentNew')
                updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - getPaymentIntentNew return an error`)
              } else {
                updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - success with getPaymentIntent!`)
              }
            })
          }
        }
      }
  
      if (counter > 0) {
        const gratuityPeriod = sessionData.gratuityPeriod
        const passedMinutes = Math.floor(counter / 60) - gratuityPeriod
    
        let totalCharged = (passedMinutes + 1) * chargeAmount
        if (passedMinutes < 0) {
          totalCharged = 0
        }
        if (gratuityPeriod && counter < gratuityPeriod * 60) {
          totalCharged = 0
        }
  
        const newAmount = isConsultant ? 
            totalCharged * (sessionData.customConsultantPercentage ?? 1) * (sessionData.teamMemberPercentage ?? 1) : 
            totalCharged
        updateChargeAmount(newAmount)
        if (counter % 60 === 0) {
          updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - update amount for ${isConsultant ? 'consultant' : 'client'} after ${passedMinutes + 1} mins - ${newAmount}`)
        }
      }

      if (counter % 5 === 0) {
        if (videoEnabled) {
          const videoStats = agoraClientRef.getLocalVideoStats()
          const kbVideoBitrate = videoStats.sendBitrate / 1024
          if (kbVideoBitrate < 24) {
            updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - slow connection for video bitrate - ${kbVideoBitrate} kbps`)
            setSlowConnection(true)
          } else {
            setSlowConnection(false)
          }
        } else if (!isChatCall) {
          const audioStats = agoraClientRef.getLocalAudioStats()
          const kbAudioBitrate = audioStats.sendBitrate / 1024
          if (kbAudioBitrate < 4) {
            updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - slow connection for audio bitrate - ${kbAudioBitrate} kbps`)
            setSlowConnection(true)
          } else {
            setSlowConnection(false)
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, isConsultant, bookingID, sessionData])

  const endCallFromGetPayment = async (message) => {
    stopCallSync()
    paymentFailed.current = true
    startEndSession('finished', "error", "", message ?? "Payment failed or slow connection to internet")
  }

  const updateChargeAmount = (amount) => {
    setSessionPrice(amount)
  }

  return (
    <>
    {counter > 0 ?
      <Typography>
        {moment(counter * 1000).format('mm:ss')}
      </Typography>
      :
      <>
        {isConsultant ?
          <div className='calling'>Connecting...</div> :
          <div className='calling'>{isChatCall ? `Initiating text chat with ${otherHandle}` : 'Calling...'}</div>
        }
      </>
    }

    {endsIn5Mins &&
      <Snackbar
        open={endsIn5Mins}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className='timeEndsBooking'
      >
        <Alert onClose={onClose} severity={`error`}>
          The official booking time ends in less than 5 minutes!
        </Alert>
      </Snackbar>
    }
    </>
  )
};

export default CallTicker;

import React, { } from "react";
import { useGlobalStyles } from "../../App/globalStyles";
import "./sidebarItem.css";

export default function SidebarItem(props) {
    useGlobalStyles()
    
    return (
        <div
            className={`sidebarItem ${props.active ? "active" : ""} ${props.disabled ? "disabled" : ""}`}
            onClick={() => props.onSelect(props.id)}
        >
            <div
                className="sidebarItem_line"
            />
            <span className="sidebarItem_icon">{props.icon}</span>
            {!props.isMini &&
                <div
                    className="sidebarItem_label heading"
                    dangerouslySetInnerHTML={{ __html: props.label }}
                ></div>}
        </div>
    )
};
import React from 'react'
import 'firebase/functions'
import { useBookingContext } from '../BookingContext';
import ContinueButton from './ContinueButton';
import { toIntMonth, validTime } from './BookingSelectionHelper';
import moment from 'moment';
import firebase from 'firebase/app'
import { config, defaultTheme } from '../../../../config/config';
import { isValidEmail } from '../../../../utils/utils';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { FirebaseContext } from '../../../Firebase';
import momentTimezone from 'moment-timezone'
import { useDispatch, useSelector } from 'react-redux';
import { userState, setIsSignUpInBookingProcess, setErrorDetails } from '../../../../api/userSlice';
import { themeState } from '../../../../api/themeSlice';
import useBookingHook from '../../useBookingHook';

const vapidKey = config.vapidKey;

const ConfirmBookingButton = (props) => {
  const { rescheduleBookingID, isGift } = props
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const integration = useSelector(userState).integration
  const themeID = useSelector(themeState).theme?.id
  const realThemeID = useSelector(themeState).realThemeID
  const {
    consultantData,
    setBookingID,
    setGiftID,
    bookingData,
    contactInfoData,
    birthInfosData,
    paymentInfoData,
    couponData,
    discountCouponData,
    giftData,
    setIsDetailsForm,
    setIsFinishBooking,
    setIsCongrats,
    setIsAlmostDone,
    setIsAllSet,
    createAccountData,
    setIsCreatedAccount,
    openForm,
    isTenPercentDiscount
  } = useBookingContext()
  const firebaseSignIn = useContext(FirebaseContext)
  const { removeBooking } = useBookingHook()
  const dispatch = useDispatch()
  const bookingDataConfirm = bookingData?.data
  
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (userID && isGift) {
      const disable = !userID || !isGift || !giftData || !giftData.name || !isValidEmail(giftData.email) || 
                      !isValidEmail(giftData.confirmEmail) || giftData.email !== giftData.confirmEmail || !giftData.deliveryDate
      setDisabled(disable)
    } else if (userID) {
      let disable = !paymentInfoData?.stripeDefaultPMHint
      if (consultantData.needBirthForm) {
        disable = disable || !birthInfosData || !birthInfosData.city || !birthInfosData.country
      }
      setDisabled(disable)
    } else {
      setDisabled(false)
    }
  }, [birthInfosData, consultantData, giftData, isGift, paymentInfoData, userID])

  const confirm = async () => {
    setLoading(true)
    if (openForm) {
      await signInTapped()
    }
    if (isGift) {
      confirmGift()
    } else {
      await confirmBooking()
    }
  }

  const signInTapped = async () => {
    const toSend = {
      email: contactInfoData.email,
      password: createAccountData.password,
      consultant: false,
      name: contactInfoData.firstName,
      surname: contactInfoData.lastName,
      locale: 'us',
      consultantCodes: [],
      stripeVersion: "2020-08-27",
      fromConsultantID: consultantData.userID,
      fromConsultantFullName: `${consultantData["name"]} ${consultantData["surname"]}`
    }
    if (integration) {
      toSend.integrationThemeID = themeID
      toSend.themeID = realThemeID
    } else {
      if (themeID) {
        toSend.themeID = themeID
      } else {
        toSend.themeID = defaultTheme.id
      }
    }
    
    console.log(toSend);
    try {
      dispatch(setIsSignUpInBookingProcess(true))
      if (Boolean(integration)) {
        localStorage.setItem('loggedInWidget', true)
        await firebase.auth().signOut()
      }
      
      await firebase.functions().httpsCallable("createUserFlow")(toSend)
      await firebaseSignIn.signIn(contactInfoData.email, createAccountData.password)

      if (!isGift) {
        const userRef = firebase.firestore().doc('/users/' + userID)
        const userData = (await userRef.get()).data()
        if (userData && consultantData.needBirthForm) {
          await userRef.update({
            birthInfos: {
              birthDate: new Date(birthInfosData.birthDate),
              city: birthInfosData.city,
              state: birthInfosData.state ?? null,
              country: birthInfosData.country,
            },
          })
        }
      }

      setIsCreatedAccount(true)
    } catch (e) {
      console.log(e);
      dispatch(setErrorDetails({
        message: "This email address is already in use!"
      }))
      setLoading(false)
    }
  }

  const confirmBooking = async () => {
    if (!bookingDataConfirm) {
      setLoading(false)
      return
    }

    const currentMonth = toIntMonth(moment(bookingDataConfirm.startDate))
    const consultantID = bookingDataConfirm.consultantID

    let prettyMonth = `${currentMonth % 100}`
    if (prettyMonth.length === 1) {
      prettyMonth = `0${prettyMonth}`
    }
    const stringMonth = Math.floor(currentMonth / 100) + "-" + prettyMonth
    const momentMonth = moment(`${stringMonth}-01 10:00:00`)
    const startDate = momentMonth.startOf('month').toDate()
    const endDate = momentMonth.endOf('month').toDate()

    const payload = {
      startDay: startDate.toISOString(),
      endDay: endDate.toISOString(),
      consultantID: consultantID,
    }

    let currentToken
    if (!userID) {
      try {
        currentToken = await firebase.messaging().getToken({ vapidKey: vapidKey })
      } catch {}
    }

    firebase.functions().httpsCallable('getConsultantSlots')(payload).then(resp => {
      const data = resp.data
      if (data) {
        const startDate = momentTimezone(bookingDataConfirm.startDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).toDate()
        const selectedLength = bookingDataConfirm.length

        var mappedData = {}
        data.forEach(each => {
          mappedData[each.startDate] = each.maxLength
        })
        if (validTime(mappedData, moment(startDate), selectedLength)) {
          const bookingPayload = {
            clientID: userID,
            consultantID: bookingDataConfirm.consultantID,
            length: bookingDataConfirm.length,
            notes: bookingDataConfirm.notes,
            startDateSeconds: Math.trunc(new Date(startDate.setMilliseconds(0)).getTime() / 1000),
            callType: bookingDataConfirm.callType,
            webCreated: true,
            clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            isFree: bookingDataConfirm.estimatedPrice < 0,
            isTenPercentDiscount: isTenPercentDiscount,
            giftID: bookingDataConfirm.giftID,
          }
          if (!userID) {
            bookingPayload.clientEmail = contactInfoData.email
            bookingPayload.clientSurname = contactInfoData.lastName
            bookingPayload.clientName = contactInfoData.firstName
          }
          if (couponData) {
            bookingPayload.giftID = couponData.id
          }
          if (discountCouponData) {
            bookingPayload.discountCouponID = discountCouponData.id
          }
          if (consultantData.needBirthForm) {
            bookingPayload.clientDetails = {
              birthDate: new Date(birthInfosData.birthDate),
              address: `${birthInfosData.city}, ${birthInfosData.state ? `${birthInfosData.state}, ` : ''}${birthInfosData.country}`
            }
          }
          if (currentToken) {
            bookingPayload.clientVapidKey = currentToken
          }

          const successfullyFinished = (bookingID) => {
            if (consultantData.needBirthForm && dbUser && !dbUser.birthInfos) {
              firebase.app().firestore().doc(`/users/${userID}`).update({
                birthInfos: {
                  birthDate: new Date(bookingDataConfirm.clientDetails.birthDate),
                  city: bookingDataConfirm.clientDetails.city,
                  country: bookingDataConfirm.clientDetails.country,
                  state: bookingDataConfirm.clientDetails.state,
                }
              });
            }
            removeBooking()
            setBookingID(bookingID)
            if (userID) {
              if (consultantData.needBirthForm) {
                setIsAlmostDone(true)
              } else {
                setIsAllSet(true)
              }
              setIsDetailsForm(false)
            } else {
              if (consultantData.needBirthForm) {
                setIsCongrats(true)
              } else {
                setIsAllSet(true)
              }
              setIsFinishBooking(false)
            }
          }

          if (rescheduleBookingID) {
            firebase.functions().httpsCallable("cancelBooking")({
              bookingID: rescheduleBookingID,
              withoutRefund: true,
              isConsultant: false,
              withoutEmail: true
            }).then(_ => {
              firebase.functions().httpsCallable('addBooking')({
                  ...bookingPayload,
                  rescheduleBookingID: rescheduleBookingID
                }).then(resp => {
                  successfullyFinished(resp.data.bookingID)
              }).finally(() => {
                setLoading(false)
              })
            }).catch((e) => {
              setLoading(false)
              dispatch(setErrorDetails({
                message: 'Cannot cancel old booking, please try again later.'
              }))
            })
          } else {
            firebase.functions().httpsCallable('addBooking')(bookingPayload).then(resp => {
              successfullyFinished(resp.data.bookingID)
            }).finally(_ => {
              setLoading(false)
            })
          }
        } else {
          setLoading(false)
          dispatch(setErrorDetails({
            message: 'The time slot you selected has been occupied by someone else in the meantime. Please go back and select an available time slot'
          }))
        }
      } else {
        setLoading(false)
        dispatch(setErrorDetails({
          message: 'The time slot you selected has been occupied by someone else in the meantime. Please go back and select an available time slot'
        }))
      }
    }).catch((e) => {
      console.log('Error', e)
      setLoading(false)
      dispatch(setErrorDetails({
        message: 'The time slot you selected has been occupied by someone else in the meantime. Please go back and select an available time slot'
      }))
    })
  }

  const confirmGift = () => {
    if (!giftData) {
      setLoading(false)
      return
    }

    const deliveryDate = new Date(giftData.deliveryDate)
    deliveryDate.setMilliseconds(0)
    firebase.functions().httpsCallable('createGift')({
      giftData: {
        ...giftData,
        deliveryDate: Math.trunc(deliveryDate.getTime() / 1000),
        consultantID: bookingDataConfirm.consultantID,
        clientID: userID,
        consultantFullName: bookingDataConfirm.consultantName,
        consultantName: bookingDataConfirm.consultantFirstName,
        clientFullName: `${contactInfoData.firstName} ${contactInfoData.lastName}`,
        clientName: contactInfoData.firstName,
        callType: bookingDataConfirm.callType,
        length: bookingDataConfirm.length,
        locale: bookingDataConfirm.locale,
        themeID: themeID ?? defaultTheme.id,
        clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        clientEmail: contactInfoData.email,
        discountCouponID: discountCouponData?.id ?? null,
        isFree: bookingDataConfirm.estimatedPrice < 0,
        isTenPercentDiscount: isTenPercentDiscount,
      }
    }).then(resp => {
      setGiftID(resp.data.giftID)
      removeBooking()
      setIsAllSet(true)
      if (userID) {
        setIsDetailsForm(false)
      } else {
        setIsFinishBooking(false)
      }
    }).catch(e => {
      if (e.details?.error === 'payment_failed') {
        dispatch(setErrorDetails({
          message: 'Payment failed! Please check your card and try again!'
        }))
      }
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <ContinueButton
      disabled={loading || disabled}
      loading={loading}
      onClick={confirm}
      text='CONFIRM'
    />
  )
}

export default ConfirmBookingButton;

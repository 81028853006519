import './styles.css';
import React, { useEffect, useState } from 'react';
import { acceptURL } from "../../utils/utils";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'

const TeamAcceptInvite = (props) => {
    const [isFetchingData, setIsFetching] = useState(false)
    const [requestSent, setRequestSent] = useState(false)
    const [message, setMessage] = useState("Please wait until the invite is processed...")

    const processInvite = () => {
        if (requestSent) {
            return
        }
        setRequestSent(true)
        setIsFetching(true)
        const inviteId = props.match.params["invite_id"]

        // Must be public http function not callable.
        fetch(`${acceptURL()}acceptTeamInvitation?invitation_id=${inviteId}`)
            .then(function (response) {
                if (response.status !== 200) {
                    return response.json()
                } else {
                    // handle success
                    setMessage("Invite processed. You are now part of the team.")
                }
            })
            .catch(function (error) {
                // handle error
                if(error.response?.data?.message) {
                    setMessage("Error processing invite. Error: " + error.response.data.message)
                } else {
                    setMessage("Error processing invite. Error: " + error.message)
                }
            })
            .then(body => {
                setMessage("Error processing invite. Error: " + body.message)
            })
            .finally(function () {
                // always executed
                setIsFetching(false)
            });

    }

    useEffect(processInvite, [requestSent])

    const useStyles = makeStyles({
        message: {
            paddingLeft: 25,
            fontSize: 17,
            color: "#505050"
        }
    });
    const tableClasses = useStyles();

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0 50px', textAlign: 'center' }}>
            <Typography variant="h6" display="inline" gutterBottom className={tableClasses.message}>
                {message}
            </Typography>

            {isFetchingData && 
                <>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 200 }}>
                        <CircularProgress size={50} /> 
                    </div>
                </>
            }
        </div>
    )
};

export default TeamAcceptInvite;
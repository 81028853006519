export const marks = [
  {
      value: 0,
      label: '00:00',
  },
  {
      value: 240,
      label: '04:00',
  },
  {
      value: 480,
      label: '08:00',
  },
  {
      value: 720,
      label: '12:00',
  },
  {
      value: 960,
      label: '16:00',
  },
  {
      value: 1200,
      label: '20:00',
  },
  {
      value: 1440,
      label: '24:00',
  },
];

export const miniMarks = [
  {
      value: 0,
      label: '00:00',
  },
  {
      value: 480,
      label: '08:00',
  },
  {
      value: 960,
      label: '16:00',
  },
  {
      value: 1440,
      label: '24:00',
  },
];

export const timeConvert = (num) => {
  const hours = Math.floor(num / 60);
  const minutes = num % 60;
  let toRet = hours + ":" + minutes;
  if (minutes < 10) {
      toRet += '0'
  }
  return toRet
}
import React, { useState } from 'react';
import './styles.css';
import { Box, Button, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import ConfirmAlert from '../../../components/ConfirmAlert'
import firebase from 'firebase';
import { capitalizeFirstLetter } from '../../../../utils/utils';
import useCookieTheme from '../../../App/useCookieTheme';

const SetPercentagePopup = (props) => {
    const consultantID = props.data.consultantID
    const functions = firebase.functions()
    const { employeeTitle } = useCookieTheme()

    const [open, setOpen] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false)
    const [alertInfo, setAlertInfo] = useState({ open: false })
    const [percentage, setPercentage] = useState(Math.round(props.data.memberCut * 100));
    
    const widthLessThan650px = useMediaQuery('(max-width:649px)');
    const widthLessThan450px = useMediaQuery('(max-width:449px)');

    const handleClickOpen = () => {
        setOpen(true)
    };

    const handleSave = () => {
        setIsUpdating(true)

        functions.httpsCallable('setTeamMemberPercentage')({ consultantID: consultantID, percentage: percentage / 100.0 })
          .then(function (result) {
            console.log("Percentage updated!")
            props.percentageUpdated(percentage)
          })
          .catch(error => {
            setAlertInfo({ open: true, title: "Error", description: error.message, onConfirm: hideAlert })
          })
          .finally(() => {
            setOpen(false);
            setIsUpdating(false)
          });
    };

    const handleClose = () => {
        setOpen(false)
    };

    const hideAlert = () => {
        setAlertInfo(oldAlertInfo => ({
          ...oldAlertInfo,
          open: false
        }))
    }

    const useStyles = makeStyles({
        circularProgress: {
            margin: 'auto',
            display: 'block'
        },
        percentageButton: {
            width: widthLessThan450px ? 82 : 110,
            height: widthLessThan450px ? 17 : widthLessThan650px ? 20 : 25,
            fontSize: widthLessThan450px ? 7 : 11,
            textTransform: 'none',
            marginRight: 10,
            color: "#FFFFFF",
            "&:hover": {
                backgroundColor: "#CC6600"
            }
        },
        employeeCutLabel: {
            marginTop: 11,
            marginRight: 8
        },
        percentSymbol: {
            marginTop: 8
        }
    });
    const styleClasses = useStyles();

    return (
        <div>
            {alertInfo.open && <ConfirmAlert data={alertInfo} />}
            <Button variant="contained" className={`${styleClasses.percentageButton} percentageButton`} onClick={handleClickOpen}>
                {capitalizeFirstLetter(employeeTitle)} cut
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <DialogContentText>
                        <b>{capitalizeFirstLetter(employeeTitle)} percentage cut</b> <br /><br /> This percentage will be used to compute {employeeTitle} cut from total income.
                        Changing this value will update {employeeTitle} cut only for the new sessions income. Old sessions incomes will be computed with the old value. <br />
                        If you don't set a value for this field, the default value is 100%.
                    </DialogContentText>
                    <Box display="flex">
                        <Typography className={styleClasses.employeeCutLabel}>{capitalizeFirstLetter(employeeTitle)} cut:</Typography>
                        <TextField
                            disabled={isUpdating}
                            autoFocus
                            margin="dense"
                            id="percentage"
                            type="number"
                            value={percentage}
                            onChange={(e) => { 
                                let value = parseInt(e.target.value)
                                if (value < 0) {
                                    value = 0
                                }
                                if (value > 100) {
                                    value = 100
                                }
                                setPercentage(value) 
                            }}
                        />
                        <Typography className={styleClasses.percentSymbol}>%</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button disabled={isUpdating} onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default SetPercentagePopup
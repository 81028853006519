import React, {  } from 'react'
import { IconButton, Popover } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const InfoPopover = (props) => {
  const { handleInfo, open, anchorEl, setAnchorEl, children, buttonClass } =props

  return (
    <>
      <IconButton className={`icon_info_button ${buttonClass ?? ''}`} onClick={handleInfo}>
        <InfoIcon />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
        }}
        style={{
          top: '-15px'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {children}
      </Popover>
    </>
  )
}

export default InfoPopover;
import React, { useState } from 'react';
import 'firebase/firestore'
import firebase from 'firebase/app'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, useMediaQuery } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import ConfirmAlert from '../components/ConfirmAlert'
import useCookieTheme from '../App/useCookieTheme';
import { AUTH_ROUTES_CONSULTANT } from '../../utils/routes';
import { useDispatch, useSelector } from 'react-redux';
import { userState, checkTeamMembership, setIsTeamPage, setErrorDetails } from '../../api/userSlice';
import { themeState } from '../../api/themeSlice';

const ManageTeam = (props) => {
    const { showWithdraw, setWithdrawDialog } = props
    const functions = firebase.app().functions()
    const dispatch = useDispatch()
    const isTeamMaster = useSelector(userState).isTeamMaster
    const isTeamMember = useSelector(userState).isTeamMember
    const isConsultant = useSelector(userState).user?.consultant
    const themeID = useSelector(themeState).theme?.id
    const dbUserID = useSelector(userState).user?.userID
    const { consultantTitle, changePage } = useCookieTheme()

    const [alertInfo, setAlertInfo] = useState({ open: false })
    const [isCreatingTeam, setIsCreatingTeam] = useState(false)
    
    const widthLessThan850px = useMediaQuery('(max-width:850px)');

    const handleReadMore = () => {
        setAlertInfo({
            open: true,
            title: 'How team system works',
            description: `By creating a team, you can build and manage a team of ${consultantTitle}s. \n\n` +
                'After creating a team you will be the team manager. ' +
                `As a manager, you can invite new ${consultantTitle}s to join your team, remove ${consultantTitle}s from team, ` +
                'withdraw team balance as the cumulated wallet balance of each team member.\n\n' +
                'Each team member will have the `withdraw feature` disabled, and their wallet balance can be withdrawn only by the team manager.',
            onConfirm: hideAlert, okTitle: "Close"
        })
    }

    const handleTeamButtonPressed = () => {
        if (isTeamMaster) {
            console.log("Go to team management page.")
            dispatch(setIsTeamPage(true))
            changePage({
                pathname: AUTH_ROUTES_CONSULTANT.TEAM
            })
        } else {
            setAlertInfo({
                open: true,
                title: 'Be aware',
                description: 'By creating a team, you will lose the posibility to join another team under this account, if invited.',
                onConfirm: createTeam, onCancel: hideAlert, okTitle: "Create team"
            })
            console.log("Here we must create a team for the current user.")
            return
        }
    }

    const createTeam = async () => {
        console.log("Creating team...")

        hideAlert()
        setIsCreatingTeam(true)

        
        const lastWithdrawalPending = await firebase.firestore().collection('/withdrawals')
            .where("user", "==", dbUserID)
            .where("status", "in", ["pending"])
            .orderBy("date", "desc").limit(1)
            .get()
        if (!lastWithdrawalPending.empty) {
            dispatch(setErrorDetails({
              message: 'You cannot create a team because you have a pending withdrawal! Please wait until it\'s processed.'
            }))
            setIsCreatingTeam(false)
            return
        }

        functions.httpsCallable('createTeam')({themeID: themeID})
            .then(function () {
                console.log("Go to team management page.")
                setTimeout(async () => {
                    dispatch(setIsTeamPage(true))
                    setIsCreatingTeam(false)
                    await dispatch(checkTeamMembership()).unwrap()
                    changePage({
                        pathname: AUTH_ROUTES_CONSULTANT.TEAM
                    })
                }, 2000)
            }
            ).catch(error => {
                setIsCreatingTeam(false)
                setAlertInfo({ open: true, title: "Error", description: error.message, onConfirm: hideAlert })
            })
    }

    const hideAlert = () => {
        setAlertInfo(oldAlertInfo => ({
          ...oldAlertInfo,
          open: false
        }))
    }

    const themeMainColor = useTheme().palette.primary.main
    const useStyles = makeStyles({
        readMore: {
            color: themeMainColor,
            textTransform: "none",
            "&:hover": {
                backgroundColor: "transparent"
            },
            "&:active": {
                backgroundColor: "transparent"
            }
        },
        overlay: {
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1500,
            position: 'fixed'
        }
    });
    const styleClasses = useStyles();

    return (
        <div className={`${!isTeamMaster ? 'manage_team_create' : ''}`}>
            {alertInfo.open && <ConfirmAlert data={alertInfo} />}

            {(!isTeamMember || isTeamMaster) && dbUserID &&
                <div className='manage_team_buttons'>
                    <div className='manage_team_container'>
                        {isCreatingTeam ?
                            <div className='loading_box'>
                                <CircularProgress size={30} />
                            </div>
                        :
                            <>
                                <Button
                                    className="manage_team_btn"
                                    onClick={handleTeamButtonPressed}
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                >
                                    {isTeamMaster ? "Manage team" : "Create team"}
                                </Button>
                                {!isTeamMaster &&
                                    <Button disableRipple onClick={handleReadMore} className={`${styleClasses.readMore} manage_team_link`} variant="text">
                                        How teams work
                                    </Button>
                                }
                            </>
                        }
                    </div>
                </div>
            }
            {showWithdraw && widthLessThan850px && isConsultant && !isTeamMember && !isTeamMaster &&
                <Button
                    className="withdraw_button"
                    variant="outlined"
                    color="primary"
                    onClick={() => setWithdrawDialog(true)}>
                        Withdraw
                </Button>
            }
        </div>
    )

};

export default ManageTeam;

import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import 'firebase/functions'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { COMMON_ROUTES } from '../../../../utils/routes'
import LoginDialog from './LoginDialog';
import { useBookingContext } from '../BookingContext';
import useCookieTheme from '../../../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, userState } from '../../../../api/userSlice';

const Header = (props) => {
  const { isInstantCall } = props
  const fromConsultantsList = JSON.parse((new URL(document.location)).searchParams.get("fromConsultantsList") ?? null)
  const teamID = (new URL(document.location)).searchParams.get("teamID")
  const userID = useSelector(userState).user?.userID
  const testIntegration = useSelector(userState).testIntegration
  const dispatch = useDispatch()
  const { consultantData, isCreatedAccount } = useBookingContext()
  const { logo, bookingLogo, changePage } = useCookieTheme()

  const [openDialog, setOpenDialog] = useState(false)

  const onClickLogin = () => {
    if (testIntegration) {
      dispatch(setErrorDetails({
        message: 'Cannot login in preview widget because you\'re already logged as consultant!',
        severity: 'warning'
      }))
    } else {
      setOpenDialog(true)
    }
  }
 
  return (
    <div className='big_header'>
      {!userID && !isCreatedAccount &&
        <Button
          className='have_an_account_button'
          onClick={onClickLogin}
          variant="contained"
        >
          Have an account? Login here
        </Button>
      }
      <div className='header'>
        <Button
          className='dismiss_buttton'
          onClick={() => changePage({
            pathname: Boolean(teamID) ?
                        COMMON_ROUTES.TEAM_CONSULTANTS.replace(':teamID', teamID) :
                        fromConsultantsList ? 
                          COMMON_ROUTES.LIST : 
                          COMMON_ROUTES.CONSULTANT.replace(':consultant', consultantData.userID)
          })}
          color="primary"
          variant="outlined"
        >
          <KeyboardArrowLeftIcon />
          Dismiss
        </Button>
        <img
          alt=""
          src={bookingLogo ?? logo}
        />

        {openDialog && 
          <LoginDialog
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            isInstantCall={isInstantCall}
          />
        }
      </div>
    </div>
  )
}

export default Header;

import React from 'react';
import { styled } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { useMediaQuery } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setErrorDetails } from '../../api/userSlice';

const SwitchIOS = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : theme.palette.primary.main,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#D3E7F4',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const SwitchIOSMini = styled((props) => (
  <SwitchIOS {...props} />
))(() => ({
  width: 37,
  height: 21,
  '& .MuiSwitch-thumb': {
    width: 17,
    height: 17,
  },
}));

const SwitchIOSExtraMini = styled((props) => (
  <SwitchIOSMini {...props} />
))(() => ({
  width: 32,
  height: 16,
  '& .MuiSwitch-thumb': {
    width: 12,
    height: 12
  },
}));

export default function SwitchItem(props) {
  const dispatch = useDispatch()

  const heightLessThan720px = useMediaQuery('(max-height:719px)');
  const orientationPortrait = useMediaQuery('(orientation:portrait)');
  const widthLessThan650px = useMediaQuery('(max-width:649px)');

  const onChange = (e) => {
    props.set(e.target.checked);
    dispatch(setErrorDetails({
      message: null
    }))
  }

  return (
    (heightLessThan720px || (orientationPortrait && widthLessThan650px)) ? 
    <SwitchIOSExtraMini
      checked={props.checked}
      onChange={e => onChange(e)}
    /> :
      <SwitchIOSMini
        checked={props.checked}
        onChange={e => onChange(e)}
      />
  )
}
import { makeStyles } from "@material-ui/core";

export const useGlobalStyles = makeStyles(theme => ({
    '@global': {
        'body, .home_navigation': {
            backgroundColor: `${theme.palette.background.default}`,
        },
        '.MuiButton-contained:not(.Mui-disabled)': {
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
        '.MuiButton-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26) !important',
        },
        '.MuiButton-textPrimary': {
            color: `${theme.palette.primary.main} !important`,
        },
        '.details_client_form .react-datepicker__day--selected, .details_client_form .react-datepicker__time-list-item--selected': {
            color: `${theme.palette.primary.main} !important`,
        },
        '.details_client_form .react-datepicker__day--selected:hover, .details_client_form .react-datepicker__time-list-item--selected:hover': {
            color: 'white !important',
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
        '.test_page .primary_color_text': {
            color: theme.palette.primary.main,
        },
        '.profile_pic': {
            objectFit: 'cover'
        },
        'img': {
            objectFit: 'contain'
        },
        '.gift_button_container .coupon': {
            color: theme.palette.primary.main
        },
        '.booking_orange_button': {
            color: theme.palette.primary.main
        },
        '.sidebarItem.active .sidebarItem_icon svg path': {
            fill: theme.palette.primary.main
        },
        '.sidebarItem_line': {
            background: theme.palette.primary.main,
            boxShadow: `0px 0px 8px ${theme.palette.primary.main}`,
            width: '3px',
            height: '100%',
            marginRight: '20px',
            opacity: 0
        },
        '.sidebarItem.active .sidebarItem_label': {
            color: theme.palette.primary.main
        },
        '.home .label_type_4': {
            color: theme.palette.primary.main
        },
        '.consultantProfile .perMinuteButtons button': {
            background: theme.palette.primary.main,
        },
        '.createBooking_calendar .react-datepicker__time-list-item--selected': {
            background: `${theme.palette.primary.main} !important`,
        },
        '.createBooking_calendar.react - datepicker__time - list - item: hover': {
            background: `${theme.palette.primary.main} !important`
        },
        '.createBooking_calendar .react-datepicker__day--selected, .createBooking_calendar .react-datepicker__day--selected:focus': {
            background: theme.palette.primary.main
        },
        '.audio_video_selection_icon': {
            background: theme.palette.primary.main
        },
        '.finish_booking_info_item .selection_icon': {
            background: theme.palette.primary.main
        },
        '.withdraw_dialog .MuiRadio-colorSecondary.Mui-checked': {
            color: theme.palette.primary.main
        },
        '.priceForm .MuiInputBase-root': {
            color: theme.palette.primary.main,
        },
        '::-webkit-scrollbar-thumb': {
            background: theme.palette.primary.main
        },
        '.consultant_booking_content .stepper .MuiStepLabel-labelContainer .MuiStepLabel-active': {
            borderBottom: `5px ${theme.palette.primary.main} solid`,
        },
        '.consultant_booking_content .border_left': {
            borderLeft: `5px ${theme.palette.primary.main} solid`,
        },
        '.consultant_booking_content .add_to_calendar_button': {
            color: theme.palette.primary.main,
        },
        '.consultant_booking_content .add_to_calendar_dropdown ul li a': {
            color: theme.palette.primary.main,
        },
        '.confirm_alert_ok_button': {
            color: theme.palette.primary.main,
        }
    }
}),
);

export const usePaymentStyles = makeStyles(theme => ({
    '@global': {
        '.payment_card_current > div:nth-child(2)': {
            color: theme.palette.primary.main
        },
        '.booking_card .selection_icon': {
            color: theme.palette.primary.main
        },
        '.payment_check': {
            color: theme.palette.primary.main
        }
    }
}),
);

export const useBookingCardStyles = makeStyles(theme => ({
    '@global': {
        '.booking_accepted_status': {
            color: theme.palette.primary.main
        },
        '.booking_card .selection_icon': {
            color: theme.palette.primary.main
        }
    }
}),
);

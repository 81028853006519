import './styles.css';
import React, { useEffect, useState } from 'react';
import firebase from 'firebase';
import ConfirmAlert from '../components/ConfirmAlert';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, Link, TextField, useMediaQuery } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isValidEmail } from '../../utils/utils'
import TeamNameDialog from './components/TeamNameDialog'
import { config } from '../../config/config';
import AddMember from './components/AddMember';
import copy from 'copy-to-clipboard';
import TeamTabs from './components/TeamTabs';
import { useTeamContext } from './components/teamContext';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, userState } from '../../api/userSlice';

const Team = () => {
  const functions = firebase.functions()
  const firestore = firebase.firestore()
  const authUser = firebase.auth().currentUser
  const userID = useSelector(userState).user?.userID
  const dispatch = useDispatch()
  const {
    addInvitesListener,
    setIsFetching,
    alertInfo,
    setAlertInfo,
    fetchConsultantStats
  } = useTeamContext()

  const [teamInfo, setTeamInfo] = useState({})
  const [isFetchingInfo, setIsFetchingInfo] = useState(true)
  const [consultantProfilePic, setConsultantProfilePic] = useState(null);
  const [freeMinutesDialog, setFreeMinutesDialog] = useState(false)
  const [freeMinutes, setFreeMinutes] = useState(0)
  const [isUpdatingFreeMinues, setIsUpdatingFreeMinutes] = useState(false)
  const [isAddingMember, setIsAddingMember] = useState(false)

  const [email, setEmail] = useState("")
  const [formMode, setFormMode] = useState(false)

  const widthLessThan650px = useMediaQuery('(max-width:649px)');
  const widthLessThan700px = useMediaQuery('(max-width:699px)');

  const fetchTeamData = () => {
    const uid = authUser?.uid
    if (uid) {
      firestore.collection("teams").where("masterID", "==", uid).get().then(snapshot => {
        if (snapshot.length === 0) {
          console.log("Maybe you're admin?")
        } else {
          const teamData = snapshot.docs[0].data()
          console.log("TN: " + teamData.masterName)
          setTeamInfo({
            ...teamData,
            id: snapshot.docs[0].id
          })
          setFreeMinutes(teamData.freeMinutes ?? 1)
        }

      }).catch(error => {
        console.log(error)
        dispatch(setErrorDetails({
          message: "Error fetching team!"
        }))
      }).finally(() => {
        setIsFetchingInfo(false)
      })
    } else {
      dispatch(setErrorDetails({
        message: "You're not authenticated!"
      }))
    }
  }

  const startFetching = () => {
    if (authUser?.uid) {
      fetchTeamData()
      addInvitesListener()
    }
  }

  useEffect(startFetching, [userID])

  const newInviteTapped = () => {
    setEmail('')
    if (!formMode) {
      setFormMode(true)
    }
  }

  const cancelTapped = () => {
    setEmail('')
    setFormMode(false)
  }

  const sendInviteTapped = () => {
    const validEmail = isValidEmail(email)
    if (!validEmail) {
      dispatch(setErrorDetails({
        message: 'Invalid email address!'
      }))
      return
    }
    addInvite(email)
  }

  const addInvite = (email) => {
    setIsFetching(true)
    setIsAddingMember(true)
    const toSend = { invitedEmail: email }
    functions.httpsCallable('inviteToTeam')(toSend)
      .then(function () {
        setFormMode(false)
        setAlertInfo({
          open: true,
          title: "Invitation sent!",
          description: config.type === 'astrolgoer' ? `${email} has been added to your team.` :
            `The invitation has been sent to ${email}. Please inform the invited person to check their inbox.`,
          onConfirm: () => {
            hideAlert()
          }, okTitle: "OK"
        })
      }).catch(error => {
        dispatch(setErrorDetails({
          message: error.message
        }))
      }).finally(() => {
        setIsAddingMember(false)
        fetchConsultantStats()
      })
  }

  const teamNameUpdated = (name) => {
    const info = Object.create(teamInfo)
    info.masterName = name
    setTeamInfo(info)
  }

  const hideAlert = () => {
    setAlertInfo(oldAlertInfo => ({
      ...oldAlertInfo,
      open: false
    }))
  }

  useEffect(() => {
    const getProfilePic = async () => {
      firebase.app().storage().ref(`images/${teamInfo.masterID}.jpg`).getDownloadURL().then(dlURL => {
        setConsultantProfilePic(dlURL)
      }).catch(_ => {
        console.log('no profile pic')
        setConsultantProfilePic(null)
      })
    }

    if (teamInfo.masterID) {
      getProfilePic()
    }
  }, [teamInfo])

  const onClickSetFreeMinutes = async () => {
    setIsUpdatingFreeMinutes(true)

    await firestore.doc(`teams/${teamInfo.id}`).update({
      freeMinutes: freeMinutes
    })

    setTeamInfo(oldTeamInfo => {
      return {
        ...oldTeamInfo,
        freeMinutes: freeMinutes
      }
    })
    setIsUpdatingFreeMinutes(false)
    setFreeMinutesDialog(false)
  }

  const themeMainColor = useTheme().palette.primary.main
  const useStyles = makeStyles({
    gratuityLabel: {
      fontSize: 17,
      paddingLeft: 25,
      paddingRight: 70
    },
    teamNameLabel: {
      fontSize: 26,
      color: themeMainColor,
      height: 'auto !important',
    },
    nameEditButton: {
      paddingLeft: 25
    },
    totalAmount: {
      paddingLeft: 25,
      fontSize: 24,
      fontWeight: 'bold',
      color: 'darkslategray',
      margin: 0
    },
    amount: {
      color: themeMainColor,
    },
    withrawal_button: {
      color: 'white',
      backgroundColor: themeMainColor,
      "&:hover": {
        backgroundColor: themeMainColor,
        opacity: .7
      }
    },
    deleteTeamButton: {
      color: "#FFFFFF",
      "&:hover": {
        backgroundColor: "#A45A52"
      }
    }
  });

  const styleClasses = useStyles();

  return (
    <div className='team_container'>
      {authUser &&
        <>
          {isFetchingInfo ?
            <div className='loading_container'>
              <CircularProgress size={50} />
            </div>
          :
            <>
              {alertInfo.open && <ConfirmAlert data={alertInfo} />}
              <div className='team_header'>
                <div className='img_container'>
                  <img className='profile_pic' alt="" src={consultantProfilePic ?? require("../../images/user.png")} />
                </div>
                <div className='team_flex_column'>
                  <div className='team_flex'>
                    <Typography display="inline" gutterBottom className={`${styleClasses.teamNameLabel} teamNameLabel heading`}>
                      {teamInfo.masterName ?? ""}
                    </Typography>
                    <TeamNameDialog name={teamInfo.masterName ?? ""} className={styleClasses.nameEditButton} nameUpdated={teamNameUpdated} />
                  </div>
                  {config.type === 'astrologer' &&
                    <div className='free_min_container'>
                      <div className='free_min_text'>Free minutes: {freeMinutes ?? '1'} {freeMinutes > 1 ? 'mins' : 'min'}</div>
                      <Button onClick={() => setFreeMinutesDialog(true)} color="primary" variant="text">
                        Set
                      </Button>
                    </div>
                  }
                </div>
                {!widthLessThan650px &&
                  <AddMember
                    formMode={formMode}
                    newInviteTapped={newInviteTapped}
                    email={email}
                    setEmail={setEmail}
                    sendInviteTapped={sendInviteTapped}
                    cancelTapped={cancelTapped}
                    isAddingMember={isAddingMember}
                  />
                }
              </div>
              {widthLessThan650px &&
                <AddMember
                  formMode={formMode}
                  newInviteTapped={newInviteTapped}
                  email={email}
                  setEmail={setEmail}
                  sendInviteTapped={sendInviteTapped}
                  cancelTapped={cancelTapped}
                  isAddingMember={isAddingMember}
                />
              }
    
              <div className='team_flex link_container'>
                <div className="label_type_2 heading">
                  This is your team's link:
                </div>
                <Link className="label_type_4" target="_blank" href={`${config.webSite}/team/${teamInfo.masterName}/${teamInfo.code}`}>
                  {config.webSite}/team/{teamInfo.masterName}/{teamInfo.code}
                </Link>
                {widthLessThan700px ? (
                  <div className="label_type_3">
                    Share it with your clients or<br />
                    promote it on your website and social media<br />
                    so people can start using your paid services
                  </div>
                ) : (
                  <div className="label_type_3">
                    Share it with your clients or promote it on your website and<br />
                    social media so people can start using your paid services
                  </div>
                )}
                <Button 
                  className="home_copy_link_btn"
                  onClick={() => {
                    copy(`${config.webSite}/team/${teamInfo.masterName}/${teamInfo.code}`.replaceAll(' ', '%20'));
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Copy link
                </Button>
              </div>
    
              <TeamTabs />
            </>
          }
        </>
      }

      {freeMinutesDialog &&
        <Dialog
          open={freeMinutesDialog}
          onClose={() => {
            setFreeMinutesDialog(false)
            setFreeMinutes(teamInfo.freeMinutes)
          }}
        >
          <DialogContent>
            <DialogContentText className='free_minutes_dialog_content'>
              <Typography>Free minutes:</Typography>
              <TextField
                disabled={isUpdatingFreeMinues}
                autoFocus
                margin="dense"
                id="percentage"
                type="number"
                value={freeMinutes}
                onChange={(e) => {
                  let value = parseInt(e.target.value)
                  if (value < 1) {
                    value = 1
                  }
                  setFreeMinutes(value)
                }}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className='check_button' disabled={!freeMinutes} onClick={onClickSetFreeMinutes} color="primary" variant="outlined">
              {isUpdatingFreeMinues ? <CircularProgress size={20} /> : 'SET'}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  )
};

export default Team;

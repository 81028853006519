import React, {  } from 'react';
import TableCell from '@material-ui/core/TableCell';
import { TableRow, useMediaQuery } from '@material-ui/core';
import useCookieTheme from '../../../App/useCookieTheme';
import TeamTable from './TeamTable';
import TeamMemberRow from './TeamMemberRow';

const TeamMemberTable = (props) => {
  const { dataSource, handleRemove, canBeRemoved, canSetEmployeeCut } = props
  const { employeeTitle } = useCookieTheme()

  const widthLessThan650px = useMediaQuery('(max-width:649px)');
  const widthLessThan450px = useMediaQuery('(max-width:449px)');

  const canPresentHistory = (data) => {
    const isConsultant = data.type === "consultant"
    return isConsultant
  }

  const rowUniqueKey = (data) => {
    const isConsultant = data.type === "consultant"
    if (isConsultant) {
      return data.consultantID
    }
    return data.name
  }

  const tableCells = 
      <TableRow>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 80 : widthLessThan650px ? 100 : 150 }}>Name</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 100 : widthLessThan650px ? 120 : 170 }}>Email</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 85 : widthLessThan650px ? 110 : 145 }}>Total Revenue Since Last Withdrawal</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 40 : widthLessThan650px ? 55 : 70 }}>{employeeTitle} cut</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 95 : widthLessThan650px ? 125 : 165 }}>{employeeTitle} Revenue Since Last Withdrawal</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 95 : widthLessThan650px ? 125 : 165 }}>Time Spent in Call Since Last Withdrawal</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 130 : widthLessThan650px ? 170 : 230 }}>Profile Link</TableCell>
        <TableCell align="left" style={{ minWidth: widthLessThan450px ? 30 : widthLessThan650px ? 40 : 50 }}>Status</TableCell>
        <TableCell align="left">Actions</TableCell>
      </TableRow>

  const tableRows = 
      <>
        {dataSource.map((row) => (
          <TeamMemberRow 
            data={row}
            historyEnabled={canPresentHistory(row)}
            removeTapped={handleRemove}
            removeEnabled={canBeRemoved(row)}
            employeeCutEnabled={canSetEmployeeCut(row)}
            key={rowUniqueKey(row)}>
          </TeamMemberRow>
        ))}
      </>

  return (
    <TeamTable tableCells={tableCells} tableRows={tableRows} />
  )
};

export default TeamMemberTable;

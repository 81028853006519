import * as React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import { CircularProgress } from '@material-ui/core';


export default function Confirmation(props) {

    return (
        <Dialog
            open={true}
            onClose={() => props.onClose(false)}
        >
            <DialogTitle>Atention</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(false)}>No</Button>
                <Button onClick={props.onAction}>
                    {props.loading ? <CircularProgress size={20}/> : 'Yes'}
                </Button>
            </DialogActions>
        </Dialog>
    )
}


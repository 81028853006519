import React, { useState } from 'react'
import firebase from 'firebase/app'
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, DialogContentText, Button } from '@material-ui/core'

export default function ChangeEmailDialog(props) {
    const { handleClose, open } = props
    
    const [oldPassword, setOldPassword] = useState("")
    const [newEmail, setNewEmail] = useState("")
    const currentEmail = firebase.auth().currentUser.email

    const handleUpdateEmail = () => {
        handleClose()

        firebase.auth().signInWithEmailAndPassword(currentEmail, oldPassword).then(res => {
            firebase.auth().currentUser.updateEmail(newEmail)
        })
    }

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Edit Email</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Fill in these fields to update your email:
                </DialogContentText>
                <TextField
                    disabled
                    margin="dense"
                    label="Email address"
                    fullWidth
                    value={currentEmail}
                />
                <TextField
                    margin="dense"
                    label="New email address"
                    fullWidth
                    onChange={(event) => setNewEmail(event.target.value)}
                />
                <TextField
                    type="password"
                    margin="dense"
                    label="Password"
                    fullWidth
                    onChange={(event) => setOldPassword(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="white">
                    Cancel
                </Button>
                <Button disabled={oldPassword.length < 3 || newEmail.length < 3} onClick={handleUpdateEmail} color="primary">
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
}

import React, {  } from 'react'
import { useProfileContext } from '../profileContext';
import DiscountCoupons from '../components/DiscountCoupons';

const DiscountCouponsTab = () => {
    const { userID, setSeverity } = useProfileContext()

    return (
      <div id='profile_pricing' className='content_item'>
            <DiscountCoupons
                consultantID={userID}
                setSeverity={setSeverity}
            />
      </div>
    )
}

export default DiscountCouponsTab;

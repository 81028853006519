import moment from 'moment'
import { cloudFunctionsURL } from '../components/Firebase/firebase'
import getSymbolFromCurrency from 'currency-symbol-map'
import { countries } from './countries'
import { config } from '../config/config'

//FIXME delete this
export const SYMBOLS = {
    EUR: 'EUR',
    USD: 'USD',
}

export const localeToCurrency = (locale = "us") => {
    const currency = countries.find(each => each.code.toLowerCase() === locale.toLowerCase()).currency
    return getSymbolFromCurrency(currency) ?? currency
}

export const localeToCurrencyName = (locale = "us") => {
    return countries.find(each => each.code.toLowerCase() === locale)?.currency
}

export const localeString = (locale = "us") => {
    let currency = ""

    if (!locale) {
        return currency
    }
    const lowercaseLocale = locale.toLowerCase()
    return localeToCurrencyName(lowercaseLocale)
}

export const displayAsCurrency = (integer, locale = "us") => {
    const formatted = formattedIntegerLocaleSeparator(integer, locale)
    return `${formatted} ${localeString(locale)}`
}

export const formattedIntegerLocaleSeparator = (integer, locale = "us") => {
    let decimalPart = Math.abs(integer % 100).toFixed(0)
    let toRet = Math.trunc(integer / 100).toFixed(0)
    if (decimalPart.length === 1) {
        decimalPart = "0" + decimalPart
    }
    if (decimalPart.length === 2 && decimalPart[1] === '0' && !localeShowsSecondDecimal(locale)) {
        decimalPart = "" + decimalPart[0]
    }
    if (integer < 0 && !toRet.includes('-')) {
        toRet = '-' + toRet
    }
    if (decimalPart === "0") {
        return toRet
    }
    return toRet + decimalSeparator(locale) + decimalPart
}

const localeShowsSecondDecimal = (locale = "us") => {
    if (locale?.toLowerCase() === "us" || locale?.toLowerCase() === "au") {
        return true
    }
    return false
}

const decimalSeparator = (locale = "us") => {
    if (locale?.toLowerCase() === "us" || locale?.toLowerCase() === "au") {
        return "."
    }
    return ","
}

export function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        // eslint-disable-next-line no-mixed-operators
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16).toUpperCase();
    });
}

export const sessionMinutes = (data) => {
    const firstPing = data["firstPingDate"]
    const lastPing = data["lastPingDate"]

    if (!firstPing || !lastPing) {
      console.log("First / last ping missing, will show default 1")
      return 1
    }

    const first = firstPing.toDate()
    const last = lastPing.toDate()

    const difference = last - first

    const mins = Math.ceil((difference / 1000) / 60); // Floor means that 2 mins 59 secs will be rounded to 2 mins
    return Math.max(mins, 1)
}

const totalMinutes = (sessionData) => {
    if (!sessionData.firstPingDate || !sessionData.lastPingDate) {
        return 0
    }
    const startDate = sessionData.firstPingDate.toDate()
    const endDate = sessionData.lastPingDate.toDate()

    return Math.ceil(moment(endDate).diff(startDate, 'minutes', true))
}

export function calculateFakePrice(sessionData) {
    if (sessionData.bookingID) {
        return sessionData.bookingSuccessfullyCharged ?? 0
    }
    const price = sessionData.salesTax ? sessionData.price + sessionData.salesTax : sessionData.price
    const totalMins = totalMinutes(sessionData)
    const gratuityPeriod = sessionData.gratuityPeriod ?? 0
    const totalPaidMinutes = Math.max(totalMins - gratuityPeriod, 0)
    const fakePrice = totalPaidMinutes * price
    if (fakePrice === 0) {
        return fakePrice // Free calls still free
    }
    if (sessionData.noMinimumPaidPrice ?? false) {
        return fakePrice // No min price for corporate calls w/ wallet balance
    }
    //Otherwise apply min charge amount
    return Math.max(fakePrice, 200)
}

export function calculateRealPrice(sessionData) {
    if (sessionData.bookingID) {
        return sessionData.bookingSuccessfullyCharged ?? 0
    }
    return sessionData.successfullyCharged ?? 0
}

export function isFullyPaid(sessionData) {
    console.log(`[NDEBUG] Comparing ${calculateFakePrice(sessionData)} with ${calculateRealPrice(sessionData)}`)
    if (sessionData.price < 0) {
        return true
    }
    return calculateFakePrice(sessionData) === calculateRealPrice(sessionData)
}

export const minWithdrawal = 1000

export const isValidEmail = (email) => {
    if(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/.test(email)) {
        return true
    }
    return false
}

export const acceptURL = () => {
    return cloudFunctionsURL()
}

export const formatterRegex = {
    perMinuteOnlyRegex: "^-*[0-9]{1,2}(?:\\.[0-9]{1,2})?$",
    perMinute: "matchRegexp:^[0-9]{1,2}(?:\\.[0-9]{1,2})?$",
    bookingOnlyRegex: "^[0-9]{1,3}(?:\\.[0-9]{1,2})?$",
    booking: "matchRegexp:^[0-9]{1,3}(?:\\.[0-9]{1,2})?$"
}

export const numberFromString = (string) => {
    const split = string.split(".")
    if (split.length > 0 && split.length < 3) { }
    else {
        return 0
    }
    const mainAmount = parseInt(split[0])
    if (Number.isNaN(mainAmount)) {
        return 0
    }
    let decimalString = split.length > 1 ? split[1] : "0"
    if (decimalString.length === 0) {
        decimalString += "0"
    }
    let decimals = parseInt(decimalString)
    if (decimalString.length === 1) {
        decimals = decimals * 10
    }
    return mainAmount * 100 + decimals
}

export const stringFromNumber = (number) => {
    const decimalPart = number % 100
    const realPart = Math.floor(number / 100)
    return `${realPart}.${decimalPart}`
}

export const mapAmountsFunction = (arrayOfAmounts) => {
    return arrayOfAmounts.map(each => {
        if (each === 0) {
            return ""
        }
        return stringFromNumber(each)
    })
}

export const sDefault = (orig) => {
    if (!orig) {
        return [0, 0, 0, 0, 0, 0]
    } else {
        return orig
    }
}

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const updateSessionLog = (sessionID, text) => {
    console.log(text)

    const sessionLog = localStorage.getItem(sessionID)
    if (sessionLog) {
        const newSessionLog = `${sessionLog}\n${text}`
        localStorage.setItem(sessionID, newSessionLog)
    } else {
        localStorage.setItem(sessionID, text)
    }
}

export const bookingsDisabled = (isConsultant, consultantData) => {
    return isConsultant || !consultantData.bookingsEnabled || (
        !consultantData.audioBookingAmounts?.filter(amount => amount !== 0).length &&
        !consultantData.videoBookingAmounts?.filter(amount => amount !== 0).length &&
        (config.type === 'astrologer' || !consultantData.chatBookingAmounts?.filter(amount => amount !== 0).length))
}

import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@material-ui/core";
import firebase from "firebase/app";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useProfileContext } from "../profileContext";
import useCookieTheme from "../../App/useCookieTheme";
import {
  formatterRegex,
  localeToCurrencyName,
  mapAmountsFunction,
  numberFromString,
  sDefault,
  stringFromNumber,
} from "../../../utils/utils";
import SwitchItem from "../../components/SwitchIOS";
import { config } from "../../../config/config";
import InfoPopover from "../../components/InfoPopover";

const PricingTab = (props) => {
  const { setNotSetPerMin, setNotSetBookings } = props;
  const {
    user,
    setUser,
    setErrorMessage,
    setSeverity,
    open,
    anchorEl,
    anchorSet,
    handleInfo,
  } = useProfileContext();
  const { bookingEnableName } = useCookieTheme();

  const [isSavePricing, setIsSavePricing] = useState(false);
  const [enablePerMinuteCalls, setEnablePerMinuteCalls] = useState(user.online);
  const [isChangeEnablePerMinuteCalls, setIsChangeEnablePerMinuteCalls] =
    useState(false);
  const [videoPrice, setVideoPrice] = useState(
    user.videoPrice > 0 || user.videoPrice === -100
      ? stringFromNumber(user.videoPrice)
      : null
  );
  const [audioPrice, setAudioPrice] = useState(
    user.audioPrice > 0 || user.audioPrice === -100
      ? stringFromNumber(user.audioPrice)
      : null
  );
  const [chatPrice, setChatPrice] = useState(
    user.chatPrice > 0 || user.chatPrice === -100
      ? stringFromNumber(user.chatPrice)
      : null
  );
  const [enableBookingCalls, setEnableBookingCalls] = useState(
    user.bookingsEnabled
  );
  const [isChangeEnableBookingCalls, setIsChangeEnableBookingCalls] =
    useState(false);
  const [videoBookingAmounts, setVideoBookingAmounts] = useState(
    mapAmountsFunction(sDefault(user.videoBookingAmounts))
  );
  const [audioBookingAmounts, setAudioBookingAmounts] = useState(
    mapAmountsFunction(sDefault(user.audioBookingAmounts))
  );
  const [chatBookingAmounts, setChatBookingAmounts] = useState(
    mapAmountsFunction(sDefault(user.chatBookingAmounts))
  );
  const [enableRecordings, setEnableRecordings] = useState(
    user.recordingsEnabled
  );
  const [needBirthForm, setNeedBirthForm] = useState(user.needBirthForm);

  const widthLessThan850px = useMediaQuery("(max-width:849px)");
  const widthLessThan650px = useMediaQuery("(max-width:649px)");

  const minuteIndexes = [15, 30, 45, 60, 75, 90];

  const handleEnablePerMinuteCalls = (checked) => {
    if (!videoPrice && !audioPrice && !chatPrice) {
      setNotSetPerMin(true);
      return;
    }
    setIsChangeEnablePerMinuteCalls(true);
    firebase
      .app()
      .functions()
      .httpsCallable("updateUserProfile")({
        updateData: {
          online: checked,
          onlineCache: checked,
          videoPrice: videoPrice ? numberFromString(videoPrice) : null,
          audioPrice: audioPrice ? numberFromString(audioPrice) : null,
          chatPrice: chatPrice ? numberFromString(chatPrice) : null,
        },
        targetUserID: user.userID,
      })
      .then(() => {
        console.log("Success updating!");
        setEnablePerMinuteCalls(checked);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setSeverity("error");
      })
      .finally(() => {
        setIsChangeEnablePerMinuteCalls(false);
      });
  };

  const handleEnableBookingCalls = (checked) => {
    if (
      !audioBookingAmounts?.filter((amount) => amount !== "").length &&
      !videoBookingAmounts?.filter((amount) => amount !== "").length &&
      !chatBookingAmounts?.filter((amount) => amount !== "").length
    ) {
      setNotSetBookings(true);
      return;
    }
    setIsChangeEnableBookingCalls(true);
    firebase
      .app()
      .functions()
      .httpsCallable("updateUserProfile")({
        updateData: {
          bookingsEnabled: checked,
          audioBookingAmounts: audioBookingAmounts.map((each) =>
            numberFromString(each)
          ),
          videoBookingAmounts: videoBookingAmounts.map((each) =>
            numberFromString(each)
          ),
          chatBookingAmounts: chatBookingAmounts.map((each) =>
            numberFromString(each)
          ),
        },
        targetUserID: user.userID,
      })
      .then(() => {
        console.log("Success updating!");
        setEnableBookingCalls(checked);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setSeverity("error");
      })
      .finally(() => {
        setIsChangeEnableBookingCalls(false);
      });
  };

  const handleSavePricing = () => {
    setIsSavePricing(true);

    const setErrorValues = (message) => {
      setErrorMessage(
        message ?? "Price is outside allowed range! Min 2 units."
      );
      setSeverity("error");
      setIsSavePricing(false);
    };

    let toUpdate = null;
    try {
      toUpdate = {
        videoPrice: videoPrice ? numberFromString(videoPrice) : null,
        audioPrice: audioPrice ? numberFromString(audioPrice) : null,
        chatPrice: chatPrice ? numberFromString(chatPrice) : null,
        audioBookingAmounts: audioBookingAmounts.map((each) =>
          numberFromString(each)
        ),
        videoBookingAmounts: videoBookingAmounts.map((each) =>
          numberFromString(each)
        ),
        chatBookingAmounts: chatBookingAmounts.map((each) =>
          numberFromString(each)
        ),
      };
    } catch (e) {
      setErrorValues("Please insert valid values!");
      return;
    }

    if (
      videoPrice &&
      ((numberFromString(videoPrice) > 0 &&
        numberFromString(videoPrice) < 100) ||
        !videoPrice.match(formatterRegex.perMinuteOnlyRegex))
    ) {
      setErrorValues();
      return;
    }

    if (
      audioPrice &&
      ((numberFromString(audioPrice) > 0 &&
        numberFromString(audioPrice) < 100) ||
        !audioPrice.match(formatterRegex.perMinuteOnlyRegex))
    ) {
      setErrorValues();
      return;
    }

    if (
      chatPrice &&
      ((numberFromString(chatPrice) > 0 && numberFromString(chatPrice) < 100) ||
        !chatPrice.match(formatterRegex.perMinuteOnlyRegex))
    ) {
      setErrorValues();
      return;
    }

    let shouldReturn = false;
    audioBookingAmounts.forEach((amount) => {
      if (
        amount &&
        parseInt(amount) !== -1 &&
        ((numberFromString(amount) > 0 && numberFromString(amount) < 200) ||
          !amount.match(formatterRegex.bookingOnlyRegex))
      ) {
        shouldReturn = true;
        return;
      }
    });

    videoBookingAmounts.forEach((amount) => {
      if (
        amount &&
        parseInt(amount) !== -1 &&
        ((numberFromString(amount) > 0 && numberFromString(amount) < 200) ||
          !amount.match(formatterRegex.bookingOnlyRegex))
      ) {
        shouldReturn = true;
        return;
      }
    });

    chatBookingAmounts.forEach((amount) => {
      if (
        amount &&
        parseInt(amount) !== -1 &&
        ((numberFromString(amount) > 0 && numberFromString(amount) < 200) ||
          !amount.match(formatterRegex.bookingOnlyRegex))
      ) {
        shouldReturn = true;
        return;
      }
    });

    if (shouldReturn) {
      setErrorValues();
      return;
    }

    if (!Boolean(videoPrice) && !Boolean(audioPrice) && !Boolean(chatPrice)) {
      toUpdate.online = false;
      toUpdate.onlineCache = false;
      setEnablePerMinuteCalls(false);
    }
    console.log(
      "[nicdic] ",
      audioBookingAmounts,
      videoBookingAmounts,
      chatBookingAmounts
    );
    if (
      !audioBookingAmounts?.filter((amount) => amount !== "").length &&
      !videoBookingAmounts?.filter((amount) => amount !== "").length &&
      !chatBookingAmounts?.filter((amount) => amount !== "").length
    ) {
      toUpdate.bookingsEnabled = false;
      setEnableBookingCalls(false);
    }

    firebase
      .app()
      .functions()
      .httpsCallable("updateUserProfile")({
        updateData: toUpdate,
        targetUserID: user.userID,
      })
      .catch((error) => {
        setErrorValues(error.message);
      })
      .then(async () => {
        setUser((oldUser) => {
          return {
            ...oldUser,
            ...toUpdate,
          };
        });
        setErrorMessage("Successfully saved pricing");
        setSeverity("success");
        setIsSavePricing(false);
      });
  };

  const setEnableRecordingsHandle = (e) => {
    setEnableRecordings(e.target.checked);
    firebase.app().functions().httpsCallable("updateUserProfile")({
      updateData: { recordingsEnabled: e.target.checked },
      targetUserID: user.userID,
    });
  };

  const setNeedBirthFormHandle = (e) => {
    setNeedBirthForm(e.target.checked);
    firebase.app().functions().httpsCallable("updateUserProfile")({
      updateData: { needBirthForm: e.target.checked },
      targetUserID: user.userID,
    });
  };

  const themeMainColor = useTheme().palette.primary.main;

  const useStyles = makeStyles({
    price_p: {
      color: `${themeMainColor} !important`,
    },
  });
  const styleClasses = useStyles();

  return (
    <div id="profile_pricing" className="content_item">
      <div className="content_label heading">Pricing</div>
      <div className="pricing_container">
        <div>
          <div className="pricing_header pricing_per_min">
            <div className="pricing_label">
              <p>INSTANT CALLS</p>
            </div>
            {isChangeEnablePerMinuteCalls ? (
              <div className="loading_when_change_container">
                <CircularProgress size={10} />
              </div>
            ) : (
              <SwitchItem
                checked={enablePerMinuteCalls}
                set={handleEnablePerMinuteCalls}
              />
            )}
          </div>
          <ValidatorForm
            className="price_form price_form_per_min priceForm"
            onSubmit={() => {
              return;
            }}
          >
            <div className="price_form_item">
              <p
                className={`${
                  !enablePerMinuteCalls || !videoPrice ? "disable_color" : ""
                }`}
              >
                Video Price per minute
              </p>
              <div
                className={`price_item ${
                  videoPrice &&
                  numberFromString(videoPrice) > 0 &&
                  (numberFromString(videoPrice) < 100 ||
                    !videoPrice?.match(formatterRegex.perMinuteOnlyRegex))
                    ? "error_input"
                    : ""
                }`}
              >
                <p
                  className={`price_p ${styleClasses.price_p} ${
                    !enablePerMinuteCalls ||
                    !videoPrice ||
                    parseInt(videoPrice) === -1
                      ? "disable_color"
                      : ""
                  }`}
                >
                  {localeToCurrencyName(user.locale)}
                </p>
                <div
                  className={`${
                    !enablePerMinuteCalls || !videoPrice ? "disable_input" : ""
                  }`}
                >
                  <TextValidator
                    disabled={parseInt(videoPrice) === -1}
                    onChange={(e) => setVideoPrice(e.target.value)}
                    value={
                      videoPrice && numberFromString(videoPrice) > 0
                        ? videoPrice
                        : ""
                    }
                    variant="outlined"
                  />
                </div>
              </div>
              {config.type === "yaloop" && (
                <>
                  <div
                    className={`price_item checkbox_free ${
                      enablePerMinuteCalls && parseInt(videoPrice) === -1
                        ? ""
                        : "disable_color"
                    }`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={parseInt(videoPrice) === -1}
                          onChange={(e) => {
                            const newV = e.target.checked ? "-1" : "";
                            setVideoPrice(newV);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="FREE"
                    />
                  </div>
                  <Divider />
                </>
              )}
            </div>
            <div className="price_form_item">
              <p
                className={`${
                  !enablePerMinuteCalls || !audioPrice ? "disable_color" : ""
                }`}
              >
                Audio Price per minute
              </p>
              <div
                className={`price_item ${
                  audioPrice &&
                  numberFromString(audioPrice) > 0 &&
                  (numberFromString(audioPrice) < 100 ||
                    !audioPrice?.match(formatterRegex.perMinuteOnlyRegex))
                    ? "error_input"
                    : ""
                }`}
              >
                <p
                  className={`price_p ${styleClasses.price_p} ${
                    !enablePerMinuteCalls ||
                    !audioPrice ||
                    parseInt(audioPrice) === -1
                      ? "disable_color"
                      : ""
                  }`}
                >
                  {localeToCurrencyName(user.locale)}
                </p>
                <div
                  className={`${
                    !enablePerMinuteCalls || !audioPrice ? "disable_input" : ""
                  }`}
                >
                  <TextValidator
                    disabled={parseInt(audioPrice) === -1}
                    onChange={(e) => setAudioPrice(e.target.value)}
                    value={
                      audioPrice && numberFromString(audioPrice) > 0
                        ? audioPrice
                        : ""
                    }
                    variant="outlined"
                  />
                </div>
              </div>
              {config.type === "yaloop" && (
                <>
                  <div
                    className={`price_item checkbox_free ${
                      enablePerMinuteCalls && parseInt(audioPrice) === -1
                        ? ""
                        : "disable_color"
                    }`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={parseInt(audioPrice) === -1}
                          onChange={(e) => {
                            const newV = e.target.checked ? "-1" : "";
                            setAudioPrice(newV);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="FREE"
                    />
                  </div>
                  <Divider />
                </>
              )}
            </div>
            <div className="price_form_item">
              <p
                className={`${
                  !enablePerMinuteCalls || !chatPrice ? "disable_color" : ""
                }`}
              >
                Text Chat Price per minute
              </p>
              <div
                className={`price_item ${
                  chatPrice &&
                  numberFromString(chatPrice) > 0 &&
                  (numberFromString(chatPrice) < 100 ||
                    !chatPrice?.match(formatterRegex.perMinuteOnlyRegex))
                    ? "error_input"
                    : ""
                }`}
              >
                <p
                  className={`price_p ${styleClasses.price_p} ${
                    !enablePerMinuteCalls ||
                    !chatPrice ||
                    parseInt(chatPrice) === -1
                      ? "disable_color"
                      : ""
                  }`}
                >
                  {localeToCurrencyName(user.locale)}
                </p>
                <div
                  className={`${
                    !enablePerMinuteCalls || !chatPrice ? "disable_input" : ""
                  }`}
                >
                  <TextValidator
                    disabled={parseInt(chatPrice) === -1}
                    onChange={(e) => setChatPrice(e.target.value)}
                    value={
                      chatPrice && numberFromString(chatPrice) > 0
                        ? chatPrice
                        : ""
                    }
                    variant="outlined"
                  />
                </div>
              </div>
              {config.type === "yaloop" && (
                <>
                  <div
                    className={`price_item checkbox_free ${
                      enablePerMinuteCalls && parseInt(chatPrice) === -1
                        ? ""
                        : "disable_color"
                    }`}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={parseInt(chatPrice) === -1}
                          onChange={(e) => {
                            const newV = e.target.checked ? "-1" : "";
                            setChatPrice(newV);
                          }}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="FREE"
                    />
                  </div>
                  <Divider />
                </>
              )}
            </div>
          </ValidatorForm>
        </div>
        <Divider
          orientation={`${widthLessThan850px ? "horizontal" : "vertical"}`}
          variant={`${widthLessThan850px ? "fullWidth" : "middle"}`}
          className="pricing_divider"
        />
        <div>
          {config.type === "yaloop" && (
            <p className="details_prices">
              Choose a price for any desired session type/duration or leave it
              blank. You need to complete at least one. If you prefer not to
              offer any session calls, simply turn off the 'Enable{" "}
              {bookingEnableName} calls?' switch.
            </p>
          )}
          <div className="booking_pricing">
            <div className="booking_pricing_item">
              <div>
                <ValidatorForm
                  className="price_form priceForm"
                  onSubmit={() => {
                    return;
                  }}
                >
                  <div className="pricing_header">
                    <div className="pricing_label">
                      <p>{bookingEnableName.toUpperCase()} CALLS</p>
                    </div>
                    {isChangeEnableBookingCalls ? (
                      <div className="loading_when_change_container">
                        <CircularProgress size={10} />
                      </div>
                    ) : (
                      <SwitchItem
                        checked={enableBookingCalls}
                        set={handleEnableBookingCalls}
                      />
                    )}
                  </div>
                  <p className="booking_calls_label">
                    VIDEO CALLS {bookingEnableName.toUpperCase()}S
                  </p>
                  {videoBookingAmounts?.map((amount, index) => (
                    <div key={index}>
                      <p
                        className={`${
                          !enableBookingCalls || amount === ""
                            ? "disable_color"
                            : ""
                        }`}
                      >
                        {minuteIndexes[index]} min session price
                      </p>
                      <div
                        className={`price_item ${
                          numberFromString(amount) > 0 &&
                          (numberFromString(amount) < 100 ||
                            !amount?.match(formatterRegex.bookingOnlyRegex))
                            ? "error_input"
                            : ""
                        }`}
                      >
                        <p
                          className={`price_p ${styleClasses.price_p} ${
                            !enableBookingCalls || numberFromString(amount) <= 0
                              ? "disable_color"
                              : ""
                          }`}
                        >
                          {localeToCurrencyName(user.locale)}
                        </p>
                        <div
                          className={`${
                            !enableBookingCalls || amount === ""
                              ? "disable_input"
                              : ""
                          }`}
                        >
                          <TextValidator
                            disabled={parseInt(amount) === -1}
                            onChange={(e) => {
                              const newV = e.target.value;
                              const newAmounts = [...videoBookingAmounts];
                              newAmounts[index] = newV;
                              setVideoBookingAmounts(newAmounts);
                            }}
                            value={numberFromString(amount) > 0 ? amount : ""}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      {config.type === "yaloop" && (
                        <>
                          <div
                            className={`price_item checkbox_free ${
                              enableBookingCalls && parseInt(amount) === -1
                                ? ""
                                : "disable_color"
                            }`}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={parseInt(amount) === -1}
                                  onChange={(e) => {
                                    const newV = e.target.checked ? "-1" : "";
                                    const newAmounts = [...videoBookingAmounts];
                                    newAmounts[index] = newV;
                                    setVideoBookingAmounts(newAmounts);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="FREE"
                            />
                          </div>
                          <Divider />
                        </>
                      )}
                    </div>
                  ))}
                </ValidatorForm>
              </div>
            </div>
            <Divider
              orientation={`${widthLessThan650px ? "horizontal" : "vertical"}`}
              variant="middle"
              className="special_divider"
            />
            <div className="booking_pricing_item">
              <div>
                <ValidatorForm
                  className="price_form priceForm"
                  onSubmit={() => {
                    return;
                  }}
                >
                  <div className="pricing_header pricing_header_small"></div>
                  <p className="booking_calls_label">
                    AUDIO CALLS {bookingEnableName.toUpperCase()}S
                  </p>
                  {audioBookingAmounts?.map((amount, index) => (
                    <div key={index}>
                      <p
                        className={`${
                          !enableBookingCalls || amount === ""
                            ? "disable_color"
                            : ""
                        }`}
                      >
                        {minuteIndexes[index]} min session price
                      </p>
                      <div
                        className={`price_item ${
                          numberFromString(amount) > 0 &&
                          (numberFromString(amount) < 100 ||
                            !amount?.match(formatterRegex.bookingOnlyRegex))
                            ? "error_input"
                            : ""
                        }`}
                      >
                        <p
                          className={`price_p ${styleClasses.price_p} ${
                            !enableBookingCalls || numberFromString(amount) <= 0
                              ? "disable_color"
                              : ""
                          }`}
                        >
                          {localeToCurrencyName(user.locale)}
                        </p>
                        <div
                          className={`${
                            !enableBookingCalls || amount === ""
                              ? "disable_input"
                              : ""
                          }`}
                        >
                          <TextValidator
                            disabled={parseInt(amount) === -1}
                            onChange={(e) => {
                              const newV = e.target.value;
                              const newAmounts = [...audioBookingAmounts];
                              newAmounts[index] = newV;
                              setAudioBookingAmounts(newAmounts);
                            }}
                            value={numberFromString(amount) > 0 ? amount : ""}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      {config.type === "yaloop" && (
                        <>
                          <div
                            className={`price_item checkbox_free ${
                              enableBookingCalls && parseInt(amount) === -1
                                ? ""
                                : "disable_color"
                            }`}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={parseInt(amount) === -1}
                                  onChange={(e) => {
                                    const newV = e.target.checked ? "-1" : "";
                                    const newAmounts = [...audioBookingAmounts];
                                    newAmounts[index] = newV;
                                    setAudioBookingAmounts(newAmounts);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="FREE"
                            />
                          </div>
                          <Divider />
                        </>
                      )}
                    </div>
                  ))}
                </ValidatorForm>
              </div>
            </div>
            <Divider
              orientation={`${widthLessThan650px ? "horizontal" : "vertical"}`}
              variant="middle"
              className="special_divider"
            />
            <div className="booking_pricing_item">
              <div>
                <ValidatorForm
                  className="price_form priceForm"
                  onSubmit={() => {
                    return;
                  }}
                >
                  <div className="pricing_header pricing_header_small"></div>
                  <p className="booking_calls_label">
                    TEXT CHAT {bookingEnableName.toUpperCase()}S
                  </p>
                  {chatBookingAmounts?.map((amount, index) => (
                    <div key={index}>
                      <p
                        className={`${
                          !enableBookingCalls || amount === ""
                            ? "disable_color"
                            : ""
                        }`}
                      >
                        {minuteIndexes[index]} min session price
                      </p>
                      <div
                        className={`price_item ${
                          numberFromString(amount) > 0 &&
                          (numberFromString(amount) < 100 ||
                            !amount?.match(formatterRegex.bookingOnlyRegex))
                            ? "error_input"
                            : ""
                        }`}
                      >
                        <p
                          className={`price_p ${styleClasses.price_p} ${
                            !enableBookingCalls || numberFromString(amount) <= 0
                              ? "disable_color"
                              : ""
                          }`}
                        >
                          {localeToCurrencyName(user.locale)}
                        </p>
                        <div
                          className={`${
                            !enableBookingCalls || amount === ""
                              ? "disable_input"
                              : ""
                          }`}
                        >
                          <TextValidator
                            disabled={parseInt(amount) === -1}
                            onChange={(e) => {
                              const newV = e.target.value;
                              const newAmounts = [...chatBookingAmounts];
                              newAmounts[index] = newV;
                              setChatBookingAmounts(newAmounts);
                            }}
                            value={numberFromString(amount) > 0 ? amount : ""}
                            variant="outlined"
                          />
                        </div>
                      </div>
                      {config.type === "yaloop" && (
                        <>
                          <div
                            className={`price_item checkbox_free ${
                              enableBookingCalls && parseInt(amount) === -1
                                ? ""
                                : "disable_color"
                            }`}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={parseInt(amount) === -1}
                                  onChange={(e) => {
                                    const newV = e.target.checked ? "-1" : "";
                                    const newAmounts = [...chatBookingAmounts];
                                    newAmounts[index] = newV;
                                    setChatBookingAmounts(newAmounts);
                                  }}
                                  name="checkedB"
                                  color="primary"
                                />
                              }
                              label="FREE"
                            />
                          </div>
                          <Divider />
                        </>
                      )}
                    </div>
                  ))}
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="save_button">
        <Button
          disabled={isSavePricing}
          color="primary"
          variant="contained"
          onClick={handleSavePricing}
        >
          Save
        </Button>
      </div>
      {config.type !== "astrologer" && (
        <div className="recordings_checkbox">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableRecordings}
                  onChange={setEnableRecordingsHandle}
                  color="primary"
                />
              }
              label="Enable recording calls"
            />
            <InfoPopover
              open={open.enableRecordings}
              anchorEl={anchorEl.enableRecordings}
              setAnchorEl={(value) => anchorSet(value, "enableRecordings")}
              handleInfo={(e) =>
                handleInfo(e, (value) => anchorSet(value, "enableRecordings"))
              }
              buttonClass="availability_info_button"
            >
              <div className="timezone_info_content">
                When enabled, all calls (both instant and booked sessions) are
                automatically recorded. Clients receive a link to access the
                recording via email, and it is also available in their account's
                history section. The recording will be accessible for 30 days
                before it expires.
              </div>
            </InfoPopover>
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={needBirthForm}
                  onChange={setNeedBirthFormHandle}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Need date of birth form"
            />
            <InfoPopover
              open={open.needBirthForm}
              anchorEl={anchorEl.needBirthForm}
              setAnchorEl={(value) => anchorSet(value, "needBirthForm")}
              handleInfo={(e) =>
                handleInfo(e, (value) => anchorSet(value, "needBirthForm"))
              }
              buttonClass="availability_info_button"
            >
              <div className="timezone_info_content">
                Mainly beneficial for astrologers, this feature integrates a
                birth date intake form into the booking process.
              </div>
            </InfoPopover>
          </div>
        </div>
      )}
    </div>
  );
};

export default PricingTab;

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  currentBookingID: null,
  activeSession: null,
  isCallActive: false,
  endCallReason: null,
}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setCurrentBookingID(state, action) {
      state.currentBookingID = action.payload
    },
    setActiveSession(state, action) {
      state.activeSession = JSON.parse(action.payload)
    },
    setIsCallActive(state, action) {
      state.isCallActive = action.payload
    },
    setEndCallReason(state, action) {
      state.endCallReason = action.payload
    },
  },
})

export const {
  setCurrentBookingID,
  setActiveSession,
  setIsCallActive,
  setEndCallReason,
} = sessionSlice.actions

export const sessionState = state => state.session

export default sessionSlice.reducer

import React, { useEffect, useState } from 'react'
import { Button, Divider, FormControlLabel, Checkbox } from '@material-ui/core'
import firebase from 'firebase/app'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useProfileContext } from '../profileContext';
import ChangeEmailDialog from '../components/changeEmailDialog';
import ChangePasswordDialog from '../components/changePasswordDialog';
import DeleteAccountDialog from '../components/deleteAccountDialog';
import { useDispatch, useSelector } from 'react-redux';
import { userState, setErrorDetails, setUserState } from '../../../api/userSlice';

const PersonalDetailsTab = () => {
    const { user, setUser } = useProfileContext()
    const dispatch = useDispatch()
    const userID = useSelector(userState).user?.userID

    const [personalDetails, setPersonalDetails] = useState({
      name: user.name,
      surname: user.surname,
      consultancyType: user.consultancyType,
      aboutMe: user.aboutMe,
      serviceDetails: user.serviceDetails
    })
    const [invoicing, setInvoicing] = useState(null)
    const [isSaveProfile, setIsSaveProfile] = useState(false)
    const [displayInvoicing, setDisplayInvoicing] = useState(false)

    const [changePasswordDialog, setChangePasswordDialog] = useState(false)
    const [changeEmailDialog, setChangeEmailDialog] = useState(false)
    const [deleteAccountDialog, setDeleteAccountDialog] = useState(false)

    useEffect(() => {
        if (user.legal_entity) {
            firebase.firestore().doc(user.legal_entity.path).get().then((legalData) => {
                const dataLegalData = legalData.data()
                setInvoicing({
                    name: dataLegalData.name,
                    address: dataLegalData.address,
                    taxIdentificationNumber: dataLegalData.taxIdentificationNumber
                })
                setDisplayInvoicing(true)
            })
        }
    }, [user])

    useEffect(() => {
      ValidatorForm.addValidationRule('minChars', (value) => {
          if (value.toString().length < 2) {
              return false;
          }
          return true;
      });
    }, [])

    const handleSave = async () => {
        setIsSaveProfile(true)
        try {
            const toUpdate = {
                name: personalDetails.name,
                surname: personalDetails.surname,
                consultancyType: personalDetails.consultancyType,
                aboutMe: personalDetails.aboutMe,
                serviceDetails: personalDetails.serviceDetails,
            }
            const newUserData = await firebase.app().functions().httpsCallable("updateUserProfile")({
                updateData: toUpdate,
                targetUserID: user.userID,
            })
            console.log("Success updating!")
    
            const legal = user.legal_entity
            if (displayInvoicing) {
                const legatData = {
                    address: invoicing.address,
                    name: invoicing.name,
                    taxIdentificationNumber: invoicing.taxIdentificationNumber
                }
                if (legal) {
                    legal.update(legatData)
                } else {
                    const doc = firebase.firestore().collection('legal_entities').doc()
                    doc.set(legatData)
                    firebase.app().firestore().doc('/users/' + user.userID).update({ legal_entity: doc })
                }
            } else {
                if (legal) {
                    setInvoicing(null)
                    legal.delete()
                    firebase.app().firestore().doc('/users/' + user.userID).update({ legal_entity: null })
                }
            }
    
            if (user.userID === userID) {
                dispatch(setUserState(newUserData.data))
            }
            setUser(oldUser => {
                return {
                    ...oldUser,
                    name: personalDetails.name,
                    surname: personalDetails.surname,
                    consultancyType: personalDetails.consultancyType,
                    aboutMe: personalDetails.aboutMe,
                    serviceDetails: personalDetails.serviceDetails,
                }
            })
            dispatch(setErrorDetails({
              message: 'Successfully saved changes',
              severity: 'success'
            }))
            setIsSaveProfile(false)
        } catch (error) {
            dispatch(setErrorDetails({
              message: error.message
            }))
            setIsSaveProfile(false)
        }
    }

    const themeMainColor = useTheme().palette.primary.main
  
    const useStyles = makeStyles({
        change_pass_button: {
            color: themeMainColor
        }
    })
    const styleClasses = useStyles();

    return (
      <div className='content_item'>
          <div className='content_label heading'>Personal Details</div>
          <ValidatorForm
              className='form_names'
              onSubmit={() => { return }}
          >
              <div>
                  <p>First Name</p>
                  <TextValidator
                      validators={['required', 'minChars']}
                      variant="outlined"
                      errorMessages={['This field is required', 'Min 2 characters']}
                      onChange={(e) => { 
                          const { value } = e.target
                          setPersonalDetails(oldUser => ({
                              ...oldUser,
                              name: value
                          }))
                      }}
                      value={personalDetails.name}
                      fullWidth
                      size='small'
                  />
              </div>
              <div>
                  <p>Last Name</p>
                  <TextValidator
                      validators={['required', 'minChars']}
                      variant="outlined"
                      errorMessages={['This field is required', 'Min 2 characters']}
                      onChange={(e) => { 
                          const { value } = e.target
                          setPersonalDetails(oldUser => ({
                              ...oldUser,
                              surname: value
                          }))
                      }}
                      value={personalDetails.surname}
                      fullWidth
                      size='small'
                  />
              </div>
              <div>
                  <p>Expertise</p>
                  <TextValidator 
                      validators={['required']}
                      variant="outlined"
                      errorMessages={['This field is required']}
                      onChange={(e) => { 
                          const { value } = e.target
                          setPersonalDetails(oldUser => ({
                              ...oldUser,
                              consultancyType: value
                          }))
                      }}
                      value={personalDetails.consultancyType}
                      fullWidth
                      size='small'
                  />
              </div>
          </ValidatorForm>
          <Divider className='divider' variant='middle' />
          <ValidatorForm
              onSubmit={() => { return }}
          >
              <div>
                  <p>About Me</p>
                  <TextValidator 
                      validators={['required']}
                      variant="outlined"
                      errorMessages={['This field is required']}
                      onChange={(e) => { 
                          const { value } = e.target
                          setPersonalDetails(oldUser => ({
                              ...oldUser,
                              aboutMe: value
                          }))
                      }}
                      value={personalDetails.aboutMe}
                      fullWidth
                      multiline
                      size='small'
                  />
              </div>
              <div>
                  <p>Service Details</p>
                  <TextValidator 
                      validators={['required']}
                      variant="outlined"
                      errorMessages={['This field is required']}
                      onChange={(e) => { 
                          const { value } = e.target
                          setPersonalDetails(oldUser => ({
                              ...oldUser,
                              serviceDetails: value
                          }))
                      }}
                      value={personalDetails.serviceDetails}
                      fullWidth
                      multiline
                      size='small'
                  />
              </div>
              {user.userID === userID &&
                  <>
                      <FormControlLabel
                          className='invoicing_checkbox'
                          control={
                              <Checkbox
                                  checked={displayInvoicing}
                                  onChange={() => setDisplayInvoicing(oldDisplayInvoicing => !oldDisplayInvoicing)}
                                  name="checkedB"
                                  color="primary"
                              />
                          }
                          label='Need invoice as a company'
                      />
                      {displayInvoicing &&
                          <div className='invoicing'>
                              <div>
                                  <p>Company Name</p>
                                  <TextValidator 
                                      validators={['required']}
                                      variant="outlined"
                                      errorMessages={['This field is required']}
                                      onChange={(e) => { 
                                          const { value } = e.target
                                          setInvoicing(oldInvoicing => ({
                                              ...oldInvoicing,
                                              name: value
                                          }))
                                      }}
                                      value={invoicing?.name ?? ""}
                                      fullWidth
                                      size='small'
                                  />
                              </div>
                              <div>
                                  <p>Address</p>
                                  <TextValidator 
                                      validators={['required']}
                                      variant="outlined"
                                      errorMessages={['This field is required']}
                                      onChange={(e) => { 
                                          const { value } = e.target
                                          setInvoicing(oldInvoicing => ({
                                              ...oldInvoicing,
                                              address: value
                                          }))
                                      }}
                                      value={invoicing?.address ?? ""}
                                      fullWidth
                                      size='small'
                                  />
                              </div>
                              <div>
                                  <p>Tax ID</p>
                                  <TextValidator 
                                      validators={['required']}
                                      variant="outlined"
                                      errorMessages={['This field is required']}
                                      onChange={(e) => { 
                                          const { value } = e.target
                                          setInvoicing(oldInvoicing => ({
                                              ...oldInvoicing,
                                              taxIdentificationNumber: value
                                          }))
                                      }}
                                      value={invoicing?.taxIdentificationNumber ?? ""}
                                      fullWidth
                                      size='small'
                                  />
                              </div>
                          </div>
                      }
                  </>
              }
          </ValidatorForm>
          <div className='save_button'>
              <Button disabled={isSaveProfile} color="primary" variant="contained" onClick={handleSave}>
                  Save
              </Button>
          </div>
          {user.userID === userID && 
              <>
                  <Divider className='divider' variant='middle' />
                  <div className='footer_buttons'>
                      <Button className={styleClasses.change_pass_button} onClick={() => setChangeEmailDialog(true)}>
                          Change email
                      </Button>
                      <Button className={styleClasses.change_pass_button} onClick={() => setChangePasswordDialog(true)}>
                          Change password
                      </Button>
                      <Button className='delete_button' onClick={() => setDeleteAccountDialog(true)}>
                          Delete Account
                      </Button>
                  </div>
              </>
          }

          {changeEmailDialog && <ChangeEmailDialog handleClose={() => setChangeEmailDialog(false)} open={changeEmailDialog} />}
          {changePasswordDialog && <ChangePasswordDialog handleClose={() => setChangePasswordDialog(false)} open={changePasswordDialog} />}
          {deleteAccountDialog && <DeleteAccountDialog handleClose={() => setDeleteAccountDialog(false)} open={deleteAccountDialog} />}
      </div>
    )
}

export default PersonalDetailsTab;

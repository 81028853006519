import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import SignInPage from "../SignIn";
import HomePage from "../Home";
import ConsultantPage from "../ConsultantProfile";
import firebase from "firebase/app";
import { ThemeProvider } from "@material-ui/core/styles";
import UserPaymentPage from "../Payment";
import TeamAcceptInvite from "../TeamAcceptInvite";
import Team from "../Team";
import { TermsAndConditionsPage, PrivacyPolicyPage } from "../Helpers/PDFPage";
import ProfilePage from "../MyProfile";
import Alert from "@material-ui/lab/Alert";
import Sidebar from "../sidebar/Sidebar";
import Navigation from "../Navigation";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import { useGlobalStyles } from "./globalStyles";
import AccountDetailsClient from "../AccountDetailsClient";
import { config, defaultTheme } from "../../config/config";
import ActiveCall from "../ActiveCall";
import useCookieTheme from "./useCookieTheme";
import { convertMessage } from "../Helpers/util";
import ConsultantsList from "../ConsultantsList";
import {
  AUTH_ROUTES_CLIENT,
  AUTH_ROUTES_CONSULTANT,
  checkIfPageExists,
  COMMON_ROUTES,
  NO_AUTH_ROUTES,
} from "../../utils/routes";
import PerMinCard from "../Home/components/PerMinCard";
import AccountResetPasswordPage from "../Account/AccountResetPassword";
import useSessionHook from "./useSessionHook";
import Banner from "./Banner";
import History from "../History";
import TestCall from "../TestCall";
import { isAndroid, isChrome, isIOS, isMobile } from "react-device-detect";
import { BookingProvider } from "../ConsultantProfile/ConsultantBooking/BookingContext";
import ConsultantBooking from "../ConsultantProfile/ConsultantBooking";
import GuestCall from "../GuestCall";
import { notSameDataMessages } from "../../utils/theme";
import ConfirmAlert from "../components/ConfirmAlert";
import GuestInstantCall from "../ConsultantProfile/components/GuestInstantCall";
import DownloadRecording from "../DownloadRecording";
import ReviewDialog from "./ReviewDialog";
import TeamConsultantsList from "../Team/components/TeamConsultantsList/TeamConsultantsList";
import { Route, Switch, useHistory } from "react-router-dom";
import { TeamProvider } from "../Team/components/teamContext";
import { ProfileProvider } from "../MyProfile/profileContext";
import { useDispatch, useSelector } from "react-redux";
import {
  userState,
  checkTeamMembership,
  executeLogout,
  fetchUser,
  getBalance,
  getProfilePic,
  setNotSetUserOnline,
  setErrorDetails,
  setIntegration,
  setTestIntegration,
  setIsIntegrationTeam,
} from "../../api/userSlice";
import { sessionState, setEndCallReason } from "../../api/sessionSlice";
import WebsiteIntegration from "../WebsiteIntegration";
import {
  fetchTheme,
  setRealThemeID,
  themeState,
  updateTheme,
} from "../../api/themeSlice";
import TestWidget from "../TestWidget";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { formattedIntegerLocaleSeparator, localeToCurrency } from "../../utils/utils";

const queryClient = new QueryClient();

const App = () => {
  console.log("RENDER APP");
  const { themeMui, autoNotifyTeamMaster, name, changePage } = useCookieTheme();
  const history = useHistory();
  const fromEmail = JSON.parse(
    new URL(document.location).searchParams.get("fromEmail") ?? null
  );
  const integrationCode = new URL(document.location).searchParams.get(
    "integration"
  );
  const testIntegration = JSON.parse(
    new URL(document.location).searchParams.get("testIntegration") ?? null
  );
  const params = new URL(document.location).searchParams;
  const themeName = params.get("theme") ?? null;

  const [reviewSessionID, setReviewSessionID] = useState(null);
  const [callData, setCallData] = useState(null);
  const isChromeIOS = isChrome && isIOS;
  const [bannerOn, setBannerOn] = useState(
    isMobile &&
      (isAndroid || isIOS) &&
      !isChromeIOS &&
      config.type !== "astrologer" &&
      !Boolean(integrationCode)
  );
  const [successMessage, setSuccessMessage] = useState(
    convertMessage(params.get("message"))
  );
  const authUserRef = useRef(null);

  const dispatch = useDispatch();
  const dbUser = useSelector(userState).user;
  const isConsultant = useSelector(userState).user?.consultant;
  const isLoadingTheme = useSelector(themeState).isLoadingTheme;
  const isLoading = useSelector(userState).isLoading;
  const isTeamMaster = useSelector(userState).isTeamMaster;
  const errorMessage = useSelector(userState).errorMessage;
  const verticalAnchor = useSelector(userState).verticalAnchor;
  const severity = useSelector(userState).severity;
  const activeSession = useSelector(sessionState).activeSession;
  const isCallActive = useSelector(sessionState).isCallActive;
  const endCallReason = useSelector(sessionState).endCallReason;

  const isSignUpInBookingProcess =
    useSelector(userState).isSignUpInBookingProcess;
  const isSignUpInBookingProcessRef = useRef(isSignUpInBookingProcess);
  useEffect(() => {
    isSignUpInBookingProcessRef.current = isSignUpInBookingProcess;
  }, [isSignUpInBookingProcess]);

  const notSetUserOnline = useSelector(userState).notSetUserOnline;
  const notSetUserOnlineRef = useRef(notSetUserOnline);
  useEffect(() => {
    notSetUserOnlineRef.current = notSetUserOnline;
  }, [notSetUserOnline]);

  const theme = useSelector(themeState).theme;
  const themeRef = useRef(theme);
  useEffect(() => {
    themeRef.current = theme;
  }, [theme]);

  const errorGettingUser = useSelector(userState).errorGettingUser;
  if (errorGettingUser) {
    firebase.auth().signOut();
  }

  const {
    missedCallAlert,
    setMissedCallAlert,
    setCurPath,
    pingTapped,
    acceptPPMCallTapped,
  } = useSessionHook();

  const didUpdate = useRef(false);

  useEffect(() => {
    if (integrationCode) {
      dispatch(setIntegration(integrationCode));
      if (window.location.pathname.includes("/team/")) {
        dispatch(setIsIntegrationTeam(true));
      }

      setInterval(() => {
        let height =
          document.getElementById("scrolling_container")?.scrollHeight ?? 0;
        if (height !== undefined) {
          if (authUserRef.current) {
            // height = height + 90;
          }
        }

        const width = themeRef.current?.width ?? 100;

        if (!didUpdate.current) {
          // didUpdate.current = true;
          window.parent.postMessage(
            {
              height: height,
              width: width,
            },
            "*"
          );
        }
      }, 500);

      document.body.classList.add("body_no_scrolling");

      window.addEventListener("message", function (event) {
        const dialogClass = document.querySelector(".MuiDialog-container");
        if (event.data.scrollHeight !== undefined && dialogClass) {
          const scroll = event.data.scrollHeight - event.data.offset;
          dialogClass.style.alignItems = "flex-start";
          dialogClass.style.marginTop = `${
            scroll - 50 > 0 ? scroll : scroll + 50
          }px`;
          if (event.data.maxHeight) {
            dialogClass.style.maxHeight = `${event.data.maxHeight}px`;
          }
        }
      });
    }
    if (testIntegration) {
      dispatch(setTestIntegration(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationCode, testIntegration]);

  const [alertInfo, setAlertInfo] = useState({ open: false });
  const [isChechedTeamInvite, setCheckedTeamInvite] = useState(false);
  useEffect(() => {
    if (dbUser?.shouldBeInvited) {
      const msg = notSameDataMessages(autoNotifyTeamMaster, name);
      setAlertInfo({
        open: true,
        title: "",
        description: msg,
        onConfirm: async () => {
          setAlertInfo({ open: false });
          await dispatch(executeLogout()).unwrap();
        },
      });
      setCheckedTeamInvite(false);
    } else {
      setCheckedTeamInvite(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser]);

  useEffect(() => {
    const reviewSessionIDLocalStorage = localStorage.getItem("reviewSessionID");
    if (reviewSessionIDLocalStorage) {
      setReviewSessionID(reviewSessionIDLocalStorage);
      localStorage.removeItem("reviewSessionID");
    }
  }, []);

  useEffect(() => {
    if (isConsultant) {
      const userRef = firebase
        .firestore()
        .doc(`users/${authUserRef.current?.uid}`);
      const unsubscribe = userRef.onSnapshot((user) => {
        const userData = user.data();
        if (
          authUserRef.current &&
          !notSetUserOnlineRef.current &&
          userData?.consultant &&
          userData.onlineCache &&
          !userData.online
        ) {
          userRef.update({ online: userData.onlineCache, canCallOnWeb: true });
        }
        if (!authUserRef.current) {
          unsubscribe();
        }
      });
    }
  }, [isConsultant, notSetUserOnline]);

  useEffect(() => {
    const callDataLocalStorage = localStorage.getItem("callData");
    if (callDataLocalStorage) {
      setCallData(JSON.parse(callDataLocalStorage));
      localStorage.removeItem("callData");
    }
  }, []);

  useEffect(() => {
    if (callData && dbUser) {
      dispatch(setEndCallReason({
        severity: 'warning',
        message: 'Processing payment...'
      }))

      const { sessionID, severity, message, paymentFailed } = callData
      if (sessionID) {
        const unsubscribe = firebase.firestore().doc(`sessions/${sessionID}`).onSnapshot(res => {
          const newData = res.data()
          let successfullyCharged = newData?.successfullyCharged
          const paidWithCredit = newData?.paidWithCredit
          const processed = newData?.processed
          const locale = newData?.locale
          console.log("Session price iss: " + successfullyCharged)
          let newMessage = message
          if (processed !== undefined) {
            if (successfullyCharged === 0 || newData.isFree) {
              newMessage += " This session was free."
            } else {
              if (isConsultant) {
                const percentage = newData.customConsultantPercentage ?? 0.9
                if (newData?.salesTax) {
                  const minutes =  newData?.successfullyCharged / ( newData?.price +  newData?.salesTax)
                  successfullyCharged = newData?.price * minutes
                }
                let remainingSessionCost = Math.round(successfullyCharged * percentage)
    
                let teamAmount = ""
                if (newData.teamMemberPercentage) {
                  const beforeCopy = remainingSessionCost
                  remainingSessionCost = Math.round(remainingSessionCost * newData.teamMemberPercentage)
                  teamAmount = `${formattedIntegerLocaleSeparator(beforeCopy - remainingSessionCost, locale)}${localeToCurrency(locale)}`
                }
    
                const prettySessionCost = `${formattedIntegerLocaleSeparator(remainingSessionCost, locale)}${localeToCurrency(locale)}`
                newMessage += " You earned " + prettySessionCost
    
                if (teamAmount !== "") {
                  newMessage += `. Team cut: ${teamAmount}`
                }
              } else {
                const paidByCash = paidWithCredit ? successfullyCharged - paidWithCredit : successfullyCharged
                if (paidByCash) {
                  const prettySessionCost = `${formattedIntegerLocaleSeparator(paidByCash, locale)}${localeToCurrency(locale)}`
                  newMessage += ` Your card was charged ${prettySessionCost}.`
                }
                if (paidWithCredit) {
                  const prettySessionCost = `${formattedIntegerLocaleSeparator(paidWithCredit, locale)}${localeToCurrency(locale)}`
                  newMessage += ` Paid by coupon credit: ${prettySessionCost}.`
                }
              }
            }
  
            dispatch(setEndCallReason({
              severity: paymentFailed ? 'error' : severity,
              message: paymentFailed ? 'The call ended due to a payment error or slow connection to network!' : newMessage,
            }))
          }
        })
  
        return () => unsubscribe()
      } else {
        dispatch(setEndCallReason({
          severity: paymentFailed ? 'error' : severity,
          message: paymentFailed ? 'The call ended due to a payment error or slow connection to network!' : message,
        }))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callData, dbUser])

  useEffect(() => {
    if (!isLoading) {
      const pageName = window.location.pathname;
      const pageExists = checkIfPageExists(authUserRef, isConsultant, pageName);
      if (!pageExists) {
        changePage({
          pathname: AUTH_ROUTES_CONSULTANT.HOME,
          search: fromEmail ? "fromEmail=true" : "",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUserRef, isConsultant, fromEmail, isLoading]);

  useEffect(() => {
    const iframe = document.getElementById("check_logged_user");
    if (iframe) {
      const wind = iframe.contentWindow;
      if (wind) {
        const data = {
          loggedUser: Boolean(authUserRef.current),
        };

        wind.postMessage(JSON.stringify(data), "https://lokiapp.live/");
        console.log("Successfully sent message to https://lokiapp.live/");
      }
    }
  }, [authUserRef]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (authUserValue) => {
      let shouldRedirectForAstrologer = config.type === "astrologer";
      shouldRedirectForAstrologer =
        shouldRedirectForAstrologer &&
        ((!authUserValue && window.location.pathname !== "/") ||
          (authUserValue && authUserValue.email !== "ana@astrologyhub.com"));
      if (shouldRedirectForAstrologer) {
        window.location.href = "https://astrologyhub.com/connect";
      }

      const loggedInWidget = localStorage.getItem("loggedInWidget")
        ? JSON.parse(localStorage.getItem("loggedInWidget"))
        : null;
      if (Boolean(integrationCode) && !loggedInWidget) {
        authUserRef.current = null;
        await dispatch(fetchUser(null)).unwrap();
      } else {
        authUserRef.current = authUserValue;
        if (authUserValue) {
          await dispatch(checkTeamMembership()).unwrap();
        } else {
          dispatch(setNotSetUserOnline(false));
        }
        if (!isSignUpInBookingProcessRef.current) {
          await dispatch(fetchUser(authUserValue?.uid)).unwrap();
          await dispatch(getProfilePic()).unwrap();
          await dispatch(getBalance()).unwrap();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [didCreateDefaultSchedule, setCreatedDefaultSchedule] = useState(false);
  useEffect(() => {
    if (!authUserRef.current) {
      setCreatedDefaultSchedule(false);
      return;
    }
    if (!isConsultant || didCreateDefaultSchedule) {
      return;
    }
    setCreatedDefaultSchedule(true);
    console.log("Attempting to create default schedule if none exists..");

    try {
      firebase.app().functions().httpsCallable("generateDefaultSchedule")({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    } catch (_) {}
  }, [isConsultant, didCreateDefaultSchedule]);

  useEffect(() => {
    if (
      authUserRef.current &&
      !isConsultant &&
      (!dbUser?.timezone ||
        dbUser?.timezone !== Intl.DateTimeFormat().resolvedOptions().timeZone)
    ) {
      firebase
        .firestore()
        .doc("/users/" + authUserRef.current.uid)
        .update({
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });
    }
  }, [isConsultant, dbUser]);

  useEffect(() => {
    const cleanup = () => {
      console.log("Running cleanup!!");
      const userRef = firebase
        .firestore()
        .doc(`users/${authUserRef.current?.uid}`);
      userRef.get().then((user) => {
        const dbUser = user.data();
        const fieldsToChange = {};
        if (dbUser?.consultant) {
          fieldsToChange.canCallOnWeb = false;
          if (!dbUser.vapidKey) {
            fieldsToChange.online = false;
          }
        }
        if (Object.keys(fieldsToChange).length > 0) {
          userRef.update(fieldsToChange);
        }
      });
    };

    window.addEventListener("beforeunload", cleanup);

    return () => {
      window.removeEventListener("beforeunload", cleanup);
    };
  }, []);

  useEffect(() => {
    const reloadTheme = async () => {
      let themeID;
      if (integrationCode) {
        let consultantData = (
          await firebase
            .firestore()
            .collection("/users")
            .where("code", "==", integrationCode)
            .get()
        ).docs[0]?.data();
        if (!consultantData) {
          const teamData = (
            await firebase
              .firestore()
              .collection("/teams")
              .where("code", "==", integrationCode)
              .get()
          ).docs[0]?.data();
          if (teamData) {
            consultantData = (
              await firebase
                .firestore()
                .doc(`/users/${teamData.masterID}`)
                .get()
            ).data();
          }
        }
        if (consultantData) {
          if (testIntegration && consultantData.testIntegrationThemeID) {
            themeID = consultantData.testIntegrationThemeID;
          } else if (consultantData.integrationThemeID) {
            themeID = consultantData.integrationThemeID;
            dispatch(setRealThemeID(consultantData.themeID));
          } else {
            themeID = consultantData.themeID;
          }
        } else {
          themeID = defaultTheme.id;
        }
      } else {
        let consultantData;
        const consultant = window.location.pathname.split("/")[2];
        if (window.location.pathname.includes("/consultant/")) {
          consultantData = (
            await firebase.firestore().doc(`/users/${consultant}`).get()
          ).data();
        } else if (consultant) {
          consultantData = (
            await firebase
              .firestore()
              .collection("/users")
              .where("code", "==", consultant)
              .get()
          ).docs[0]?.data();
        }
        if (consultantData) {
          themeID = consultantData.themeID;
        } else if (dbUser?.themeID) {
          themeID = dbUser.themeID;
        } else if (themeName) {
          themeID = `${themeName}Theme`;
        } else {
          themeID = defaultTheme.id;
        }
      }

      await dispatch(
        fetchTheme({
          newThemeID: themeID,
          history,
        })
      ).unwrap();
    };

    reloadTheme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbUser?.themeID, themeName]);

  useEffect(() => {
    if (integrationCode && theme?.id) {
      const unusubscribe = firebase
        .firestore()
        .doc(`themes/${theme.id}`)
        .onSnapshot((theme) => {
          const themeData = theme.data();
          dispatch(
            updateTheme({
              name: themeData?.name,
              title: themeData?.title,
              logo: themeData?.logo,
              invoiceLogo: themeData?.invoiceLogo,
              color: themeData?.color,
              backgroundColor: themeData?.backgroundColor,
              tileBg: themeData?.tileBg,
              width: themeData?.width,
            })
          );
        });

      return () => {
        unusubscribe();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationCode, theme?.id]);

  useGlobalStyles();

  if (window.location.pathname.includes("undefined")) {
    changePage({
      pathname: AUTH_ROUTES_CONSULTANT.HOME,
    });
  }

  if (!isChechedTeamInvite) {
    return <>{alertInfo.open && <ConfirmAlert data={alertInfo} />}</>;
  }

  return (
    <div id={`app-${config.type}`} className="app_container">
      {config.type === "loki" && (
        <iframe
          title="Check logged user"
          id="check_logged_user"
          src="https://lokiapp.live/"
          style={{ display: "none" }}
        />
      )}

      {endCallReason && (
        <Snackbar
          open={Boolean(endCallReason)}
          autoHideDuration={30000}
          onClose={() => {
            dispatch(setEndCallReason(null));
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              dispatch(setEndCallReason(null));
            }}
            severity={endCallReason.severity}
          >
            {endCallReason.message}
          </Alert>
        </Snackbar>
      )}

      {Boolean(errorMessage) && (
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={10000}
          onClose={() => {
            dispatch(
              setErrorDetails({
                message: null,
              })
            );
          }}
          anchorOrigin={{ vertical: verticalAnchor, horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              dispatch(
                setErrorDetails({
                  message: null,
                })
              );
            }}
            severity={severity}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      )}

      {successMessage && (
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={10000}
          onClose={() => setSuccessMessage(null)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => setSuccessMessage(null)} severity={`success`}>
            {successMessage}
          </Alert>
        </Snackbar>
      )}

      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeMui}>
          {!isLoading ? (
            <div>
              {!isLoadingTheme && bannerOn && (
                <Banner setBannerOn={setBannerOn} />
              )}

              {!isLoadingTheme ? (
                <>
                  {dbUser ? (
                    <div
                      style={{
                        display: "flex",
                        marginTop: bannerOn ? "49px" : "0",
                      }}
                    >
                      <Sidebar bannerOn={bannerOn} />

                      <div className="right_container">
                        <div className="home_navigation">
                          <Navigation />
                        </div>

                        <div
                          id="scrolling_container"
                          className="scrolling_content_container"
                        >
                          {activeSession && !isCallActive && isConsultant ? (
                            <PerMinCard
                              acceptPPMCallTapped={acceptPPMCallTapped}
                            />
                          ) : (
                            <Switch>
                              {isTeamMaster && (
                                <Route
                                  exact
                                  path={AUTH_ROUTES_CONSULTANT.TEAM}
                                  render={() => {
                                    return (
                                      <TeamProvider>
                                        <Team />
                                      </TeamProvider>
                                    );
                                  }}
                                />
                              )}

                              <Route
                                path={AUTH_ROUTES_CONSULTANT.PROFILE}
                                render={(props) => {
                                  return (
                                    <ProfileProvider>
                                      <ProfilePage {...props} />
                                    </ProfileProvider>
                                  );
                                }}
                              />

                              <Route
                                path={AUTH_ROUTES_CLIENT.ACCOUNT_DETAILS}
                                render={(props) => {
                                  return <AccountDetailsClient />;
                                }}
                              />

                              <Route
                                path={AUTH_ROUTES_CONSULTANT.HISTORY}
                                render={(props) => {
                                  return <History />;
                                }}
                              />

                              <Route
                                path={AUTH_ROUTES_CLIENT.PAYMENT}
                                render={(props) => {
                                  return <UserPaymentPage {...props} />;
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.TEST}
                                render={(props) => {
                                  return <TestCall {...props} />;
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.DOWNLOAD_RECORDING}
                                render={(props) => {
                                  return (
                                    <BookingProvider>
                                      <DownloadRecording {...props} />
                                    </BookingProvider>
                                  );
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.LIST}
                                render={(props) => {
                                  return <ConsultantsList />;
                                }}
                              />

                              <Route
                                exact
                                path={COMMON_ROUTES.TEAM_CONSULTANTS}
                                render={(props) => {
                                  return <TeamConsultantsList {...props} />;
                                }}
                              />

                              <Route
                                exact
                                path={COMMON_ROUTES.GUEST_INSTANT_CALL}
                                render={(props) => {
                                  return (
                                    <BookingProvider>
                                      <GuestInstantCall {...props} />
                                    </BookingProvider>
                                  );
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.ACTIVE_CALL}
                                render={(props) => {
                                  return <ActiveCall {...props} />;
                                }}
                              />

                              <Route
                                exact
                                path={AUTH_ROUTES_CONSULTANT.HOME}
                                render={(props) => {
                                  return <HomePage {...props} />;
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.TEAM_INVITE}
                                render={(props) => {
                                  return <TeamAcceptInvite {...props} />;
                                }}
                              />

                              <Route
                                path={
                                  AUTH_ROUTES_CONSULTANT.WEBSITE_INTEGRATION
                                }
                                render={(props) => {
                                  return <WebsiteIntegration />;
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.TEST_WIDGET}
                                render={(props) => {
                                  return <TestWidget />;
                                }}
                              />

                              <Route
                                exact
                                path={COMMON_ROUTES.CONSULTANT_BOOKING}
                                render={(props) => {
                                  return (
                                    <BookingProvider>
                                      <ConsultantBooking {...props} />
                                    </BookingProvider>
                                  );
                                }}
                              />

                              <Route
                                exact
                                path={COMMON_ROUTES.CONSULTANT}
                                render={(props) => {
                                  return <ConsultantPage {...props} />;
                                }}
                              />

                              <Route
                                path={COMMON_ROUTES.CONSULTANT_DYNAMIC_PAGE}
                                render={(props) => {
                                  return <ConsultantPage {...props} />;
                                }}
                              />
                            </Switch>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="scrolling_container"
                      className={`scrolling_content_container scrolling_content_container_guest ${
                        bannerOn ? "scrolling_content_container_banner" : ""
                      }`}
                    >
                      <Switch>
                        <Route
                          path={NO_AUTH_ROUTES.RESET_PASSWORD}
                          render={(props) => {
                            return <AccountResetPasswordPage />;
                          }}
                        />

                        <Route
                          path={NO_AUTH_ROUTES.TERMS_AND_CONDITIONS}
                          render={(props) => {
                            return <TermsAndConditionsPage />;
                          }}
                        />

                        <Route
                          path={NO_AUTH_ROUTES.PRIVACY}
                          render={(props) => {
                            return <PrivacyPolicyPage />;
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.TEAM_INVITE}
                          render={(props) => {
                            return <TeamAcceptInvite {...props} />;
                          }}
                        />

                        <Route
                          path={NO_AUTH_ROUTES.GUEST_CALL}
                          render={(props) => {
                            return <GuestCall {...props} />;
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.GUEST_INSTANT_CALL}
                          render={(props) => {
                            return (
                              <BookingProvider>
                                <GuestInstantCall {...props} />
                              </BookingProvider>
                            );
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.ACTIVE_CALL}
                          render={(props) => {
                            return <ActiveCall {...props} />;
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.LIST}
                          render={(props) => {
                            return <ConsultantsList />;
                          }}
                        />

                        <Route
                          exact
                          path={COMMON_ROUTES.TEAM_CONSULTANTS}
                          render={(props) => {
                            return <TeamConsultantsList {...props} />;
                          }}
                        />

                        <Route
                          exact
                          path={NO_AUTH_ROUTES.SIGN_UP}
                          render={(props) => {
                            return (
                              <SignInPage {...props} bannerOn={bannerOn} />
                            );
                          }}
                        />

                        {config.type === "astrologer" && (
                          <Route
                            path={NO_AUTH_ROUTES.SIGN_UP_COSNULTANT}
                            render={(props) => {
                              return (
                                <SignInPage
                                  {...props}
                                  forConsultant={true}
                                  bannerOn={bannerOn}
                                />
                              );
                            }}
                          />
                        )}

                        <Route
                          path={COMMON_ROUTES.TEST}
                          render={(props) => {
                            return <TestCall {...props} />;
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.DOWNLOAD_RECORDING}
                          render={(props) => {
                            return (
                              <BookingProvider>
                                <DownloadRecording {...props} />
                              </BookingProvider>
                            );
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.TEST_WIDGET}
                          render={(props) => {
                            return <TestWidget />;
                          }}
                        />

                        <Route
                          exact
                          path={COMMON_ROUTES.CONSULTANT_BOOKING}
                          render={(props) => {
                            return (
                              <BookingProvider>
                                <ConsultantBooking {...props} />
                              </BookingProvider>
                            );
                          }}
                        />

                        <Route
                          exact
                          path={COMMON_ROUTES.CONSULTANT}
                          render={(props) => {
                            return <ConsultantPage {...props} />;
                          }}
                        />

                        <Route
                          path={COMMON_ROUTES.CONSULTANT_DYNAMIC_PAGE}
                          render={(props) => {
                            return <ConsultantPage {...props} />;
                          }}
                        />
                      </Switch>
                    </div>
                  )}
                </>
              ) : (
                <div className="loading_container">
                  <CircularProgress size={50} />
                </div>
              )}
            </div>
          ) : (
            <div className="loading_container">
              <CircularProgress size={50} />
            </div>
          )}

          {reviewSessionID && (
            <ReviewDialog
              sessionID={reviewSessionID}
              setOpen={setReviewSessionID}
            />
          )}

          <Dialog
            open={Boolean(missedCallAlert)}
            onClose={() => {
              setMissedCallAlert(null);
              setCurPath(null);
            }}
          >
            <DialogTitle>
              Oops, looks like you missed a{" "}
              {missedCallAlert?.isBooking && "scheduled "}call from{" "}
              {missedCallAlert?.clientName}!
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                If you're available now, you can ping{" "}
                {missedCallAlert?.clientName} to let them know.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={pingTapped}>
                PING
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  setMissedCallAlert(null);
                  setCurPath(null);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      </QueryClientProvider>

      <audio id="session_ring" loop={true} />
    </div>
  );
};

export default App;

import React, { useState } from 'react'
import { IconButton, Avatar } from '@material-ui/core'
import { formattedIntegerLocaleSeparator, localeToCurrency, uuidv4, isFullyPaid, calculateFakePrice, calculateRealPrice, updateSessionLog } from '../../../utils/utils'
import firebase from 'firebase/app'
import { Box, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as AudioIcon } from '../../../images/audio.svg';
import { ReactComponent as VideoIcon } from '../../../images/video.svg';
import ChatIcon from '@material-ui/icons/Chat'
import UnpaidSessionDialog from '../../Home/components/bookingCard/UnpaidSessionDialog'
import { config } from '../../../config/config'
import { AUTH_ROUTES_CLIENT, COMMON_ROUTES } from '../../../utils/routes'
import ConfirmAlert from '../../components/ConfirmAlert'
import { beforeTestMessage } from '../../../utils/constants'
import useCookieTheme from '../../App/useCookieTheme'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveSession } from '../../../api/sessionSlice'
import { setErrorDetails, userState } from '../../../api/userSlice'
import useBookingHook from '../useBookingHook'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const PerMinuteButtons = (props) => {
  const { data, setSeverity, fromConsultantsList, teamID } = props
  const dbUserID = useSelector(userState).user?.userID
  const dbUser = useSelector(userState).user
  const isConsultant = useSelector(userState).user?.consultant
  const integration = useSelector(userState).integration
  const { setBooking } = useBookingHook()
  const { changePage } = useCookieTheme()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [unpaidSession, setUnpaidSession] = useState(null)
  const [alertInfo, setAlertInfo] = useState({ open: false })

  const audioAvailable = data.online && (data.audioPrice > 0 || data.audioPrice === -100) && !data.isBusy
  const videoAvailable = data.online && (data.videoPrice > 0 || data.videoPrice === -100) && !data.isBusy
  const chatAvailable = data.online && (data.chatPrice > 0 || data.chatPrice === -100) && !data.isBusy

  const startCallAsync = async (videoEnabled, isChatCall, contactInfoData, uid) => {
    if (dbUserID) {
      const lastSession = await firebase.app().firestore().collection('sessions').where('clientID', '==', dbUserID).where("status", "==", "finished").orderBy('dateCreated', 'desc').limit(1).get()

      if (lastSession.docs.length > 0) { // We got a session
        const lastSesh = lastSession.docs[0]
        const lastSeshData = lastSesh.data()

        if (!isFullyPaid(lastSeshData)) {
          setLoading(false)
          setUnpaidSession(lastSesh)
          return
        }
      }
    }

    const { locale, corporate, userID } = data
    const name = dbUserID ? dbUser.name : contactInfoData.firstName
    const surname = dbUserID ? dbUser.surname : contactInfoData.lastName
    const fullName = `${name} ${surname}`

    const createNewSessionParams = {
      consultantID: userID,
      callUUID: uuidv4(),
      clientFullName: fullName,
      clientName: name,
      videoEnabled: videoEnabled,
      isChatCall: isChatCall,
      locale: locale,
      corporate: corporate,
      firstChargeFromWallet: false,
      clientTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      clientEmail: contactInfoData?.email,
      isGuestSession: !Boolean(dbUserID),
    }
    if (dbUserID) {
      createNewSessionParams.clientID = dbUserID
    }

    try {
      const result = await firebase.app().functions("europe-west1").httpsCallable('createNewSession')(createNewSessionParams)
      const newSessionID = result.data.sessionID
      const newSession = await firebase.firestore().doc(`/sessions/${newSessionID}`).get()

      updateSessionLog(newSessionID, `[SESSION - ${newSessionID}] ${new Date()} - Starting ${isChatCall ? 'text chat' : videoEnabled ? 'video' : 'audio'} call by ${dbUserID ? `client ${dbUserID}` : `guest ${contactInfoData?.email}`}`)

      dispatch(setActiveSession(JSON.stringify({
        ...newSession.data(),
        id: newSession.id
      })))
      setLoading(false)
      const from = teamID ? 
                    COMMON_ROUTES.TEAM_CONSULTANTS.replace(':teamID', teamID) :
                    fromConsultantsList ? COMMON_ROUTES.LIST : COMMON_ROUTES.CONSULTANT.replace(':consultant', data.userID)
      changePage({
        pathname: COMMON_ROUTES.ACTIVE_CALL,
        search: `isConsultant=false&from=${from}`
      })
    } catch(_) {
      dispatch(setErrorDetails({
        message: "Consultant unavailable at the moment. Please try again!"
      }))
      if (setSeverity) {
        setSeverity('error')
      }
      setLoading(false)
    }
  }

  const tappedAudioCall = () => {
    checkIfTestNeeded(false)
  }

  const tappedVideoCall = () => {
    checkIfTestNeeded(true)
  }

  const tappedChatCall = () => {
    checkIfTestNeeded(false, true)
  }

  const checkIfTestNeeded = (videoEnabled, isChatCall) => {
    if (dbUserID) {
      if (isChatCall) {
        startCall(videoEnabled, isChatCall)
      } else {
        setAlertInfo({
          open: true, 
          title: "",
          description: beforeTestMessage(videoEnabled),
          okTitle: 'Test',
          cancelTitle: 'Skip',
          onConfirm: () => {
            setAlertInfo({ open: false })
            changePage({
              pathname: COMMON_ROUTES.TEST,
              search: `videoEnabled=${videoEnabled}`,
              startCall: () => { startCall(videoEnabled, isChatCall) },
            })
          },
          onCancel: () => {
            setAlertInfo({ open: false })
            startCall(videoEnabled, isChatCall)
          },
          onClose: () => {
            setAlertInfo({ open: false })
          }
        })
      }
    } else {
      changePage({
        pathname: COMMON_ROUTES.GUEST_INSTANT_CALL.replace(':consultant', data.userID),
        search: `isChatCall=${isChatCall ?? false}&videoEnabled=${videoEnabled}&fromConsultantsList=${fromConsultantsList ?? false}${teamID ? `&teamID=${teamID ?? null}` : ''}`,
        startCall: (contactInfoData, uid) => {
          startCall(videoEnabled, isChatCall, contactInfoData, uid)
        },
      })
    }
  }

  const startCall = (videoEnabled, isChatCall, contactInfoData, uid) => {
    if (dbUser && !dbUser?.stripeDefaultPMHint) {
      const booking = { perMinuteConsultant: data.userID, videoEnabled: videoEnabled, locale: data.locale, consultantName: `${data.name} ${data.surname}` }
      setBooking(booking)

      changePage({
        pathname: AUTH_ROUTES_CLIENT.PAYMENT,
        search: `fromConsultantsList=${fromConsultantsList}`
      })
      return
    }

    setLoading(true)

    startCallAsync(videoEnabled, isChatCall, contactInfoData, uid)
  }

  const pendingAmount = unpaidSession ? (calculateFakePrice(unpaidSession.data()) - calculateRealPrice(unpaidSession.data())) : 0
  
  const classes = useStyles();

  return (
    <div className={`perMinuteButtons ${config.type === 'astrologer' ? 'perMinuteButtons_astrologer' : ''}`}>
      {data.isBusy ? 
        <div className='in_call_container'>
          <p className='in_call_text'>In call...</p>
        </div>
      :
        <div className='buttons'>
          <Box className={`content ${integration && !videoAvailable ? 'display-none' : ''}`}>
            <IconButton style={{ padding: "10px 0px" }} color="primary" disabled={!videoAvailable || loading || isConsultant} onClick={tappedVideoCall}>
              {videoAvailable && !loading && (!dbUserID || !isConsultant) ? <VideoIcon /> : <Avatar style={{ width: 50, height: 50 }} src={process.env.PUBLIC_URL + `/video-unavailable.png`}></Avatar>}
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </IconButton>
            {!fromConsultantsList && <div className="label_type_3 heading">Instant video call</div>}
            <div className="label_type_4" style={{ color: videoAvailable ? '#2f455c !important' : 'lightgray' }}>
              {!!data.videoPrice && (data.videoPrice === -100 ? 'FREE' : `${formattedIntegerLocaleSeparator(data["videoPrice"], data["locale"])} ${localeToCurrency(data["locale"])}/min`)}
            </div>
          </Box>
          <div className={`separator ${(videoAvailable && (audioAvailable || chatAvailable)) || !integration ? '' : 'display-none'}`}>&nbsp;</div>
          <Box className={`content ${integration && !audioAvailable ? 'display-none' : ''}`}>
            <IconButton style={{ padding: "10px 0px" }} color="primary" disabled={!audioAvailable || loading || isConsultant} onClick={tappedAudioCall}>
              {audioAvailable && !loading && (!dbUserID || !isConsultant) ?
                <AudioIcon /> : <Avatar style={{ width: 50, height: 50 }} src={process.env.PUBLIC_URL + `/audio-unavailable.png`}></Avatar>}
                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </IconButton>
            {!fromConsultantsList && <div className="label_type_3 heading">Instant audio call</div>}
            <div className="label_type_4" style={{ color: audioAvailable ? '#2f455c !important' : 'lightgray' }}>
              {!!data.audioPrice && (data.audioPrice === -100 ? 'FREE' : `${formattedIntegerLocaleSeparator(data["audioPrice"], data["locale"])} ${localeToCurrency(data["locale"])}/min`)}
            </div>
          </Box>
          <div className={`separator ${(audioAvailable && chatAvailable) || !integration ? '' : 'display-none'}`}>&nbsp;</div>
          <Box className={`content ${integration && !chatAvailable ? 'display-none' : ''}`}>
            <IconButton className={`${chatAvailable && !loading && (!dbUserID || !isConsultant) ? '' : 'button_disable'}`} style={{ padding: "10px 0px" }} color="primary" disabled={!chatAvailable || loading || isConsultant} onClick={tappedChatCall}>
              {chatAvailable && !loading && (!dbUserID || !isConsultant) ? <ChatIcon className='chat_icon' /> : <ChatIcon className='chat_icon_disable' />}
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </IconButton>
            {!fromConsultantsList && <div className="label_type_3 heading">Instant<br />text chat</div>}
            <div className="label_type_4" style={{ color: chatAvailable ? '#2f455c !important' : 'lightgray' }}>
              {!!data.chatPrice && (data.chatPrice === -100 ? 'FREE' : `${formattedIntegerLocaleSeparator(data["chatPrice"], data["locale"])} ${localeToCurrency(data["locale"])}/min`)}
            </div>
          </Box>
        </div>
      }
      {config.type === 'astrologer' && !fromConsultantsList &&
        <div className='tax_text'>
          *All sessions are subject to a 4% HI Sales Tax that will be reflected at booking and on your final invoice
        </div>
      }
      
      {unpaidSession &&
        <UnpaidSessionDialog
          unpaidSession={unpaidSession}
          setUnpaidSession={setUnpaidSession}
          pendingAmount={pendingAmount}
          locale={data['locale']}
        />
      }

      {alertInfo.open && <ConfirmAlert data={alertInfo} />}
    </div >
  )
}

export default PerMinuteButtons;
import React, { useEffect, useState } from 'react';
import "./payment.css";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'firebase/functions'
import Button from '@material-ui/core/Button'
import { config } from '../../config/config';
import { AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from '../../utils/routes';
import { useTheme } from '@material-ui/core/styles';
import DeleteCardDialog from './DeleteCardDialog';
import CheckIcon from '@material-ui/icons/Check'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import PaymentForm from './PaymentForm';
import useCookieTheme from '../App/useCookieTheme';
import { useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';
import useBookingHook from '../ConsultantProfile/useBookingHook';

const stripePromise = loadStripe(config.stripeKey);

const CardElementStripe = (props) => {
  const {
    currentCardNumber,
    currentCardExpiryDate,
    fromConsultantBooking,
    setPaymentInfoData,
    email,
    stripeInfoId,
    setStripeInfoId
  } = props;
  const isConsultant = useSelector(userState).user?.consultant
  const { getBooking, setBooking } = useBookingHook()
  const { changePage } = useCookieTheme()
  const fromConsultantsList = JSON.parse((new URL(document.location)).searchParams.get("fromConsultantsList") ?? null)
  const isEdit = JSON.parse((new URL(document.location)).searchParams.get("isEdit") ?? null)
  const fromCallHistory = JSON.parse((new URL(document.location)).searchParams.get("fromCallHistory") ?? null)

  const [edit, setEdit] = useState(isEdit ?? false);
  const [cardLoaded, setCardLoaded] = useState(true);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const bookingFlow = getBooking()

  useEffect(() => {
    if (isConsultant) {
      changePage({
        pathname: AUTH_ROUTES_CONSULTANT.HOME
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsultant])

  const CancelButton = () => {
    return (
      <Button
        onClick={() => {
          if (bookingFlow && bookingFlow !== "null") {
            setBooking(bookingFlow)
            changePage({
              pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', bookingFlow.bookingData?.data?.consultantID)
            })
          } else {
            setEdit(false)
          }
        }} color="primary" variant="contained" className='cancel_button'>
        Cancel
      </Button>
    )
  }
  
  const themeMainColor = useTheme().palette.primary.main
  
  return (
    <>
      {!fromConsultantBooking &&
        <div className="payment_header">
          <div className="payment_label_1 heading">
            Payment Method
          </div>
          <div className="payment_label_2">
            {edit ? 'Add another valid card to update your payment method' : 'Add a valid card to set up bookings or make calls'}
          </div>
        </div>
      }

      <div className="payment_content">
        {currentCardNumber && !edit && cardLoaded ?
          <div className="payment_current_card">
            <div>
              {currentCardNumber}
            </div>
            <div>{currentCardExpiryDate ?? 'xx/xx'}</div>
            <div className="payment_check">
              <CheckIcon style={{ color: themeMainColor }} />
              <div>Active</div>
            </div>
            <div className="payment_check">
              <EditIcon style={{ color: themeMainColor, cursor: 'pointer' }} onClick={() => {
                setEdit(true)
              }} />
            </div>
            <div className="payment_check">
              <DeleteIcon style={{ color: 'red', cursor: 'pointer' }} onClick={() => setDeleteDialog(true)} />
            </div>
          </div>
        :
          <Elements stripe={stripePromise}>
            <div className={"payment_form"}>
              <PaymentForm
                fromConsultantsList={fromConsultantsList}
                fromConsultantBooking={fromConsultantBooking}
                fromCallHistory={fromCallHistory}
                email={email}
                setStripeInfoId={setStripeInfoId}
                setPaymentInfoData={setPaymentInfoData}
                setEdit={setEdit}
                edit={edit}
                setCardLoaded={setCardLoaded}
              />
            </div>
          </Elements>
        }
          
        <div className="payment_actions">
          {edit && !fromConsultantBooking ? 
            <CancelButton />
          :
            <>
              {currentCardNumber && !fromConsultantBooking &&
                <Button
                  onClick={() => changePage({
                    pathname: AUTH_ROUTES_CONSULTANT.HOME
                  })}
                  color="primary"
                  variant="contained"
                >
                  Return to Dashboard
                </Button>
              }
            </>
          }
        </div>
      </div>

      {deleteDialog &&
        <DeleteCardDialog
          handleClose={() => setDeleteDialog(false)}
          open={deleteDialog}
          stripeInfoId={stripeInfoId}
          setStripeInfoId={setStripeInfoId}
          setPaymentInfoData={setPaymentInfoData}
        />
      }
    </>
  )
};

export default CardElementStripe;

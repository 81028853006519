import React, { useState } from 'react'
import { formattedIntegerLocaleSeparator, localeToCurrency } from '../../../../utils/utils'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import firebase from 'firebase/app'
import { CircularProgress } from '@material-ui/core';

const UnpaidSessionDialog = (props) => {
  const { unpaidSession, setUnpaidSession, pendingAmount, locale } = props

  const [loadingRetry, setLoadingRetry] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const retryToPaySession = async () => {
    setLoadingRetry(true)
    firebase.functions().httpsCallable('attemptRemainingCharge')({
      session: unpaidSession.id,
      amount: pendingAmount
    }).then(() => {
      setSuccess(true)
    }).catch(() => {
      setError(true)
    }).finally(() => {
      setLoadingRetry(false)
    })
  }
  
  return (
    <Dialog
      open={Boolean(unpaidSession)}
      onClose={() => setUnpaidSession(null)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{success ? 'Successfully paid your unpaid session!' : 'You have an unpaid session!'}</DialogTitle>
      {unpaidSession && 
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {error ?
              `We couldn't process your new pay for the unpaid session. Please try again later!`
            :
              <>
                {success ?
                  ''
                :
                  `We were unable to charge you the ${formattedIntegerLocaleSeparator(pendingAmount, locale)} ${localeToCurrency(locale)} pending from your session with ${unpaidSession.data().consultantFullName ?? unpaidSession.data().consultantHandle}. You will need to complete that payment in order to make new calls.`
                }
              </>
            }
          </DialogContentText>
        </DialogContent>
      }

      <DialogActions>
        {!success && !error &&
          <Button onClick={() => retryToPaySession()} color="primary">
            {loadingRetry ? <CircularProgress size={20} /> : "Retry"}
          </Button>
        }
        <Button onClick={() => setUnpaidSession(null)} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnpaidSessionDialog;

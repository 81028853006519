import React, { useState } from 'react';
import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import GeneralTab from './GeneralTab';
import DiscountCouponsTab from './DiscountCouponsTab';
import BookedHoursTab from './BookedHoursTab';
import BookedHoursDiscountTab from './BookedHoursDiscount';
import firebase from 'firebase/app'
import { useEffect } from 'react'
import { config } from '../../../../config/config';
import { setErrorDetails } from '../../../../api/userSlice';
import { useDispatch } from 'react-redux';

const TeamTabs = () => {  
  const dispatch = useDispatch()

  const [value, setValue] = useState('1');
  const [reportData, setReportData] = useState(null)
  const [reportDiscountData, setReportDiscountData] = useState(null)

  const fetchReport = () => {
    firebase.functions().httpsCallable('getReport')().then(function (result) {
      const stats = result.data
      stats.splice(stats.length - 1, 0, {
        id: 'empty_row',
        empty: true
      })
      setReportData(stats)
    }).catch(error => {
      console.log(error)
      dispatch(setErrorDetails({
        message: `Cannot get report!`
      }))
    })
  }
  useEffect(fetchReport, [])

  const fetchDiscountReport = () => {
    if (config.type === 'astrologer') {
      firebase.functions().httpsCallable('getReport')({
        discount: true
      }).then(function (result) {
        const stats = result.data
        setReportDiscountData(stats)
      }).catch(error => {
        console.log(error)
        dispatch(setErrorDetails({
          message: `Cannot get discount report!`
        }))
      })
    }
  }
  useEffect(fetchDiscountReport, [])

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        classes={{
          flexContainer: 'tab_list',
          root: 'tab_list_root'
        }}
        onChange={handleChange}
        textColor='primary'
        indicatorColor='primary'
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="General" value="1" />
        <Tab label="Discount coupons" value="2" />
        <Tab label="Booked Hours" value="3" />
        <Tab className={config.type !== 'astrologer' ? 'hidden_tab' : ''} label="10% Discount" value="4" />
      </TabList>
      <TabPanel value="1">
        <GeneralTab />
      </TabPanel>
      <TabPanel value="2">
        <DiscountCouponsTab />
      </TabPanel>
      <TabPanel value="3">
        <BookedHoursTab reportData={reportData} />
      </TabPanel>
      <TabPanel value="4">
        <BookedHoursDiscountTab reportDiscountData={reportDiscountData} />
      </TabPanel>
    </TabContext>
  )
};

export default TeamTabs;

import React, { useEffect } from 'react';
import { Grid, TextField, useMediaQuery } from '@material-ui/core';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import DatePicker from 'react-multi-date-picker';
import { useBookingContext } from '../BookingContext';

const GiftForm = () => {
  const { giftData, setGiftData } = useBookingContext()

  useEffect(() => {
    ValidatorForm.addValidationRule('isEmailMatch', () => {
      if (giftData?.confirmEmail !== giftData?.email) {
        return false;
      }
      return true;
    });

    return () => {
      ValidatorForm.removeValidationRule('isEmailMatch')
    }
  }, [giftData])
  
  const widthLessThan600px = useMediaQuery('(max-width:599px)');

  return (
    <div className='gift_form item form_information'>
      <ValidatorForm
        className='margin_item'
        onError={errors => console.log(errors)}
        onSubmit={() => { return }}
      >
        <p className='title'>GIFTEE INFORMATION</p>
        <Grid className='gift_grid' container spacing={1}>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <TextValidator
              variant="outlined"
              size='small'
              onChange={(e) => {
                const { value } = e.target
                setGiftData(oldGiftData => ({
                  ...oldGiftData,
                  name: value
                }))
              }}
              value={giftData?.name}
              label="Giftee's Name"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <TextValidator
              validators={['isEmail']}
              errorMessages={['']}
              variant="outlined"
              size='small'
              onChange={(e) => {
                const { value } = e.target
                setGiftData(oldGiftData => ({
                  ...oldGiftData,
                  email: value
                }))
              }}
              value={giftData?.email}
              label="Giftee's Email"
              type="email"
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={widthLessThan600px ? 12 : 6}>
            <TextValidator
              validators={['isEmailMatch']}
              errorMessages={['']}
              variant="outlined"
              size='small'
              onChange={(e) => {
                const { value } = e.target
                setGiftData(oldGiftData => ({
                  ...oldGiftData,
                  confirmEmail: value
                }))
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              value={giftData?.confirmEmail}
              label="Giftee's Confirm Email"
              type="email"
              fullWidth
              required
            />
          </Grid>
          <Grid className='delivery_date' item xs={widthLessThan600px ? 12 : 6}>
            <DatePicker
              value={giftData?.deliveryDate}
              onChange={(date) => {
                setGiftData(oldGiftData => ({
                  ...oldGiftData,
                  deliveryDate: date.toDate()
                }))
              }}
              minDate={new Date()}
              placeholder='Delivery date *'
            />
          </Grid>
          <Grid className='custom_message' item xs={12}>
            <TextField
              multiline
              variant='outlined'
              label="Custom message"
              value={giftData?.customMessage}
              onChange={(e) => {
                const { value } = e.target
                setGiftData(oldGiftData => ({
                  ...oldGiftData,
                  customMessage: value
                }))
              }}
              rows={3}
            />
          </Grid>
        </Grid>
      </ValidatorForm>
    </div >
  )
};

export default GiftForm;

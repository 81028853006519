import React, { useEffect, useState } from "react"
import firebase from 'firebase/app'
import momentTimezone from 'moment-timezone'
import moment from "moment"

export const IntakeInformationContent = (props) => {
  const { bookingID } = props
  const [intakeInfo, setIntakeInfo] = useState(null)
  const [timezone, setTimezone] = useState(null)

  useEffect(() => {
    const getIntakInfo = async () => {
      const bookingData = (await firebase.app().firestore().doc(`/bookings/${bookingID}`).get()).data()      
      const clientData = (await firebase.app().firestore().doc(`/users/${bookingData.clientID}`).get()).data()

      if (clientData) {
        setIntakeInfo({
          ...bookingData.clientDetails,
          birthDate: clientData.birthInfos.birthDate.toDate(),
          address: `${clientData.birthInfos.city}, ${clientData.birthInfos.state ? `${clientData.birthInfos.state}, ` : ''}${clientData.birthInfos.country}`
        })
      } else {
        setIntakeInfo({
          ...bookingData.clientDetails,
          birthDate: bookingData.clientDetails.birthDate.toDate()
        })
      }
      setTimezone(bookingData.clientTimezone)
    }

    if (bookingID) {
      getIntakInfo()
    }
  }, [bookingID])

  const dateWithTimezone = () => {
    return momentTimezone(intakeInfo?.birthDate).tz(timezone)
  }

  return (
    <div className='intake_info_content'>
      {intakeInfo && timezone &&
        <>
          <p className='title birthday'>BirthDay:</p> {moment(dateWithTimezone()).format('MM/DD/yyyy, h:mm A')}<br />
          <p className='title birthday'>Birth place:</p> {intakeInfo?.address}
          <p className='title'>What question would you like to ask your Astrologer?</p>
          {intakeInfo?.questionsText ?? '-'}
          <p className='title'>Have you ever had a reading in the past?</p>
          {intakeInfo?.readingInThePast ?? '-'}
          <p className='title'>Anything else we should know?</p>
          {intakeInfo?.anythingElseText ?? '-'}
        </>
      }
    </div>
  )
}

import React, { useState } from "react";
import { useEffect } from "react";
import "./styles.css";
import firebase from "firebase/app";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import ConsultantItem from "./components/ConsultantItem";
import { Fragment } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { useGlobalStyles } from "../App/globalStyles";
import { useDispatch, useSelector } from "react-redux";
import { setConsultantsList, userState } from "../../api/userSlice";
import { fetchTheme, themeState } from "../../api/themeSlice";
import { defaultTheme } from "../../config/config";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIos";
import ListLoginButton from "./ListLoginButton";

const ConsultantsList = (props) => {
  const { teamID } = props;
  const dbUserID = useSelector(userState).user?.userID;
  const isConsultant = useSelector(userState).user?.consultant;
  const integration = useSelector(userState).integration;
  const isIntegrationTeam = useSelector(userState).isIntegrationTeam;
  const testIntegration = useSelector(userState).testIntegration;
  const consultantsList = useSelector(userState).consultantsList;
  const themeID = useSelector(themeState).theme?.id ?? defaultTheme.id;
  const realThemeID = useSelector(themeState).realThemeID;
  const dispatch = useDispatch();
  const history = useHistory();

  const widthLessThan800px = useMediaQuery("(max-width:799px)");
  const widthLessThan1175px = useMediaQuery("(max-width:1174px)");
  const widthLessThan1415px = useMediaQuery("(max-width:1414px)");
  const itemPerColumn = widthLessThan800px
    ? 1
    : (dbUserID && widthLessThan1415px) || (!dbUserID && widthLessThan1175px)
    ? 2
    : 3;

  const [displayedConsultants, setDisplayedConsultants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(2);
  const [pageNumber, setPageNumber] = useState(0);
  const [showingPerPage, setShowingPerPage] = useState();
  const [searchText, setSearchText] = useState(null);
  const [onlyOnline, setOnlyOnline] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setShowingPerPage(
      (dbUserID && widthLessThan1415px) || (!dbUserID && widthLessThan1175px)
        ? 10
        : 15
    );
  }, [dbUserID, widthLessThan1175px, widthLessThan1415px]);

  useEffect(() => {
    setPageNumber(0);
  }, [showingPerPage]);

  useEffect(() => {
    setShowAnimation(true);
    setTimeout(() => {
      setShowAnimation(false);
    }, 1000);
  }, [onlyOnline]);

  useEffect(() => {
    setTotalPages(displayedConsultants.length / showingPerPage);
  }, [displayedConsultants, showingPerPage]);

  useEffect(() => {
    setPageNumber(0);
    const newDisplayedConsultants = consultantsList
      .filter((consultant) => {
        let condition = !!consultant;
        if (searchText) {
          condition =
            condition &&
            ((consultant.name + " " + consultant.surname)
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
              consultant.consultancyType
                .toLowerCase()
                .includes(searchText.toLowerCase()));
        }
        if (onlyOnline) {
          condition = condition && consultant.online && !consultant.isBusy;
        }
        return condition;
      })
      .map((consultant, index) => {
        const oldIndex = displayedConsultants.indexOf(
          displayedConsultants.find((cons) => cons.userID === consultant.userID)
        );
        const newIndex = index;

        return {
          ...consultant,
          oldIndex,
          newIndex,
        };
      });
    setDisplayedConsultants(newDisplayedConsultants);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultantsList, searchText, onlyOnline]);

  useEffect(() => {
    setLoading(true);
    if (teamID || isIntegrationTeam) {
      firebase
        .firestore()
        .collection("/teams")
        .where("code", "==", teamID ?? integration)
        .get()
        .then((teams) => {
          const teamData = teams.docs[0].data();
          if (teamData) {
            firebase
              .firestore()
              .doc(`users/${teamData.masterID}`)
              .get()
              .then((res) => {
                if (teamID) {
                  const userData = res.data();
                  const newTheme =
                    testIntegration && userData.testIntegrationThemeID
                      ? userData.testIntegrationThemeID
                      : isIntegrationTeam && userData.integrationThemeID
                      ? userData.integrationThemeID
                      : userData.themeID;
                  dispatch(
                    fetchTheme({
                      newThemeID: newTheme,
                      history,
                    })
                  );
                }

                firebase
                  .functions()
                  .httpsCallable("getTeamWorkersStats")({
                    code: teamID ?? integration,
                  })
                  .then(async (res) => {
                    const stats = JSON.parse(res.data).stats;

                    const consultantListWithProfilePic = await Promise.all(
                      stats.map(async (user) => {
                        try {
                          const url = await firebase
                            .app()
                            .storage()
                            .ref(`images/${user.userID}.jpg`)
                            .getDownloadURL();
                          return {
                            ...user,
                            profilePic: url,
                          };
                        } catch {
                          return user;
                        }
                      })
                    );

                    const sortedConsultantByTimeSpend =
                      consultantListWithProfilePic.sort((a, b) => {
                        return b.timeSpentInCall - a.timeSpentInCall;
                      });
                    dispatch(setConsultantsList(sortedConsultantByTimeSpend));
                    setLoading(false);
                  });
              });
          } else {
            dispatch(setConsultantsList([]));
            setLoading(false);
          }
        });
    } else if (integration) {
      firebase
        .firestore()
        .collection("users")
        .where("code", "==", integration)
        .onSnapshot((res) => {
          const consultant = res.docs.map((consultant) => consultant.data());
          dispatch(setConsultantsList(consultant));
          setLoading(false);
        });
    } else {
      getConsultants(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamID, realThemeID, themeID]);

  const getConsultants = (withLimit) => {
    let query = firebase
      .app()
      .firestore()
      .collection("users")
      .where("consultant", "==", true)
      .where("isPublic", "==", true)
      .where("themeID", "==", realThemeID ?? themeID);

    if (onlyOnline) {
      query = query.where("online", "==", true);
      query = query.where("isBusy", "==", false);
    }
    query = query.orderBy("timeSpentInCall", "desc");
    if (withLimit) {
      query = query.limit(showingPerPage);
    }

    const unsubscribe = query.onSnapshot(async (res) => {
      let users = res.docs.map((consultant) => consultant.data());
      const goodThemeID = realThemeID ?? themeID;
      if (goodThemeID !== "lokiTheme" && goodThemeID !== "yaloopTheme") {
        users = await firebase.functions().httpsCallable("checkTeamMember")({
          themeID: goodThemeID,
          users: JSON.stringify(users),
        });
      }

      const realUsers =
        goodThemeID === "lokiTheme" || goodThemeID === "yaloopTheme"
          ? users
          : users.data;
      const consultantListWithProfilePic = await Promise.all(
        realUsers.map(async (user) => {
          try {
            const url = await firebase
              .app()
              .storage()
              .ref(`images/${user.userID}.jpg`)
              .getDownloadURL();
            return {
              ...user,
              profilePic: url,
            };
          } catch {
            return user;
          }
        })
      );

      const sortedConsultantByTimeSpend = consultantListWithProfilePic.sort(
        (a, b) => {
          return b.timeSpentInCall - a.timeSpentInCall;
        }
      );
      dispatch(setConsultantsList(sortedConsultantByTimeSpend));
      if (withLimit) {
        getConsultants();
      }
      setLoading(false);
    });

    return () => unsubscribe();
  };

  useGlobalStyles();

  return (
    <div className="consultants_list_container">
      <div
        className="search_container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TextField
          className="search"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) =>
            setSearchText(e.target.value.length > 0 ? e.target.value : null)
          }
          placeholder="Start typing a name or expertise..."
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <ListLoginButton />
      </div>
      <div className="online_checkbox_container">
        <FormControlLabel
          control={
            <Checkbox
              checked={onlyOnline}
              onChange={(e) => setOnlyOnline(e.target.checked)}
              color="primary"
            />
          }
          label={
            <p>
              <span>Show online only</span>
              {widthLessThan800px
                ? ""
                : " (instant sessions are subject to availability)"}
            </p>
          }
        />
      </div>
      {isConsultant && (
        <p className="cannot_access_consultant">
          You cannot access these services as you are logged in with a
          consultant account.
        </p>
      )}
      {loading ? (
        <div className="loading">
          <CircularProgress size={50} />
        </div>
      ) : (
        <>
          <div
            className="list_container"
            style={{
              gridTemplateColumns: widthLessThan800px
                ? "max-content"
                : (dbUserID && widthLessThan1415px) ||
                  (!dbUserID && widthLessThan1175px)
                ? "max-content max-content"
                : "max-content max-content max-content",
              width: widthLessThan800px
                ? "374px"
                : (dbUserID && widthLessThan1415px) ||
                  (!dbUserID && widthLessThan1175px)
                ? "748px"
                : "1122px",
              justifyContent:
                displayedConsultants.length > itemPerColumn
                  ? "center"
                  : "flex-start",
              gridTemplateRows: "0fr",
            }}
          >
            {displayedConsultants.length === 0 ? (
              <div
                className="no_consultants_container"
                style={{
                  width: widthLessThan800px
                    ? "320px"
                    : (dbUserID && widthLessThan1415px) ||
                      (!dbUserID && widthLessThan1175px)
                    ? "794px"
                    : "1068px",
                }}
              >
                No consultants
              </div>
            ) : (
              <>
                {displayedConsultants
                  .filter(
                    (_, index) =>
                      index >= pageNumber * showingPerPage &&
                      index < (pageNumber + 1) * showingPerPage
                  )
                  .map((consultant) => (
                    <Fragment key={consultant.userID}>
                      <ConsultantItem
                        consultant={consultant}
                        teamID={teamID}
                        onlyOnline={onlyOnline}
                        showAnimation={showAnimation}
                      />
                    </Fragment>
                  ))}
              </>
            )}
          </div>

          <div
            className="footer_container"
            style={{
              width: widthLessThan800px
                ? "320px"
                : (dbUserID && widthLessThan1415px) ||
                  (!dbUserID && widthLessThan1175px)
                ? "794px"
                : "1068px",
            }}
          >
            <div className="showing_container"></div>
            <div className="page_container">
              <div className="arrows_container">
                <div
                  className="arrow_container weird_arrow"
                  onClick={() =>
                    setPageNumber((oldPageNumber) => {
                      if (oldPageNumber > 0) {
                        return --oldPageNumber;
                      }
                      return oldPageNumber;
                    })
                  }
                >
                  <ArrowBackIcon color="primary" />
                </div>
                <div
                  className="arrow_container"
                  onClick={() =>
                    setPageNumber((oldPageNumber) => {
                      if (oldPageNumber < totalPages - 1) {
                        return ++oldPageNumber;
                      }
                      return oldPageNumber;
                    })
                  }
                >
                  <ArrowForwardIcon color="primary" />
                </div>
              </div>
              <p className="page_text">
                Page {displayedConsultants.length === 0 ? 0 : pageNumber + 1} of{" "}
                {Math.ceil(totalPages)}
              </p>
            </div>
            <div className="arrows_container showing_container">
              <p className="page_text">Showing</p>
              <TextField
                className="showing_input"
                variant="outlined"
                value={showingPerPage}
                onChange={(e) => setShowingPerPage(e.target.value)}
                select={true}
              >
                {((dbUserID && widthLessThan1415px) ||
                (!dbUserID && widthLessThan1175px)
                  ? [10, 25, 50]
                  : [15, 30, 60]
                ).map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>

          <div style={{ flex: 1 }} className="take-up-space" />
        </>
      )}
    </div>
  );
};

export default ConsultantsList;

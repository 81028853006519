import React, { useEffect, useRef, useState } from 'react';
import 'firebase/firestore'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import useCookieTheme from '../../App/useCookieTheme';
import firebase from 'firebase/app'
import moment from 'moment';
import ChatIcon from '@material-ui/icons/Chat'
import { capitalize } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { sessionState } from '../../../api/sessionSlice';

const PerMinCard = (props) => {
  const { acceptPPMCallTapped } = props
  const sessionData = useSelector(sessionState).activeSession
  const { appointmentName, name } = useCookieTheme()

  const [dynamicTab, setDynamicTab] = useState(false)
  useEffect(() => {
    const audioElement = document.getElementById('session_ring')
    if (audioElement) {
      audioElement.src = process.env.PUBLIC_URL + "/shortring.wav"
      audioElement.play()
    }

    setDynamicTab(true)
    return () => {
      const audioElement = document.getElementById('session_ring')
      if (audioElement) {
        audioElement.pause()
        audioElement.src = null
      }

      setDynamicTab(false)
    }
  }, [sessionData.id])

  const dynamicRef = useRef()
  const dynamicRefWasOn = useRef(false)
  dynamicRef.current = dynamicTab

  useEffect(() => {
    const interval = setInterval(() => {
      if (dynamicRef.current) {
        if (document.title === name) {
          document.title = `${sessionData.clientFullName} is calling`
          dynamicRefWasOn.current = true
        } else {
          if (dynamicRefWasOn.current) {
            document.title = name
          }
        }
      } else {
        if (dynamicRefWasOn.current) {
          document.title = name
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
      if (dynamicRefWasOn.current) {
        document.title = name
      }
    }
  }, [sessionData.clientFullName, name]);
  
  const [bookingDateFirstPart, setBookingDateFirstPart] = useState(null)
  const [bookingDateSecondPart, setBookingDateSecondPart] = useState(null)
  useEffect(() => {
    if (sessionData.bookingID) {
      firebase.firestore().doc('/bookings/' + sessionData.bookingID).get().then(res => {
        const startMoment = moment(res.data().startDate.toDate())
        const endMoment = moment(startMoment).add(res.data().length, 'minutes')
        const prettyStartDate = startMoment.format('MMMM Do YYYY')
        const prettyStartTime = startMoment.format('hh:mm')
        const endStart = endMoment.format('hh:mm')
        const ampm = startMoment.format('A')
        setBookingDateFirstPart(`${prettyStartDate}`)
        setBookingDateSecondPart(`${prettyStartTime} - ${endStart} ${ampm}`)
      })
    }
  }, [sessionData])

  return (
    <div className='card_call'>
      <IconButton style={{ margin: '0 auto' }} color="primary" aria-label="add to shopping cart" onClick={acceptPPMCallTapped}>
        {sessionData.isChatCall ?
          <div className='chat_icon'>
            <ChatIcon />
          </div>
        :
          <Avatar className='avatar' src={process.env.PUBLIC_URL + `/${sessionData.videoEnabled ? "video" : "audio"}-available.png`}></Avatar>
        }
        <Typography variant="h6" color="textSecondary" gutterBottom>
          TAP TO ANSWER
        </Typography>
      </IconButton>
      <Typography variant="h3" color="textSecondary" gutterBottom>
        {sessionData.clientFullName}
      </Typography>
      {sessionData.bookingID && bookingDateFirstPart &&
        <Typography variant="h4" color="textSecondary" gutterBottom>
          {appointmentName ? capitalize(appointmentName) : 'Appointment'} - {bookingDateFirstPart}<br />{bookingDateSecondPart}<br />({Intl.DateTimeFormat().resolvedOptions().timeZone})
        </Typography>
      }
      <Typography variant="h4" color="textSecondary" gutterBottom>
        {sessionData.isChatCall ? 'Text Chat Session' : sessionData.videoEnabled ? "Video call" : "Audio call"}
      </Typography>
    </div>
  )
};

export default PerMinCard;

import React, { useState } from "react";
import { config } from "../../config/config";
import { isSafari } from "react-device-detect";
import { agoraObject } from "./config";
import AgoraRTC from "agora-rtc-sdk-ng";
import firebase from "firebase/app";
import { updateSessionLog } from "../../utils/utils";
import { useDispatch } from "react-redux";
import { setErrorDetails } from "../../api/userSlice";

export default function useAgoraHook(
  videoEnabled,
  isChatCall,
  userID,
  sessionID,
  bookingID,
  isConsultant,
  shouldRecording,
  agoraClientRef,
  setIsEndingCall,
  sessionStarted
) {
  const [remoteIsJoined, setRemoteIsJoined] = useState(false);
  const [muted, setMuted] = useState(false);
  const [audioOff, setAudioOff] = useState(false);
  const [videoOff, setVideoOff] = useState(false);
  const [isRemoteVideo, setIsRemoteVideo] = useState(false);
  const [shareScreen, setShareScreen] = useState(false);
  const dispatch = useDispatch();

  const options = React.useRef({
    appID: config.agoraAppID,
    uid: undefined,
    callUUID: undefined,
    uidChannel: undefined,
    resourceId: undefined,
    sid: undefined,
  });

  const join = async (callUUID) => {
    agoraClientRef.current.on("user-joined", handleUserJoin);
    agoraClientRef.current.on("user-published", handleUserPublished);
    agoraClientRef.current.on("user-unpublished", handleUserUnpublished);
    agoraClientRef.current.on("user-left", () => leave(false, true));

    if (!isChatCall) {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: videoEnabled })
        .then((mediaStreams) => {
          const audioTrack = mediaStreams.getAudioTracks()[0];
          if (audioTrack) {
            audioTrack.stop();
          }
          const videoTrack = mediaStreams.getVideoTracks()[0];
          if (videoTrack) {
            videoTrack.stop();
          }
          if (agoraObject.remoteUser && !options.current.uid) {
            joinUser(callUUID);
          }
        });
    }

    joinUser(callUUID);
  };

  const joinUser = async (callUUID) => {
    try {
      options.current.callUUID = callUUID;
      options.current.uidChannel = callUUID
        .match(/\d/g)
        .join("")
        .slice(0, 7)
        .toString();
      options.current.uid = await agoraClientRef.current.join(
        options.current.appID,
        callUUID,
        null
      );
    } catch (e) {
      console.log("Nicc:");
      console.error(e);
    }
    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        userID ? (isConsultant ? "consultant" : "client") : "guest"
      } joined`
    );

    try {
      if (!isChatCall) {
        agoraObject.localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({ bypassWebAudio: true });
        await agoraClientRef.current.publish(agoraObject.localTracks.audioTrack);
      }
      if (agoraObject.localTracks.audioTrack) {
        if (isSafari && !muted) {
          // This fixes a safari bug, sometimes if the consultant is on safari, the client can't hear him
          // Until he mutes / unmutes. This seems to fix that issue with a 1-2 second delay
          setTimeout(async function () {
            await agoraObject.localTracks.audioTrack.setEnabled(false);
            await new Promise((r) => setTimeout(r, 1000));
            await agoraObject.localTracks.audioTrack.setEnabled(true);
          }, 1000);
        }
      }

      updateSessionLog(
        sessionID,
        `[SESSION - ${sessionID}] ${new Date()} - ${
          userID ? (isConsultant ? "consultant" : "client") : "guest"
        } published audio`
      );
    } catch (e) {
      if (e.code === "PERMISSION_DENIED") {
        setMuted(true);
        setAudioOff(true);
      }
    }

    if (videoEnabled) {
      try {
        agoraObject.localTracks.videoTrack =
          await AgoraRTC.createCameraVideoTrack({
            encoderConfig: "1080p_1",
          });
        await agoraClientRef.current.publish(
          agoraObject.localTracks.videoTrack
        );

        updateSessionLog(
          sessionID,
          `[SESSION - ${sessionID}] ${new Date()} - ${
            userID ? (isConsultant ? "consultant" : "client") : "guest"
          } published video`
        );
      } catch (e) {
        if (e.code === "PERMISSION_DENIED") {
          setVideoOff(true);
        }
      }
    }

    await firebase
      .firestore()
      .doc(`/sessions/${sessionID}`)
      .update(
        isConsultant
          ? {
              consultantAgoraUID: options.current.uid,
            }
          : {
              clientAgoraUID: options.current.uid,
            }
      );
  };

  const handleUserJoin = async (user) => {
    agoraObject.remoteUser = user;
    setRemoteIsJoined(true);
    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        isConsultant ? "client" : "consultant"
      } joined with ${
        userID ? (isConsultant ? "consultant" : "client") : "guest"
      }`
    );

    if (shouldRecording.current && !isChatCall) {
      await startRecording(user.uid);
    }
  };

  const handleUserPublished = async (user, mediaType) => {
    agoraObject.remoteUser = user;
    setRemoteIsJoined(true);
    await subscribe(user, mediaType);
    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        isConsultant ? "client" : "consultant"
      } published ${mediaType} with ${
        userID ? (isConsultant ? "consultant" : "client") : "guest"
      }`
    );
  };

  const handleUserUnpublished = async (user, mediaType) => {
    setIsRemoteVideo(user.hasVideo);
    if (isChatCall) {
      setShareScreen(false);
    }
    await agoraClientRef.current.unsubscribe(user, mediaType);
    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        isConsultant ? "client" : "consultant"
      } unpublished ${mediaType} with ${
        userID ? (isConsultant ? "consultant" : "client") : "guest"
      }`
    );
  };

  const subscribe = async (user, mediaType) => {
    await agoraClientRef.current.subscribe(user, mediaType);
    if (mediaType === "audio" && sessionStarted.current) {
      user.audioTrack.play();
    }
    if (mediaType === "video") {
      setIsRemoteVideo(true);
      if (sessionStarted.current) {
        if (user.videoTrack) {
          user.videoTrack.play(
            isChatCall ? "chat_call_share_screen" : "remoteVideo"
          );
          if (isChatCall) {
            setShareScreen(true);
          }
        }
      }
    }
  };

  const stopCallSync = () => {
    setIsEndingCall(true);
    setRemoteIsJoined(false);
    setIsRemoteVideo(false);

    for (let trackName in agoraObject.localTracks) {
      var track = agoraObject.localTracks[trackName];
      if (track) {
        track.stop();
        track.close();
        agoraObject.localTracks[trackName] = null;
        updateSessionLog(
          sessionID,
          `[SESSION - ${sessionID}] ${new Date()} - ${
            userID ? (isConsultant ? "consultant" : "client") : "guest"
          } stopped ${trackName}`
        );
      }
    }

    if (agoraObject.remoteUser?.audioTrack) {
      agoraObject.remoteUser.audioTrack.stop();
      agoraObject.remoteUser.audioTrack.close();
      updateSessionLog(
        sessionID,
        `[SESSION - ${sessionID}] ${new Date()} - ${
          userID ? (isConsultant ? "consultant" : "client") : "guest"
        } stopped ${isConsultant ? "client" : "consultant"}'s audioTrack`
      );
    }
    if (agoraObject.remoteUser?.videoTrack) {
      agoraObject.remoteUser.videoTrack.stop();
      agoraObject.remoteUser.videoTrack.close();
      updateSessionLog(
        sessionID,
        `[SESSION - ${sessionID}] ${new Date()} - ${
          userID ? (isConsultant ? "consultant" : "client") : "guest"
        } stopped ${isConsultant ? "client" : "consultant"}'s videoTrack`
      );
    }

    agoraObject.remoteUser = null;
  };

  const leave = async (shouldRecording, fromListener) => {
    if (!isConsultant && shouldRecording.hasOwnProperty("current")) {
      await stopRecording(shouldRecording);
    }

    await agoraClientRef.current.leave();
    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        userID ? (isConsultant ? "consultant" : "client") : "guest"
      } leave ${fromListener ? "from agora event" : ""}`
    );

    if (!fromListener) {
      const sessionLog = localStorage.getItem(sessionID);
      if (sessionLog) {
        await firebase.app().functions().httpsCallable("saveSessionLog")({
          sessionID,
          text: sessionLog,
          isConsultant: isConsultant,
        });
        localStorage.removeItem(sessionID);
      }
    }
  };

  const startRecording = async (remoteuserUid) => {
    const response = await firebase.functions().httpsCallable("startRecording")(
      {
        options: options.current,
        userID: userID,
        remoteUserUid: remoteuserUid,
        bookingID,
        sessionID,
      }
    );

    options.current.resourceId = response.data?.resourceId;
    options.current.sid = response.data?.sid;

    localStorage.setItem("optionsRecording", JSON.stringify(options.current));

    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        userID ? "client" : "guest"
      } started recording`
    );
  };

  const stopRecording = async (shouldRecording) => {
    if (shouldRecording) {
      const savedOptions = JSON.parse(
        localStorage.getItem("optionsRecording") ?? null
      );
      await firebase.functions().httpsCallable("stopRecording")({
        options: savedOptions,
      });
    }
    localStorage.removeItem("optionsRecording");

    updateSessionLog(
      sessionID,
      `[SESSION - ${sessionID}] ${new Date()} - ${
        userID ? "client" : "guest"
      } stopped recording`
    );
  };

  const speakerTapped = async () => {
    if (!audioOff) {
      const newV = !muted;
      setMuted(newV);
      if (agoraObject.localTracks.audioTrack) {
        await agoraObject.localTracks.audioTrack.setEnabled(!newV);
      }

      updateSessionLog(
        sessionID,
        `[SESSION - ${sessionID}] ${new Date()} - ${
          userID ? (isConsultant ? "consultant" : "client") : "guest"
        } change audio to ${newV ? "off" : "on"}`
      );
    }
  };

  const screenShareTapped = async (value) => {
    const newV = value ?? !shareScreen;

    if (newV) {
      try {
        agoraObject.localTracks.screenTrack =
          await AgoraRTC.createScreenVideoTrack({
            encoderConfig: "1080p_1",
            screenSourceType: "screen",
            optimizationMode: "detail",
          });

        if (videoEnabled && agoraObject.localTracks.videoTrack) {
          await agoraClientRef.current.unpublish(
            agoraObject.localTracks.videoTrack
          );
          agoraObject.localTracks.videoTrack.stop();
          agoraObject.localTracks.videoTrack.close();
          agoraObject.localTracks.videoTrack = null;

          updateSessionLog(
            sessionID,
            `[SESSION - ${sessionID}] ${new Date()} - ${
              userID ? (isConsultant ? "consultant" : "client") : "guest"
            } unpublished video`
          );
        }

        await agoraClientRef.current.publish(
          agoraObject.localTracks.screenTrack
        );

        agoraObject.localTracks.screenTrack.play(
          isChatCall ? "chat_call_share_screen" : "localVideo"
        );
        agoraObject.localTracks.screenTrack.on("track-ended", async () => {
          await screenShareTapped(false);
        });
        setShareScreen(newV);

        updateSessionLog(
          sessionID,
          `[SESSION - ${sessionID}] ${new Date()} - ${
            userID ? (isConsultant ? "consultant" : "client") : "guest"
          } published screen share`
        );
      } catch (e) {
        if (e.code !== "PERMISSION_DENIED") {
          dispatch(
            setErrorDetails({
              message: "Share screen is not supported!",
            })
          );
        } else {
          dispatch(
            setErrorDetails({
              message:
                "Screen Sharing permission denied. Please allow screen sharing permission in both your browser and your operating system.",
            })
          );
        }
        setShareScreen(false);
      }
    } else {
      if (agoraObject.localTracks.screenTrack) {
        await agoraClientRef.current.unpublish(
          agoraObject.localTracks.screenTrack
        );
        agoraObject.localTracks.screenTrack.stop();
        agoraObject.localTracks.screenTrack.close();
        agoraObject.localTracks.screenTrack = null;

        updateSessionLog(
          sessionID,
          `[SESSION - ${sessionID}] ${new Date()} - ${
            userID ? (isConsultant ? "consultant" : "client") : "guest"
          } unpublished screen share`
        );
      }

      if (videoEnabled) {
        try {
          agoraObject.localTracks.videoTrack =
            await AgoraRTC.createCameraVideoTrack({
              encoderConfig: "1080p_1",
            });
          await agoraClientRef.current.publish(
            agoraObject.localTracks.videoTrack
          );
          agoraObject.localTracks.videoTrack.play("localVideo");

          updateSessionLog(
            sessionID,
            `[SESSION - ${sessionID}] ${new Date()} - ${
              userID ? (isConsultant ? "consultant" : "client") : "guest"
            } published video`
          );
        } catch (e) {
          if (e.code === "PERMISSION_DENIED") {
            setVideoOff(true);
          }
        }
      }
      setShareScreen(newV);
    }
  };

  return {
    remoteIsJoined,
    muted,
    audioOff,
    videoOff,
    isRemoteVideo,
    shareScreen,
    join,
    stopCallSync,
    leave,
    speakerTapped,
    screenShareTapped,
  };
}

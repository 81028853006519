import React from 'react'
import 'firebase/functions'
import { config } from '../../../../config/config';
import useCookieTheme from '../../../App/useCookieTheme';

const CongratulationsBookingSent = () => {
  const { consultantTitle, appointmentName } = useCookieTheme()

  return (
    <div className='item'>
      <div className='margin_item'>
        <p className='congrats_title'>CONGRATULATIONS! YOUR {appointmentName ? appointmentName.toUpperCase() : 'APPOINTMENT'} REQUEST HAS BEEN SENT!</p>
        <div className='astrologer_text heading'>
          Your {appointmentName ?? 'appointment'} request has been sent to your {consultantTitle} and you should receive a confirmation within 24 hours, at which point your card will be charged and your {appointmentName ?? 'appointment'} will be confirmed.
          {config.type === 'astrologer' && 
            <>
              <br /><br />
              Please look out for emails from ac@astrologyhub.com for updates about your reading request and information to prepare for your reading.
            </>
          }
        </div>
      </div>
    </div>
  )
}

export default CongratulationsBookingSent;

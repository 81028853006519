import React, { useState } from 'react';
import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import PersonalDetailsTab from './PersonalDetailsTab';
import PricingTab from './PricingTab';
import DiscountCouponsTab from './DiscountCouponsTab';
import BookingsScheduleTab from './BookingsScheduleTab';
import { useSelector } from 'react-redux';
import { userState } from '../../../api/userSlice';

const ProfileTabs = (props) => {
  const { setNotSetPerMin, setNotSetBookings } = props
  const isTeamMember = useSelector(userState).isTeamMember
  const isTeamMaster = useSelector(userState).isTeamMaster

  const [value, setValue] = useState('1');

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList
        classes={{
          flexContainer: 'tab_list',
          root: 'tab_list_root'
        }}
        onChange={handleChange}
        textColor='primary'
        indicatorColor='primary'
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Personal Details" value="1" />
        <Tab label="Pricing" value="2" />
        <Tab className={isTeamMember || isTeamMaster ? 'hidden_tab' : ''} label="Discount Coupons" value="3" />
        <Tab label="Availability" value="4" />
      </TabList>
      <TabPanel value="1">
        <PersonalDetailsTab />
      </TabPanel>
      <TabPanel value="2">
        <PricingTab setNotSetPerMin={setNotSetPerMin} setNotSetBookings={setNotSetBookings} />
      </TabPanel>
      <TabPanel value="3">
        <DiscountCouponsTab />
      </TabPanel>
      <TabPanel value="4">
        <BookingsScheduleTab />
      </TabPanel>
    </TabContext>
  )
};

export default ProfileTabs;

import React, { useEffect, useState } from "react";
import "./styles.css";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import Box from "@material-ui/core/Box";
import BookingCard from "./components/bookingCard/BookingCard";
import CircularProgress from "@material-ui/core/CircularProgress";
import Footer from "../SignIn/footer";
import { Link, Button, useMediaQuery } from "@material-ui/core";
import copy from "copy-to-clipboard";
import ManageTeam from "../Navigation/ManageTeam";
import WithdrawDialog from "./components/WithdrawDialog/WithdrawDialog";
import { config } from "../../config/config";
import useCookieTheme from "../App/useCookieTheme";
import WarningIcon from '@material-ui/icons/Warning'
import { AUTH_ROUTES_CLIENT, AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from "../../utils/routes";
import ConfirmAlert from "../components/ConfirmAlert";
import { useSelector } from "react-redux";
import { userState } from "../../api/userSlice";
import { sessionState } from "../../api/sessionSlice";
import useBookingHook from "../ConsultantProfile/useBookingHook";

const Home = () => {
  const dbUser = useSelector(userState).user
  const userID = useSelector(userState).user?.userID
  const isConsultant = useSelector(userState).user?.consultant
  const isAboutMe = Boolean(dbUser?.aboutMe)
  const isServiceDetails = Boolean(dbUser?.serviceDetails)
  const isTeamMember = useSelector(userState).isTeamMember
  const isTeamMaster = useSelector(userState).isTeamMaster
  const showWithdraw = !isTeamMember && !isTeamMaster
  const integration = useSelector(userState).integration
  const currentBookingID = useSelector(sessionState).currentBookingID
  const { consultantTitle, name, appointmentName, changePage } = useCookieTheme()
  const { getBooking } = useBookingHook()
  const bookingFlow = getBooking()

  if (bookingFlow && bookingFlow !== "null") {
    changePage({
      pathname: COMMON_ROUTES.CONSULTANT_BOOKING.replace(':consultant', bookingFlow.perMinuteConsultant ?? bookingFlow.data?.consultantID),
    })
  }

  const [rawBookings, setRawBookings] = useState([]);
  const [allBookingsLoaded, setAllBookingsLoaded] = useState(false);
  const [reloadBookings, setReloadBookings] = useState(false);
  const [withdrawDialog, setWithdrawDialog] = useState(false);
  const [alertInfo, setAlertInfo] = useState({ open: false })

  const widthLessThan850px = useMediaQuery('(max-width:850px)');
  const widthLessThan700px = useMediaQuery('(max-width:699px)');

  const appName = name
  const showNB = !rawBookings.length && allBookingsLoaded;

  useEffect(() => {
    if (!userID) {
      return
    }
    
    const currDate = new Date()
    const newDate = new Date(currDate.setMinutes(currDate.getMinutes() - 90))
    const toRet = firebase
      .firestore()
      .collection("/bookings")
      .where("status", "in", ["accepted", "pending", "processing"])
      .where(isConsultant ? "consultantID" : "clientID", "==", userID)
      .where("startDate", ">", newDate)
      .orderBy("startDate", "asc")
      .onSnapshot((snap) => {
        const allDocs = snap.docs;
        let allDatas = allDocs.map((each) => each.data());
        allDatas = allDatas.filter(each => {
          const startLength = new Date(each.startDate.seconds * 1000).getTime() + each.length * 60 * 1000
          return startLength > new Date().getTime()
        })
        allDatas = allDatas.filter(each => each.status === "accepted" || ((each.status === "pending" || each.status === "processing") && new Date(each.startDate.seconds * 1000).getTime() > new Date()))
        console.log(`Loaded ${allDatas.length} bookings.`);
        if (allDatas.length > 0) {
          setRawBookings(allDatas);
        } else {
          setRawBookings([]);
        }
        setAllBookingsLoaded(true);
        setReloadBookings(false)
      });

    return () => {
      toRet();
    };
  }, [isConsultant, reloadBookings, userID]);

  const buildBooking = (booking, index) => {
    return (
      <BookingCard
        key={index}
        variant="home"
        isClient={!isConsultant}
        data={booking}
        setAllBookingsLoaded={setAllBookingsLoaded}
        isCurrentBooking={booking.bookingID === currentBookingID}
        setAlertInfo={setAlertInfo}
        setReloadBookings={setReloadBookings}
      />
    )
  }

  return (
    <div className={`home_container ${Boolean(integration) ? 'home_container_integration' : ''}`}>
      <div className="home">
        {isConsultant && widthLessThan850px &&
          <div className="navigation_zone2 navigation_zone2_home">
            <ManageTeam
              showWithdraw={showWithdraw}
              setWithdrawDialog={setWithdrawDialog}
            />
          </div>
        }
        {isConsultant && (!isAboutMe || !isServiceDetails) &&
          <div className="no_details_container">
            <div>
              {!isAboutMe && !isServiceDetails ? '50%' : '75%'} profile completion
            </div>
            <div className="no_details">
              {!isAboutMe &&
                <div className="warning_no_details">
                  <WarningIcon className="warning_icon" />
                  <Link
                    className="no_details_button"
                    onClick={() => changePage({
                      pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', userID)
                    })}>
                      Tell us more about you!
                  </Link>
                </div>
              }
              {!isServiceDetails &&
                <div className="warning_no_details">
                  <WarningIcon className="warning_icon" />
                  <Link
                    className="no_details_button"
                    onClick={() => changePage({
                      pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', userID)
                    })}>
                      Describe your services!
                  </Link>
                </div>
              }
            </div>
          </div>
        }
        {showWithdraw && !widthLessThan850px && isConsultant &&
          <Button
            className="withdraw_button"
            variant="outlined"
            color="primary"
            onClick={() => setWithdrawDialog(true)}>
              Withdraw
          </Button>
        }
        {!rawBookings.length && showNB &&
          <div className={`home_img_container ${!isAboutMe || !isServiceDetails ? 'home_img_container_small' : ''} ${config.type === 'astrologer' ? 'astrologer_home_img_container' : ''}`}>
            <img 
              className={`home_img ${!isAboutMe || !isServiceDetails ? 'home_img_small' : ''}`}
              alt=""
              src={isConsultant ? require("../../images/app_dev_consultant.png") : config.type === 'astrologer' ? require("../../images/astrologer_dashboard_client.png") : require("../../images/seo_social_media.svg")}
            />
          </div>
        }

        {!allBookingsLoaded && (
          <div style={{ display: "flex", justifyContent: "center", alignItems: 'center', marginTop: '200px' }}>
            <CircularProgress size={50} />{" "}
          </div>
        )}

        <Box className="home_info">
          {
            <>
              {isConsultant ? (
                <>
                  {showNB && (
                    <>
                      <div className="label_type_2 heading">
                        This is your {appName} profile link:{"  "}
                      </div>
                      <Link className="label_type_4" target="_blank" href={dbUser.profileLink}>
                        {dbUser.profileLink}
                      </Link>
                      {widthLessThan700px ? (
                        <div className="label_type_3">
                          Share it with your clients or<br />
                          promote it on your website and social media<br />
                          so people can start using your paid services
                        </div>
                      ) : (
                        <div className="label_type_3">
                          Share it with your clients or promote it on your website and<br />
                          social media so people can start using your paid services
                        </div>
                      )}
                      <Button 
                        className="home_copy_link_btn"
                        onClick={() => {
                          copy(dbUser.profileLink);
                        }}
                        variant="contained"
                        color="primary"
                        size="large"
                      >
                        Copy link
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {showNB && (
                    <>
                      <div className="label_type_2">
                        {config.type === 'astrologer' ? 'You currently don\'t have any readings booked' : 'No future bookings!'}
                      </div>
                      {config.type !== 'yaloop' && <Button
                        className='make_appointment_button book_next_reading'
                        size='small'
                        onClick={() => changePage({
                          pathname: COMMON_ROUTES.LIST
                        })}
                        color="primary"
                        variant="contained"
                      >
                        Click here to book your next {appointmentName ?? 'appointment'}
                      </Button>}
                      {widthLessThan700px ? (
                        <div className="label_type_3">
                          Appointments to your favorite {consultantTitle}s<br />
                          will be shown here<br />
                          as soon as you book them
                        </div>
                      ) : (
                        <div className="label_type_3">
                          Appointments to your favorite {consultantTitle}s will be shown here<br />
                          as soon as you book them
                        </div>
                      )}
                      {config.type !== 'astrologer' &&
                        <Button
                          className="home_btn_setup_payment"
                          onClick={() => {
                            changePage({
                              pathname: AUTH_ROUTES_CLIENT.PAYMENT
                            })
                          }}
                          variant="outlined"
                          color="primary"
                          size="large"
                        >
                          {dbUser?.stripeDefaultPMHint ? `${dbUser.stripeDefaultPMHint} | Change` : 'Setup Payment Method'}
                        </Button>
                      }
                    </>
                  )}
                </>
              )}

              <div className='home_bookings'>
                <>
                  {allBookingsLoaded && rawBookings.length > 0 &&
                    <>
                      <div className="label_bookings heading">Here are your future bookings:</div>
                      <div className="bookings_content">
                        {rawBookings.map(buildBooking)}
                      </div>
                    </>
                  }
                </>
              </div>
        
              {config.type !== 'astrologer' && !integration &&
                <div className='home_footer'>
                  <Footer specialCase={true} isConsultant={isConsultant} />
                </div>
              }
            </>
          }
        </Box>

        </div >

        {withdrawDialog && 
          <WithdrawDialog
            open={withdrawDialog}
            handleClose={() => setWithdrawDialog()}
          />
        }

        {alertInfo.open && <ConfirmAlert data={alertInfo} />}
    </div>
  );
};

export default Home;

export const config =  {
  type: 'loki',
  webSite: 'https://web.lokiapp.live',
  apiKey: "AIzaSyCYL9IfpvdS0IIAthXXyd1NDegrRLEYhrg",
  authDomain: "consulting-a3065.firebaseapp.com",
  databaseURL: "https://consulting-a3065.firebaseio.com",
  cloudFunctionsURL: "https://us-central1-consulting-a3065.cloudfunctions.net/",
  projectId: "consulting-a3065",
  storageBucket: "consulting-a3065.appspot.com",
  messagingSenderId: "12210848425",
  appId: "1:12210848425:web:ad81e195c09c0d8e161fb8",
  measurementId: "G-93L6HN1W60",
  vapidKey: "BDxskIg-JhGBYjbcxaI-SEtPzyAjCjk4D4GlwJphqEjUEnilr6fTzsps-a5ptpYbr-UGnfRwQ-ctwgkBqF_B5yQ",
  stripeKey: "pk_live_IDYcv4QarnLJr3INNlpmBkxs00vr1lwnfM",
  agoraAppID: "49045b575c5944e7aae41a8eca19906d",
  title: "Loki Web",
}

export const defaultTheme = {
  id: 'lokiTheme',
  mainColor: '#34D980',
  backgroundColor: '#f3f9fb',
  appName: 'Loki',
  appConsultantProfileTitle: 'Loki',
  appConsultantProfileDescription: 'Online Consultancy Platform',
  consultantTitle: 'consultant',
  employeeTitle: 'employee',
  giftButtonName: 'Gift A Session',
  menu: {
    dashboard: "Get Started &<br />Upcoming Appointments",
    history: "History",
    profile: "Profile",
    consultantsList: "Consultants"
  },
  bookingEnableName: 'booking',
  logo: 'https://firebasestorage.googleapis.com/v0/b/consulting-a3065.appspot.com/o/logos%2Flogo.png?alt=media&token=ab8b0eb5-7c21-4f27-97a4-ff7c451bf100',
  universalLink: 'https://lokiapp.page.link/42nw',
  domainStart: "https://lokiapp.page.link"
}

export const teamMasters = null

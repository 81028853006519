import React, { useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import "./payment.css";
import 'firebase/functions'
import { usePaymentStyles } from '../App/globalStyles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import CardElementStripe from './CardElementStripe';
import { AUTH_ROUTES_CLIENT, AUTH_ROUTES_CONSULTANT } from '../../utils/routes';
import useCookieTheme from '../App/useCookieTheme';
import { useSelector } from 'react-redux';
import { userState } from '../../api/userSlice';

const PaymentPage = () => {
  const dbUser = useSelector(userState).user
  const isConsultant = useSelector(userState).user?.consultant
  const currentCardNumber = dbUser?.stripeDefaultPMHint
  const currentCardExpiryDate = dbUser?.stripeDefaultPMExpiryDate
  const { changePage } = useCookieTheme()

  useEffect(() => {
    if (isConsultant) {
      changePage({
        pathname: AUTH_ROUTES_CONSULTANT.HOME
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsultant])
  
  usePaymentStyles()
  
  return (
    <div className="payment">
      <IconButton className="signin_btn_back" onClick={() => changePage({
        pathname: AUTH_ROUTES_CLIENT.ACCOUNT_DETAILS
      })}>
        <KeyboardArrowLeftIcon />
      </IconButton>

      <CardElementStripe
        currentCardNumber={currentCardNumber}
        currentCardExpiryDate={currentCardExpiryDate}
      />
    </div >
  )
};

export default PaymentPage;

import { Button, Dialog, DialogContent } from "@material-ui/core";
import React from "react";
import firebase from "firebase";
import LoginComponent from "../SignIn/components/LoginComponent";

export default function ListLoginButton() {
  const isLoggedIn = firebase.auth().currentUser !== null;
  const [openDialog, setOpenDialog] = React.useState(false);

  if (isLoggedIn) {
    return null;
  }

  return (
    <>
      <Button
        onClick={() => setOpenDialog(true)}
        variant="contained"
        style={{ marginLeft: "20px" }}
      >
        Login
      </Button>
      <LoginDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
    </>
  );
}

const LoginDialog = (props) => {
  const { openDialog, setOpenDialog } = props;

  const saveBookingData = async () => {
    setOpenDialog(false);
  };

  return (
    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogContent className="login_dialog_content">
        <LoginComponent
          widgetLogin={true}
          fromConsultantBooking={true}
          setOpenDialog={setOpenDialog}
          saveBookingData={saveBookingData}
        />
      </DialogContent>
    </Dialog>
  );
};

import React, { useState } from 'react';
import './styles.css';
import { Button, Dialog, DialogActions, DialogContent, useMediaQuery } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import PropTypes from 'prop-types';
import firebase from 'firebase';
import HistoryRow from '../HistoryRow/HistoryRow'
import HistoryWithdrawalRow from '../HistoryWithdrawal/HistoryWithdrawalRow'
import { config } from '../../../../config/config';

const HistoryPopup = (props) => {
    const functions = firebase.functions()

    const [open, setOpen] = useState(false);
    const [isFetching, setIsFetching] = useState(true)
    const [moreToFetch, setMoreToFetch] = useState(true)
    const [dataSource, setDataSource] = useState([])
    
    const widthLessThan650px = useMediaQuery('(max-width:649px)');
    const widthLessThan450px = useMediaQuery('(max-width:449px)');

    const handleClickOpen = () => {
        setOpen(true);
        fetchConsultantHistory(props.memberData.consultantID)
    };

    const handleClose = () => {
        setOpen(false);
        setIsFetching(false)
        setDataSource([])
        setMoreToFetch(true)
    };

    const fetchConsultantHistory = (consultantID, lastDocumentId) => {
        setIsFetching(true)

        const toSend = { consultantID: consultantID }

        functions.httpsCallable('getConsultantHistory')(toSend)
            .then((result) => {
                const resData = JSON.parse(result.data)
                setMoreToFetch(resData.moreToFetch)
                const newDataSource = dataSource.concat(resData.sessionsWithWithdrawals)
                setDataSource(newDataSource)
                setIsFetching(false)
            })
    }

    const scrollCheck = () => {
        if (isFetching) {
            console.log("Already fetching...")
            return
        }

        if (dataSource.length > 0 && moreToFetch) {
            const lastDocument = dataSource[dataSource.length - 1]
            fetchConsultantHistory(props.memberData.consultantID, lastDocument["sessionID"])
        }
    };

    function renderRow(data, index) {
        if (data.callUUID || data.bookingID) {
            return (
                <ListItem key={index}>
                    <div id="history-row">
                        <HistoryRow id="history-row" data={data}></HistoryRow>
                    </div>
                    <Divider light width="100%" />
                </ListItem>
            );
        } else {
            return (
                <ListItem key={index}>
                    <div id="history-row">
                        <HistoryWithdrawalRow id="history-row" data={data}></HistoryWithdrawalRow>
                    </div>
                    <Divider light width="100%" />
                </ListItem>
            );
        }
    }

    renderRow.propTypes = {
        index: PropTypes.number.isRequired,
        style: PropTypes.object.isRequired
    };

    const themeMainColor = useTheme().palette.primary.main
    const useStyles = makeStyles({
        historyButton: {
            padding: '3px 15px',
            height: widthLessThan450px ? 17 : widthLessThan650px ? 20 : 25,
            fontSize: widthLessThan450px ? 7 : 11,
            textTransform: 'none',
            marginRight: 10,
            backgroundColor: themeMainColor,
            color: "#FFFFFF",
            "&:hover": {
                backgroundColor: themeMainColor,
            }
        },
        loadMoreButton: {
            color: themeMainColor,
        }
    });

    const styleClasses = useStyles();

    return (
        <div>
            <Button variant="contained" className={styleClasses.historyButton} onClick={handleClickOpen}>
                History
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogContent className='history_content'>
                    <div className='legend'>
                        <p>{config.type === 'astrologer' ? 'R = Reading' : 'B = Booking'}</p>
                        <p>I = Instant Call</p>
                    </div>
                    <Divider light width="100%" />
                    {dataSource.map(renderRow)}
                    {isFetching ?
                        <div className='loading_container'>
                            <CircularProgress size={30} />
                        </div>
                    :
                        <>
                            {!dataSource.length ? 
                                <div style={{display: 'flex', justifyContent: 'center', marginTop: 20}}>No history</div>
                                :
                                <>
                                    {moreToFetch &&
                                        <div className={`load_more ${styleClasses.loadMoreButton}`} onClick={scrollCheck}>
                                            Load more...
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default HistoryPopup
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import EditIcon from '@material-ui/icons/Edit'
import firebase from 'firebase';
import ConfirmAlert from '../../components/ConfirmAlert'

const TeamNameDialog = (props) => {
  const functions = firebase.functions()

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [isUpdating, setIsUpdating] = useState(false)
  const [alertInfo, setAlertInfo] = useState({ open: false })

  const handleClickOpen = () => {
    setName(props.name)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setIsUpdating(true)
    functions.httpsCallable('updateTeamName')({ name: name })
      .then(function (result) {
        console.log("Name updated!")
        props.nameUpdated(name)
      })
      .catch(error => {
        setAlertInfo({ open: true, title: "Error", description: error.message, onConfirm: hideAlert })
      })
      .finally(() => {
        setOpen(false);
        setIsUpdating(false)
      });
  }

  const hideAlert = () => {
    setAlertInfo(oldAlertInfo => ({
      ...oldAlertInfo,
      open: false
    }))
  }

  return (
    <div>
      {alertInfo.open && <ConfirmAlert data={alertInfo} />}
      <Button className='button_edit' variant="outlined" color="primary" onClick={handleClickOpen}>
        <EditIcon />
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogContent>
          <DialogContentText>
            Enter your new team name
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Team Name"
            type="text"
            fullWidth
            value={name}
            onChange={(e) => { setName(e.target.value) }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button disabled={isUpdating} onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default TeamNameDialog
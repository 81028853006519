import React from 'react';
import "./country.css";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countries } from '../../../../utils/countries'
import { useMediaQuery } from '@material-ui/core';

export default function Country(props) {
  const heightLessThan1050px = useMediaQuery('(max-height:1049px)');
  const heightLessThan900px = useMediaQuery('(max-height:899px)');
  const heightLessThan720px = useMediaQuery('(max-height:719px)');

  const select = (_, e) => {
    props.countrySelected(e);
  }

  return (
    <Autocomplete
      sx={{ width: 300 }}
      options={countries}
      value={props.selectedCountry}
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={select}
      renderOption={(option) => (
        <div className='country'>
          <div className='country_img'>
            <img
              loading="lazy"
              width={heightLessThan720px ? '15' : heightLessThan900px ? '20' : "25"}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
          </div>
          <div className='country_label'>
            {option.label}
          </div>
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          className={props.label ? '' : 'countries_textfield'}
          style={{ backgroundColor: 'white', borderRadius: 10, marginTop: props.label ? 8 : 0 }}
          size={heightLessThan1050px ? 'small' : 'medium'}
          variant={props.variant ?? 'standard'}
          InputLabelProps={heightLessThan720px ? {style: {fontSize: 12}} : heightLessThan900px ? {style: {fontSize: 14}} : undefined}
        />
      )}
    />
  );
}
import React from 'react'
import 'firebase/functions'
import BookingReviewDetails from './BookingReviewDetails';
import { useHistory } from 'react-router-dom';
import ContinueButton from './ContinueButton';
import { useState } from 'react';
import SignInDialog from './SignInDialog';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import { config } from '../../../../config/config';
import { useBookingContext } from '../BookingContext';
import useCookieTheme from '../../../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorDetails, userState } from '../../../../api/userSlice';

const YoureAllSet = (props) => {
  const { isGift, rescheduleBookingID } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const testIntegration = useSelector(userState).testIntegration
  const userID = useSelector(userState).user?.userID
  const isSignUpInBookingProcess = useSelector(userState).isSignUpInBookingProcess
  const { consultantData } = useBookingContext()
  const { appointmentName } = useCookieTheme()
  
  const [createAccount, setCreateAccount] = useState(false)

  useEffect(() => {
    window.onbeforeunload = null;
  }, []);

  const onClickSignin = () => {
    if (testIntegration) {
      dispatch(setErrorDetails({
        message: 'Cannot sign in preview widget because you\'re already logged as consultant!',
        severity: 'warning',
        verticalAnchor: 'bottom'
      }))
    } else {
      setCreateAccount(true)
    }
  }

  return (
    <div className='item'>
      <div className='margin_item'>
        <p className={`big_title ${config.type !== 'astrologer' ? 'big_title_with_subtitle' : ''}`}>
          {config.type === 'astrologer' ? 'YOU\'RE ALL SET!' : `${isGift ? 'GIFT' : 'APPOINTMENT REQUEST'} SENT!`}
        </p>
        {config.type !== 'astrologer' && !isGift &&
          <div className='subtitle'>
            You will receive an email notification as soon as {consultantData.name} {consultantData.surname} confirms/declines your request.<br />
            {!rescheduleBookingID && <span className='red_text'>*Payment will be charged automatically as soon as {consultantData.name} {consultantData.surname} accepts the request.</span>}
          </div>
        }
        {config.type === 'astrologer' &&
          <div className='astrologer_text heading'>
            Please look out for emails from ac@astrologyhub.com for confirmation, access details and reminders about your reading.
          </div>
        }
        <div className='border_left'>
          <BookingReviewDetails isGift={isGift} rescheduleBookingID={rescheduleBookingID} />
        </div>
        {!userID && !isSignUpInBookingProcess &&
          <Button
            className='dismiss_buttton create_account_button'
            onClick={onClickSignin}
            color="primary"
            variant="outlined"
          >
            <i>Click here to create an account & save this info for the future</i>
          </Button>
        }
        <ContinueButton
          onClick={() => history.go(0)}
          text={`${isGift ? 'GIFT' : "BOOK"} ANOTHER ${appointmentName ? appointmentName.toUpperCase() : 'APPOINTMENT'}`}
          specialClass='book_another_reading_button'
        />
      </div>

      {createAccount &&
        <SignInDialog
          openDialog={createAccount}
          setOpenDialog={setCreateAccount}
        />
      }
    </div>
  )
}

export default YoureAllSet;

import React, { useEffect, useState } from 'react'
import 'firebase/functions'
import { Step, StepLabel, Stepper } from '@material-ui/core'
import { useBookingContext } from '../BookingContext'
import useCookieTheme from '../../../App/useCookieTheme'
import { useSelector } from 'react-redux'
import { userState } from '../../../../api/userSlice'

const BookingStepper = () => {
  const { consultantData, isDetailsForm, isFinishBooking, isAlmostDone } = useBookingContext()
  const userID = useSelector(userState).user?.userID
  const { appointmentName } = useCookieTheme()
  
  const [step, setStep] = useState(0)

  useEffect(() => {
    if (isFinishBooking || isAlmostDone) {
      setStep(2)
    } else if (isDetailsForm) {
      setStep(1)
    }
  }, [isAlmostDone, isDetailsForm, isFinishBooking])

  return (
    <div>
      <Stepper className='stepper' activeStep={step} alternativeLabel>
        <Step className={step !== 0 ? 'hide_step' : ''}>
          <StepLabel className={`first ${!consultantData.needBirthForm ? userID ? 'first_no_astrologer_logged' : 'first_no_astrologer' : ''}`}>
            CHOOSE YOUR {appointmentName ? appointmentName.toUpperCase() : 'APPOINTMENT'}
          </StepLabel>
        </Step>
        <Step className={step !== 1 ? 'hide_step' : ''}>
          <StepLabel className={`second ${userID && !consultantData.needBirthForm ? 'second_no_astrologer_logged' : ''}`}>{userID ? 'CONFIRM PAYMENT' : 'ADD YOUR INFORMATION'}</StepLabel>
        </Step>
        {(consultantData.needBirthForm || !userID) &&
          <Step className={step !== 2 ? 'hide_step' : ''}>
            <StepLabel className={userID ? 'third_intake' : 'third'}>{userID ? 'FILL IN INTAKE' : 'CONFIRM'}</StepLabel>
          </Step>
        }
      </Stepper>
    </div>
  )
}

export default BookingStepper;

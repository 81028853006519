import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import './styles.css'
import Box from '@material-ui/core/Box'
import firebase from 'firebase/app'
import CallEnd from '@material-ui/icons/CallEnd'
import { cloudFunctionsURL } from '../Firebase/firebase'
import { FirebaseContext } from '../Firebase'
import { formattedIntegerLocaleSeparator, localeToCurrency, updateSessionLog } from '../../utils/utils'
import CallTicker from './components/CallTicker'
import { CloudOff, ScreenShare, StopScreenShare, VolumeOff, VolumeUp } from '@material-ui/icons'
import useAgoraHook from './useAgoraHook'
import IntakeInformation from './components/IntakeInformation'
import { config } from '../../config/config'
import { agoraObject } from './config'
import ActiveChat from './components/ActiveChat'
import AgoraRTC from 'agora-rtc-sdk-ng';
import BookingChatIcon from './components/ChatIcon'
import useCookieTheme from '../App/useCookieTheme'
import { userState } from '../../api/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AUTH_ROUTES_CONSULTANT } from '../../utils/routes'
import { sessionState, setActiveSession, setIsCallActive } from '../../api/sessionSlice'
import { useHistory } from 'react-router-dom'

const backgroundColorCall = ['#ab6060', 'darkcyan', '#71b350', '#6ab6b8', '#cacf3c', '#7b8009', '#d13bb8', '#c78fbe', '#ffb84d', '#9599bd'];

AgoraRTC.enableLogUpload()
AgoraRTC.setLogLevel(0);

AgoraRTC.onAutoplayFailed = () => {
  alert("Click \"Close\" to start streaming!")
}

AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
  if (changedDevice.state === "ACTIVE") {
    if (agoraObject.localTracks.audioTrack) {
      agoraObject.localTracks.audioTrack.setDevice(changedDevice.device.deviceId);
    }
  } else if (changedDevice.device?.label === agoraObject.localTracks.audioTrack?.getTrackLabel()) {
    const oldMicrophones = await AgoraRTC.getMicrophones();
    if (oldMicrophones[0] && agoraObject.localTracks.audioTrack) {
      agoraObject.localTracks.audioTrack.setDevice(oldMicrophones[0].deviceId);
    }
  }
}

AgoraRTC.onCameraChanged = async (changedDevice) => {
  if (changedDevice.state === "ACTIVE") {
    if (agoraObject.localTracks.videoTrack) {
      agoraObject.localTracks.videoTrack.setDevice(changedDevice.device.deviceId);
    }
  } else if (changedDevice.device?.label === agoraObject.localTracks.videoTrack?.getTrackLabel()) {
    const oldCameras = await AgoraRTC.getCameras();
    if (oldCameras[0] && agoraObject.localTracks.videoTrack) {
      agoraObject.localTracks.videoTrack.setDevice(oldCameras[0].deviceId);
    }
  }
}

let theClient = null;
const getClient = (sessionID) => {
  if(theClient) {
    return theClient
  }
  theClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" })
  updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - AGORA client created by client`)
  return theClient
}

const ActiveCall = () => {
  const isConsultant = JSON.parse((new URL(document.location)).searchParams.get("isConsultant") ?? null)
  const from = (new URL(document.location)).searchParams.get("from");
  const firebaseContext = useContext(FirebaseContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const userID = useSelector(userState).user?.userID
  const dbUser = useSelector(userState).user
  const profilePic = useSelector(userState).profilePic
  const sessionData = useSelector(sessionState).activeSession
  const { changePage } = useCookieTheme()

  const [otherProfilePic, setOtherProfilePic] = useState(null)
  const [firstBackgroundColorCall, setFirstBackgroundColorCall] = useState(false)
  const [secondBackgroundColorCall, setSecondBackgroundColorCall] = useState(false)
  const [slowConnection, setSlowConnection] = useState(false);
  const [isEndingCall, setIsEndingCall] = useState(false)
  const [sessionBecameActive, setSessionBecameActive] = useState(false)
  const [endsIn5Mins, setEndsIn5Mins] = useState(false)
  const [wasClosed, setWasClosed] = useState(false)
  const [firstPingDate, setFirstPingDate] = useState(null)

  const shouldRecording = useRef(!isConsultant && config.type === 'astrologer');
  const paymentFailed = useRef(false);
  const sessionStarted = useRef(false);
  const endSessionStarted = useRef(false);
  const endSessionFinalized = useRef(false);
  const meEndedCall = useRef(false)

  let callUUID = undefined
  let videoEnabled = undefined
  let isChatCall = undefined
  let bookingPrice = null
  let otherHandle = undefined
  let otherID = undefined
  let sessionID = undefined
  let locale = undefined
  let bookingID = undefined
  let isFree = undefined
  let isGift = undefined
  let isGuestSession = undefined

  const sessionSetToActive = useRef(false)

  if (sessionData) {
    callUUID = sessionData["callUUID"]
    videoEnabled = sessionData["videoEnabled"]
    isChatCall = sessionData["isChatCall"]
    locale = sessionData["locale"]
    isFree = sessionData.isFree
    isGift = sessionData.isGift
    isGuestSession = sessionData.isGuestSession

    const bookingSuccessfullyCharged = sessionData["bookingSuccessfullyCharged"]
    if (bookingSuccessfullyCharged) {
      const estimatedPrice = sessionData["price"]
      const customConsultantPercentage = sessionData["customConsultantPercentage"]
      const teamMemberPercentage = sessionData["teamMemberPercentage"]
      const discountPaidByConsultant = sessionData["discountPaidByConsultant"]
      const salesTax = sessionData["salesTax"]
      bookingPrice = isConsultant ? 
                      estimatedPrice * (customConsultantPercentage ?? 1) * (teamMemberPercentage ?? 1) - (discountPaidByConsultant ?? 0) :
                      bookingSuccessfullyCharged + (salesTax ?? 0)
      bookingID = sessionData["bookingID"]
    }
    sessionID = sessionData?.id

    if (isConsultant) {
      otherHandle = sessionData["clientFullName"]
      otherID = sessionData["clientID"]
    } else {
      otherHandle = sessionData["consultantFullName"]
      otherID = sessionData["consultantID"]
    }

    if (config.type !== 'astrologer' && !isConsultant) {
      firebase.firestore().doc(`/users/${sessionData["consultantID"]}`).get().then(consultantDoc => {
        shouldRecording.current = consultantDoc.data().recordingsEnabled
      })
    }
  } else {
    changePage({
      pathname: AUTH_ROUTES_CONSULTANT.HOME
    })
  }

  const agoraClientRef = useRef(getClient(sessionID))
  useEffect(() => {
    window.addEventListener("beforeunload", async () => {
      if (sessionStarted.current) {
        firebaseContext.functions.httpsCallable("updateSessionStatus")({
          status: 'finished',
          callUUID: callUUID,
          reason: 'Call ended successfully'
        })
      }

    return () => {
      theClient = null;
    }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    remoteIsJoined,
    muted,
    audioOff,
    videoOff,
    isRemoteVideo,
    shareScreen,
    join,
    stopCallSync,
    leave,
    speakerTapped,
    screenShareTapped
  } = useAgoraHook(
    videoEnabled,
    isChatCall,
    userID,
    sessionID,
    bookingID,
    isConsultant,
    shouldRecording,
    agoraClientRef,
    setIsEndingCall,
    sessionStarted
  )

  const [sessionPrice, setSessionPrice] = useState(bookingPrice)

  useEffect(() => {
    if (!isConsultant) {
      if (!remoteIsJoined && !isEndingCall) {
        const audioElement = document.getElementById('session_ring')
        if (audioElement) {
          audioElement.src = process.env.PUBLIC_URL + "/shortring.wav"
          audioElement.play()
        }
      } else {
        const audioElement = document.getElementById('session_ring')
        if (audioElement) {
          audioElement.pause()
          audioElement.src = null
        }
      }
    }

    return () => {
      const audioElement = document.getElementById('session_ring')
      if (audioElement) {
        audioElement.pause()
        audioElement.src = null
      }
    }
  }, [sessionData, remoteIsJoined, sessionID, isConsultant, isEndingCall])

  useEffect(() => {
    const startSessionAsync = async () => {
      await firebaseContext.euFunctions.httpsCallable("startSession")({ sessionID: sessionID, passedMinutes: 1 })
      updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - startSession function called by consultant`)
    }

    if (isConsultant) {
      startSessionAsync()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsultant, sessionID]);

  useEffect(() => {
    const asEffect = async () => {
      if (otherID) {
        try {
          const url = await firebase.app().storage().ref(`images/${otherID}.jpg`).getDownloadURL()
          setOtherProfilePic(url)
        } catch { }
      }
    }

    asEffect()
  }, [otherID])

  useEffect(() => {
    const first = backgroundColorCall[Math.floor(Math.random() * 10)];
    setFirstBackgroundColorCall(first)
    const second = backgroundColorCall[Math.floor(Math.random() * 10)];
    setSecondBackgroundColorCall(second)
  }, [])

  useEffect(() => {
    const joinEffect = async () => {
      await join(callUUID)
    }

    if (sessionData && !endSessionStarted.current) {
      joinEffect()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData, callUUID])

  useEffect(() => {
    if (sessionBecameActive && remoteIsJoined && firstPingDate === null) {
      firebase.firestore().doc(`/sessions/${sessionID}`).get().then(session => {
        const sessData = session.data()
        const dateFPD = sessData.firstPingDate.toDate()
        setFirstPingDate(dateFPD)
      })
    }
  }, [sessionBecameActive, remoteIsJoined, sessionID, firstPingDate])

  //Firebase effect
  useEffect(() => {
    if (!sessionID) {
      return
    }

    const unsubscribe = firebase.firestore().doc(`sessions/${sessionID}`).onSnapshot(sn => {
      const theData = sn.data()
      if (theData["status"] === "finished") {
        if (theData["securityChargeFailed"]) {
          paymentFailed.current = true
          endSession("error", '')
        } else {
          endSession("success", `${meEndedCall.current ? "You've" : otherHandle} ended the call!`)
        }
      }
      if (theData["status"] === "rejected") {
        endSession("warning", `You rejected the call!`)
      }
      if (theData.status === "active" && !sessionSetToActive.current) {
        sessionSetToActive.current = true
        setSessionBecameActive(true)
      }
    }, error => {
      console.log(`An error ocurred. Error: ${error.message}`)
      paymentFailed.current = true
    })

    return () => {
      unsubscribe()

      if (!sessionData) {
        return
      }
      if (!sessionSetToActive.current) {
        sendCancelCall()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const leaveInterval = setInterval(async () => {
      if (endSessionFinalized.current) {
        clearInterval(leaveInterval)

        await leaveAndGo()
      }
    }, 1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sendCancelCall = () => {
    const baseURL = cloudFunctionsURL()
    const fullURL = `${baseURL}/denyCall?callUUID=${callUUID}`
    fetch(fullURL, { mode: 'no-cors' })
  }

  const endSessionEvent = useCallback((eventString) => {
    try {
      firebase.app().analytics().logEvent("session_ended", {
        reason: eventString,
        sessionID: callUUID,
        userID: firebase.app().auth().currentUser?.uid
      })
    } catch {}
  }, [callUUID])

  const endSession = useCallback((severity, message) => {
    if (!endSessionStarted.current) {
      endSessionEvent(message)

      endSessionStarted.current = true
      stopCallSync()

      if (severity === "success" && !bookingID) {
        localStorage.setItem('callData', JSON.stringify({
          sessionID,
          severity,
          message,
          paymentFailed: paymentFailed.current
        }))
      } else {
        localStorage.setItem('callData', JSON.stringify({
          severity,
          message,
          paymentFailed: paymentFailed.current
        }))
      }

      endSessionFinalized.current = true
      
      if (!isConsultant && !isGuestSession) {
        localStorage.setItem('reviewSessionID', sessionID)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const leaveAndGo = async () => {
    await leave(shouldRecording)

    if (isConsultant || !remoteIsJoined || !shouldRecording.current) {
      dispatch(setIsCallActive(false))
      dispatch(setActiveSession(null))
  
      changePage({
        pathname: from,
      })

      setTimeout(() => {
        history.go(0)
      }, 200);
    }
  }

  const startEndSession = async (status, severity, message, reason) => {
    await firebaseContext.functions.httpsCallable("updateSessionStatus")({ status: status, callUUID: callUUID, reason: reason })
    endSession(severity, message)
  }

  const endCallTapped = async () => {
    stopCallSync()
    const audioElement = document.getElementById('session_ring')
    if (audioElement) {
      audioElement.pause()
      audioElement.src = null
    }
    
    updateSessionLog(sessionID, `[SESSION - ${sessionID}] ${new Date()} - ${isConsultant ? 'consultant' : 'client'} ${sessionSetToActive.current ? "ended" : "rejected"} the call`)

    const newStatus = sessionSetToActive.current ? "finished" : "rejected"
    meEndedCall.current = true
    startEndSession(newStatus, "success", "You've ended the call!", `${isConsultant ? 'Consultant' : 'Client'} ${newStatus} the call`)
  }

  const PlaceholderCall = () => {
    return (
      <>
        <div className='img_container profile_pic' style={{ backgroundColor: firstBackgroundColorCall }}>
          <img className='profile_pic' src={otherProfilePic ?? require("../../images/user.png")} alt="" />
        </div>
        <div className='img_container profile_pic second' style={{ backgroundColor: secondBackgroundColorCall }}>
          <img className='profile_pic' src={profilePic ?? require("../../images/user.png")} alt="" />
        </div>
      </>
    )
  }

  return (
    <>
      <Box className={`active_call ${videoEnabled && !sessionBecameActive && remoteIsJoined ? 'black_screen' : ''}`}>
        {sessionData &&
          <>
            <div className={`name ${isChatCall ? 'black_name' : ''}`}>
              <div>{otherHandle}</div>
              <div className='time'>
                {sessionBecameActive && remoteIsJoined ?
                  <CallTicker
                    isConsultant={isConsultant}
                    isChatCall={isChatCall}
                    otherHandle={otherHandle}
                    endsIn5Mins={endsIn5Mins}
                    onClose={() => {
                      setEndsIn5Mins(false)
                      setWasClosed(true)
                    }}
                    firstPingDate={firstPingDate}
                    sessionStarted={sessionStarted}
                    sessionData={sessionData}
                    isEndingCall={isEndingCall}
                    bookingID={bookingID}
                    setEndsIn5Mins={setEndsIn5Mins}
                    wasClosed={wasClosed}
                    sessionID={sessionID}
                    paymentFailed={paymentFailed.current}
                    videoEnabled={videoEnabled}
                    agoraClientRef={agoraClientRef.current}
                    setSlowConnection={setSlowConnection}
                    stopCallSync={stopCallSync}
                    startEndSession={startEndSession}
                    setSessionPrice={setSessionPrice}
                  />
                :
                  <>
                    {isEndingCall ?
                      <div className='calling'>Ending {isChatCall ? 'text chat' : 'call'}{shouldRecording.current && !isChatCall && sessionSetToActive.current ? '. Preparing your recording' : ''}. Please wait...</div> :
                      <>
                        {isConsultant ?
                          <div className='calling'>Connecting...</div> :
                          <div className='calling'>{isChatCall ? `Initiating text chat with ${otherHandle}` : 'Calling'}...</div>
                        }
                      </>
                    }
                  </>
                }
              </div>
            </div>
            {dbUser?.needBirthForm && bookingID && isConsultant &&
              <IntakeInformation bookingID={bookingID} />
            }
            {!isChatCall && 
              <BookingChatIcon 
                sessionData={sessionData}
                isConsultant={isConsultant}
                sessionID={sessionID}
                fromActiveCall={true}
                otherHandle={otherHandle}
              />
            }
            <div className={`total_price ${isChatCall ? 'black_total_price' : ''}`}>
              Total: <br />
              {isFree ? `FREE` : isGift ? 'GIFT' : `${formattedIntegerLocaleSeparator(sessionPrice, locale)}${localeToCurrency(locale)}`}
            </div>
            <div className="end_call_button_container" >
              <CallEnd className="end_call_button" onClick={endCallTapped} />
            </div>
            {slowConnection && !videoOff && !audioOff &&
              <div className="slow_connection_container" >
                <CloudOff />Slow connection...
              </div>
            }
            {!isChatCall && 
              <>
                <div className="mute_button_container" >
                  {muted ?
                    <VolumeOff className={`mute_button ${audioOff ? 'mute_button_audio_off' : ''}`} onClick={speakerTapped} /> :
                    <VolumeUp className="mute_button" onClick={speakerTapped} />
                  }
                  {audioOff &&
                    <div className='audio_off_text'>
                      Your microphone is off for this site.
                    </div>
                  }
                </div>
                <div className="screen_share_button_container" >
                  {shareScreen ?
                    <StopScreenShare className="mute_button" onClick={() => screenShareTapped()} /> :
                    <ScreenShare className="mute_button" onClick={() => screenShareTapped()} />
                  }
                </div>
              </>
            }
            {!isChatCall &&
              <>
                {sessionBecameActive && remoteIsJoined && !isEndingCall ?
                    <div id='remoteVideo' className={`stream_container ${(videoEnabled || isRemoteVideo) ? '' : 'hidden'} ${!videoEnabled && isRemoteVideo ? 'full_share_screen' : ''}`}>
                      <Box id="localVideo" className={`video_container ${videoEnabled ? '' : 'hidden'} `} >
                        {videoOff && !shareScreen &&
                          <div className='video_off_text'>
                            <span>Your video is off for this site.</span>
                          </div>
                        }
                      </Box>
                    </div>
                  :
                    <>
                      {videoEnabled &&
                        <div className='img_container img_before_call_container'>
                          {!isEndingCall && <img className='profile_pic' src={otherProfilePic ?? require("../../images/user.png")} alt="" />}
                        </div>
                      }
                    </>
                }
              </>
            }
            {!videoEnabled && !isRemoteVideo && !isChatCall &&
              <PlaceholderCall />
            }
            {isChatCall && 
              <>
                {sessionBecameActive && remoteIsJoined ?
                  <div className={`chat_call_share_screen_container ${shareScreen ? 'chat_call_share_screen_on' : ''}`}>
                    <div id='chat_call_share_screen' />
                    <ActiveChat
                      userID={firebase.app().auth().currentUser?.uid}
                      isConsultant={isConsultant}
                      sessionData={sessionData}
                      isChatCall={isChatCall}
                      fromActiveCall={true}
                      sessionID={sessionID}
                      otherHandle={otherHandle}
                    />
                  </div>
                :
                  <PlaceholderCall />
                }
              </>
            }
          </>
        }
      </Box>
    </>
  )
};

export default ActiveCall;

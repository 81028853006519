import React, { useEffect, useState } from 'react';
import "./navigation.css";
import Button from '@material-ui/core/Button'
import firebase from 'firebase'
import Notifications from './Notifications'
import ConsultantBalance from './ConsultantBalance'
import { FormControlLabel, useMediaQuery } from '@material-ui/core'
import ManageTeam from "./ManageTeam";
import Confirmation from '../components/Confirmation';
import SwitchItem from '../components/SwitchIOS';
import { AUTH_ROUTES_CLIENT, AUTH_ROUTES_CONSULTANT } from '../../utils/routes';
import { config } from '../../config/config';
import useCookieTheme from '../App/useCookieTheme';
import InfoPopover from '../components/InfoPopover';
import { HashLink } from 'react-router-hash-link';
import { useDispatch, useSelector } from 'react-redux';
import { userState, executeLogout, setErrorDetails } from '../../api/userSlice';
import { sessionState } from '../../api/sessionSlice';
import useBookingHook from '../ConsultantProfile/useBookingHook';

const Navigation = () => {
  const dispatch = useDispatch()
  const dbUser = useSelector(userState).user
  const dbUserID = useSelector(userState).user?.userID
  const isConsultant = useSelector(userState).user?.consultant
  const isTeamPage = useSelector(userState).isTeamPage
  const currentBookingID = useSelector(sessionState).currentBookingID
  const { removeBooking } = useBookingHook()
  const { bookingEnableName, changePage } = useCookieTheme()

  const [user, setUser] = useState(null)
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  const [anchorEl, setAnchorEl] = useState({
      availableInstantCalls: null,
      availableBookings: null,
  });
  const [refreshPage, setRefreshPage] = useState(false);
  const open = {
      availableInstantCalls: Boolean(anchorEl.availableInstantCalls),
      availableBookings: Boolean(anchorEl.availableBookings),
  }
  
  const widthGreaterThan850px = useMediaQuery('(min-width:850px)');
  const widthLessThan650px = useMediaQuery('(max-width:649px)');
  const widthLessThan400px = useMediaQuery('(max-width:399px)');
  const orientationPortrait = useMediaQuery('(orientation:portrait)');

  useEffect(() => {
    firebase.firestore().doc(`users/${dbUserID}`).onSnapshot((userSnap) => {
      setUser(userSnap.data())
    })
  }, [dbUserID])

  useEffect(() => {
    if (isConsultant) {
      if (currentBookingID) {
        firebase.app().firestore().doc('/users/' + dbUserID).update({
          isBusy: true,
        })
      } else {
        firebase.app().firestore().doc('/users/' + dbUserID).update({
          isBusy: false,
        })
      }
    }
  }, [currentBookingID, dbUserID, isConsultant])

  const handleLogOut = () => {
    setShowLogoutConfirmation(true);
  }

  const handleAvailableChange = async (checked) => {
    if (!user.videoPrice && !user.audioPrice && !user.chatPrice) {
      changePage({
        pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', dbUserID.userID),
        search: 'setPerMin=true&section=profile_pricing'
      })
    } else {
      if (currentBookingID) {
        dispatch(setErrorDetails({
          message: `You have a meeting now. You already are available only for a client!`
        }))
        return
      }

      const newV = checked
      await firebase.app().firestore().doc('/users/' + dbUserID).update({
        online: newV,
        onlineCache: newV,
      })
    }
  }

  const handleAvailableBookings = async (checked) => {
    if (!user.audioBookingAmounts?.filter(amount => amount !== 0).length && 
        !user.videoBookingAmounts?.filter(amount => amount !== 0).length && 
        (config.type === 'astrologer' || !user.chatBookingAmounts?.filter(amount => amount !== 0).length)) {
      changePage({
        pathname: AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', dbUserID),
        search: 'setBookings=true&section=profile_pricing'
      })
    } else {
      const newV = checked
      await firebase.app().firestore().doc('/users/' + dbUserID).update({
        bookingsEnabled: newV
      })
    }
  }

  const handleInfo = (event, setAnchor) => {
      setAnchor(event.currentTarget);
  };

  const setIsPublicProfileValue = (value, keyName) => {
      setAnchorEl(oldAnchorEl => {
          oldAnchorEl[`${keyName}`] = value
          return oldAnchorEl
      })
      setRefreshPage(!refreshPage)
  };

  return (
    <div className={`navigation ${!isConsultant ? 'navigation_client' : ''}`}>
      {dbUser &&
        <>
          {isConsultant &&
            <div className="navigation_zone1">
              <div>
                <FormControlLabel
                  className="navigation_avail"
                  control={<SwitchItem checked={(user?.online && !user.isBusy) ?? null} set={handleAvailableChange} />}
                  label='Available for instant calls'
                  labelPlacement="start"
                />
                <InfoPopover
                    open={open.availableInstantCalls}
                    anchorEl={anchorEl.availableInstantCalls}
                    setAnchorEl={(value) => setIsPublicProfileValue(value, 'availableInstantCalls')}
                    handleInfo={(e) => handleInfo(e, (value) => setIsPublicProfileValue(value, 'availableInstantCalls'))}
                    buttonClass='availability_info_button'
                >
                  <div className='timezone_info_content'>
                    Every time this is on, people can make paid per minute instant calls to you. Make sure you only set prices for the type of services you want to offer: audio, video or txt. Leave the others blank. Prices can be set&nbsp;
                    <HashLink to={{
                      pathname: `/profile/${dbUserID}`,
                      hash: '#profile_pricing',
                      closePopup: () => {
                        setIsPublicProfileValue(null, 'availableInstantCalls')
                      }
                    }}>here</HashLink>.
                  </div>
                </InfoPopover>
              </div>
              <div>
                <FormControlLabel
                  className="navigation_avail"
                  control={<SwitchItem checked={user?.bookingsEnabled ?? null} set={handleAvailableBookings} />}
                  label={`Available for ${bookingEnableName}s`}
                  labelPlacement="start"
                />
                <InfoPopover
                    open={open.availableBookings}
                    anchorEl={anchorEl.availableBookings}
                    setAnchorEl={(value) => setIsPublicProfileValue(value, 'availableBookings')}
                    handleInfo={(e) => handleInfo(e, (value) => setIsPublicProfileValue(value, 'availableBookings'))}
                    buttonClass='availability_info_button'
                >
                  <div className='timezone_info_content'>
                    Every time this is on, people can make bookings to you based on your availability that you set&nbsp;
                    <HashLink to={{
                      pathname: `/profile/${dbUserID}`,
                      hash: '#profile_availability',
                      closePopup: () => {
                        setIsPublicProfileValue(null, 'availableBookings')
                      }
                    }}>here</HashLink>. Make sure you only set prices for the sessions type (audio, video, txt) and duration (15,30,45,60,75,90 min) you want to offer. Leave the others blank. Prices can be set&nbsp;
                    <HashLink to={{
                      pathname: `/profile/${dbUserID}`,
                      hash: '#profile_pricing',
                      closePopup: () => {
                        setIsPublicProfileValue(null, 'availableBookings')
                      }
                    }}>here</HashLink>.
                  </div>
                </InfoPopover>
              </div>
            </div>
          }

          {isConsultant && widthGreaterThan850px && !isTeamPage && !dbUser?.shouldBeInvited &&
            <div className="navigation_zone2">
                <ManageTeam />
            </div>
          }

          {!isConsultant && !widthLessThan400px &&
            <div className="navigation_zone2_client">
                <Button
                  className="home_btn_setup_payment"
                  onClick={() => {
                    removeBooking()
                    changePage({
                      pathname: AUTH_ROUTES_CLIENT.PAYMENT
                    })
                  }}
                  variant="outlined"
                  color="primary"
                  size="large"
                >
                {dbUser?.stripeDefaultPMHint ? `${dbUser.stripeDefaultPMHint} | Change` : 'Setup Payment Method'}
                </Button>
            </div>
          }

          <div className="navigation_zone3">
            {isConsultant && <ConsultantBalance />}
            {((isConsultant && !(orientationPortrait && widthLessThan650px)) || !isConsultant) &&
              <>
                {dbUserID && <Notifications />}
                {dbUserID &&
                  <Button onClick={handleLogOut} color="inherit">
                    <img className='navigation_logout_button' alt="" src={require("../../images/power.svg")} />
                  </Button>
                }
              </>
            }
          </div>
        </>
      }

      {showLogoutConfirmation &&
        <Confirmation
          content="Are you sure you want to logout ?"
          onClose={setShowLogoutConfirmation}
          onAction={async () => {
            setShowLogoutConfirmation(false)
            await dispatch(executeLogout()).unwrap()
            removeBooking()
          }}
        />
      }
    </div>
  )
};

export default Navigation;
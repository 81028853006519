import React, { useEffect, useState } from 'react'
import "./account-details.css";
import { Button, CircularProgress, FormControl, Grid, Input, useMediaQuery } from '@material-ui/core'
import firebase from 'firebase/app'
import { ImagePicker } from 'react-file-picker'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ChangePasswordDialog from '../MyProfile/components/changePasswordDialog';
import ChangeEmailDialog from '../MyProfile/components/changeEmailDialog';
import DeleteAccountDialog from '../MyProfile/components/deleteAccountDialog';
import EditBirthInfosDialog from './EditBirthInfosDialog';
import { AUTH_ROUTES_CLIENT } from '../../utils/routes';
import moment from 'moment-timezone';
import { config } from '../../config/config';
import InfoPopover from '../components/InfoPopover';
import ReactCountryFlag from 'react-country-flag';
import useCookieTheme from '../App/useCookieTheme';
import { useDispatch, useSelector } from 'react-redux';
import { userState, getProfilePic, setErrorDetails, setUserState } from '../../api/userSlice';

const AccountDetailsClient = () => {
    const { changePage } = useCookieTheme()
    const dispatch = useDispatch()
    const userID = useSelector(userState).user?.userID
    const profilePic = useSelector(userState).profilePic

    const [user, setUser] = useState(null)
    const [name, setName] = useState(null)
    const [surname, setSurname] = useState(null)
    const [updating, setUpdating] = useState(false)
    const [birthInfosDialog, setBirthInfosDialog] = useState(false)
    const [changePasswordDialog, setChangePasswordDialog] = useState(false)
    const [changeEmailDialog, setChangeEmailDialog] = useState(false)
    const [deleteAccountDialog, setDeleteAccountDialog] = useState(false)
    const [birthInfosState, setBirthInfoState] =  useState(null)
    const [credit, setCredit] = useState(null)
    const [expireDays, setExpireDays] = useState(0)
    const [couponCode, setCouponCode] = useState(null)
    const [checkingCode, setCheckingCode] = useState(null)
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl)

    const handleInfo = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const widthLessThan650px = useMediaQuery('(max-width:649px)');

    useEffect(() => {
      firebase.firestore().doc(`users/${userID}`).onSnapshot((userSnap) => {
        const userData = userSnap.data()
        setUser(userData)
        setName(userData.name)
        setSurname(userData.surname)
        const seconds = userData.birthInfos?.birthDate?.seconds
        const newBirthInfos = {
          birthDate: seconds ? new Date(seconds * 1000) : null,
          city: userData?.birthInfos?.city ?? undefined,
          state: userData?.birthInfos?.state ?? undefined,
          country: userData?.birthInfos?.country ?? undefined,
        }
        setBirthInfoState(newBirthInfos)
      })
    }, [userID])

    useEffect(() => {
      ValidatorForm.addValidationRule('minChars', (value) => {
          if (value.toString().length < 2) {
              return false;
          }
          return true;
      });
    }, [])

    useEffect(() => {
        if (!user) {
            return
        }

        if (config.type === 'yaloop' && user.creditCouponID && !user.isUsedCredit) {
          firebase.firestore().doc(`credit_coupons/${user.creditCouponID}`).onSnapshot(res => {
            const dataCoupon = res.data()
            setCredit(dataCoupon.credit)
            setExpireDays(Math.floor(moment.duration(moment(dataCoupon.expireDateCredit?.toDate()).diff(moment())).asDays()))
          })
        }
    }, [userID, user])

    const changeProfilePic = (base64) => {
        if (!userID) {
            return
        }
        const stor = firebase.app().storage().ref(`images/${userID}.jpg`)

        stor.putString(base64, 'data_url').then(async res => {
            console.log("Uplodated! got res: ", res)
            await dispatch(getProfilePic()).unwrap()
        })
    }

    const updateAccountDetails = () => {
      setUpdating(true)
      const toUpdate = {
        name: name,
        surname: surname,
      }
      firebase.app().firestore().doc(`/users/${userID}`).update(toUpdate).finally(async () => {
        dispatch(setUserState(toUpdate))
        setUpdating(false)
      });
    }

    const checkCouponCode = (code) => {
      setCheckingCode(true)

      firebase.functions().httpsCallable('checkCreditCoupon')({ code }).then(res => {
        setCredit(res.data.credit)
        setExpireDays(Math.floor(moment.duration(moment(res.data.expireDateCredit).diff(moment())).asDays()))
      }).catch(e => {
        dispatch(setErrorDetails({
          message: e.message
        }))
      }).finally(() => {
        setCheckingCode(false)
      })
    }

    if (!userID) {
      return null;
    }

    return (
      <>
        {!user ?
          <div className='loading_container'>
            <CircularProgress size={50} />
          </div>
        :
          <div className="account_details">
            <div className="profile_header">
                <div className="profile_label_1 heading">
                    Account details
                </div>
                <div className="profile_label_2">
                    Update your personal information
                </div>
            </div>
            
              <ImagePicker
                  extensions={['jpg', 'jpeg', 'png']}
                  dims={{ minWidth: 10, maxWidth: 1000, minHeight: 10, maxHeight: 1000 }}
                  onChange={base64 => {
                      dispatch(setErrorDetails({
                        message: null
                      }))
                      console.log("At least onCHange called?")
                      changeProfilePic(base64)
                  }}
                  onError={() => { 
                    dispatch(setErrorDetails({
                      message: "Error uploading your picture. It must be max 1000x1000!"
                    }))
                  }}
              >
                  <Button>
                      <img className='profile_pic_image profile_pic' alt="" src={profilePic ?? require("../../images/user.png") } />
                  </Button>
              </ImagePicker>

              {config.type === 'yaloop' && !user.isUsedCredit && expireDays >= 0 &&
                <div className='credit_coupon_container'>
                  {user.creditCouponID ?
                    <div className='credit_coupon'>
                      Credit:&nbsp;<b>${credit / 100}</b>&nbsp;(Expires {expireDays === 0 ? <b>&nbsp;today</b> : <>in&nbsp;<b>{expireDays}</b>&nbsp;day{expireDays > 1 && 's'}</>})
                    </div>
                  :
                    <div className='credit_coupon'>
                      Coupon code:
                      <FormControl className='form_control' fullWidth variant="standard">
                        <Input
                          disabled={checkingCode}
                          value={couponCode}
                          onChange={(e) => {
                            const value = e.target.value.toUpperCase()
                            if (value.length < 6) {
                              setCouponCode(value)
                              if (value.length === 5) {
                                checkCouponCode(value)
                              }
                            }
                          }}
                        />
                        <div className='loading'>
                          {checkingCode && <CircularProgress size={15} />}
                        </div>
                      </FormControl>
                    </div>
                  }

                  <div className='timezone_info'>
                    <InfoPopover 
                      open={open}
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                      handleInfo={handleInfo}
                    >
                      <div className='timezone_info_content'>
                        This coupon / credit is only valid to use for American consultants as part of Yaloop's policy to support the American economy and promote the use of American consultants
                        &nbsp;<ReactCountryFlag countryCode="US" svg />&nbsp;<ReactCountryFlag countryCode="US" svg />&nbsp;<ReactCountryFlag countryCode="US" svg />
                      </div>
                    </InfoPopover>
                  </div>
                </div>
              }
            
            <ValidatorForm
              className={"form_items"}
              onError={errors => console.log(errors)}
              onSubmit={() => { return }}
            >
              <Grid container spacing={2}>
                <Grid item xs={widthLessThan650px ? 12 : 6}>
                  <TextValidator
                    validators={['required', 'minChars']}
                    variant="outlined"
                    size='small'
                    errorMessages={['This field is required', 'Min 2 characters']}
                    onChange={(e) => { setName(e.target.value) }}
                    value={name ?? ''} id="firstname"
                    label="First Name" fullWidth required
                  />
                </Grid>
                <Grid item xs={widthLessThan650px ? 12 : 6}>
                  <TextValidator
                    validators={['required', 'minChars']}
                    variant="outlined"
                    size='small'
                    errorMessages={['This field is required', 'Min 2 characters']}
                    onChange={(e) => { setSurname(e.target.value) }}
                    value={surname ?? ''} id="lastname" label="Last Name" fullWidth required
                  />
                </Grid>
                <Grid className='button_centered button_submit' item xs={12}>
                  <Button disabled={updating} onClick={updateAccountDetails} color="primary" variant="contained">
                    Update information
                  </Button>
                </Grid>
                {(config.type === 'astrologer' || user.birthInfos) &&
                  <Grid className='button_centered' item xs={12}>
                    <Button onClick={() => setBirthInfosDialog(true)}>
                        Edit Birth details
                    </Button>
                  </Grid>
                }
                <Grid className='button_centered' item xs={12}>
                  <Button onClick={() => changePage({ pathname: AUTH_ROUTES_CLIENT.PAYMENT })}>
                      Payment Settings
                  </Button>
                </Grid>
                <Grid className='button_centered' item xs={12}>
                  <Button onClick={() => setChangeEmailDialog(true)}>
                      Change email
                  </Button>
                </Grid>
                <Grid className='button_centered' item xs={12}>
                  <Button onClick={() => setChangePasswordDialog(true)}>
                      Change password
                  </Button>
                </Grid>
                <Grid className='button_centered delete_button' item xs={12}>
                  <Button onClick={() => setDeleteAccountDialog(true)}>
                      Delete Account
                  </Button>
                </Grid>
              </Grid>
            </ValidatorForm>
          </div>
        }
        
        {birthInfosDialog && 
          <EditBirthInfosDialog
            handleClose={() => setBirthInfosDialog(false)}
            open={birthInfosDialog}
            birthInfosState={birthInfosState}
            setBirthInfoState={setBirthInfoState}
          />
        }
        {changeEmailDialog && <ChangeEmailDialog handleClose={() => setChangeEmailDialog(false)} open={changeEmailDialog} />}
        {changePasswordDialog && <ChangePasswordDialog handleClose={() => setChangePasswordDialog(false)} open={changePasswordDialog} />}
        {deleteAccountDialog && <DeleteAccountDialog handleClose={() => setDeleteAccountDialog(false)} open={deleteAccountDialog} />}
      </>
    )
}

export default AccountDetailsClient;
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import firebase from 'firebase/app'
import { defaultTheme } from "../config/config"

export const fetchTheme = createAsyncThunk('theme/fetchTheme', async (data) => {
  const { newThemeID, history } = data
  if (newThemeID) {
    const theTheme = await firebase.firestore().doc(`/themes/${newThemeID}`).get()
    const themeData = theTheme.data()
    if (themeData) {
      themeData.id = newThemeID

      const appConsultantProfileTitle = themeData?.appConsultantProfileTitle ?? defaultTheme.appConsultantProfileTitle ?? defaultTheme.appName
      document.title = appConsultantProfileTitle
      const appConsultantProfileDescription = themeData?.appConsultantProfileDescription ?? defaultTheme.appConsultantProfileDescription
      document.querySelector("meta[name='description']").setAttribute('content', appConsultantProfileDescription)
    }

    const searchParams = (new URL(document.location)).searchParams;
    if (newThemeID === defaultTheme.id) {
      searchParams.delete('theme')
    } else {
      if (themeData?.simplifiedId) {
        searchParams.set('theme', themeData.simplifiedId)
      } else {
        searchParams.delete('theme')
      }
    }
    history.replace({ search: searchParams.toString() })
    
    return themeData ? JSON.stringify(themeData) : null;
  }

  return null;
})

const initialState = {
  theme: null,
  realThemeID: null,
  isLoadingTheme: true,
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    updateTheme(state, action) {
      state.theme = {
        ...state.theme,
        ...action.payload
      }
    },
    setRealThemeID(state, action) {
      state.realThemeID = action.payload
    },
  },  
  extraReducers(builder) {
    builder
      .addCase(fetchTheme.fulfilled, (state, action) => {     
        const themeParse = JSON.parse(action.payload)
        state.theme = themeParse
        state.isLoadingTheme = false
      })
      .addCase(fetchTheme.rejected, (state) => {
        state.isLoadingTheme = false
      });
  }
})

export const {
  updateTheme,
  setRealThemeID
} = themeSlice.actions

export const themeState = state => state.theme

export default themeSlice.reducer

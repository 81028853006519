import React, { useState } from "react";
import "./sidebar.css";
import SidebarItem from "./sidebarItem/SidebarItem";
import { ReactComponent as Bookings } from "../../images/bookings.svg";
import { ReactComponent as Dashboard } from "../../images/dashboard.svg";
import Typography from '@material-ui/core/Typography'
import firebase from 'firebase/app'
import { useHistory } from "react-router-dom";
import { Button, Drawer, IconButton, useMediaQuery } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import copy from "copy-to-clipboard";
import { Share, Person, Group, SettingsPhone, Language } from "@material-ui/icons";
import Confirmation from "../components/Confirmation";
import Notifications from "../Navigation/Notifications";
import useCookieTheme from "../App/useCookieTheme";
import { AUTH_ROUTES_CLIENT, AUTH_ROUTES_CONSULTANT, COMMON_ROUTES } from "../../utils/routes";
import { config } from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import { userState, executeLogout, setIsTeamPage } from "../../api/userSlice";
import useBookingHook from "../ConsultantProfile/useBookingHook";

export default function Sidebar(props) {
    const { bannerOn } = props
    const user = firebase.app().auth().currentUser
    const history = useHistory();
    const dispatch = useDispatch()
    const dbUser = useSelector(userState).user
    const userID = useSelector(userState).user?.userID
    const isConsultant = useSelector(userState).user?.consultant
    const profilePic = useSelector(userState).profilePic
    const isTeamMaster = useSelector(userState).isTeamMaster
    const isTeamMember = useSelector(userState).isTeamMember
    const integration = useSelector(userState).integration
    const {
        name,
        logo,
        menuDashboard,
        menuHistory,
        menuProfile,
        menuConsultantsList,
        changePage
    } = useCookieTheme()
    const { removeBooking } = useBookingHook()

    const paths = [
        AUTH_ROUTES_CONSULTANT.HOME,
        AUTH_ROUTES_CONSULTANT.PROFILE.replace(':id', userID),
        AUTH_ROUTES_CLIENT.ACCOUNT_DETAILS,
        AUTH_ROUTES_CONSULTANT.TEAM,
        AUTH_ROUTES_CONSULTANT.HISTORY,
        COMMON_ROUTES.LIST,
        COMMON_ROUTES.TEST,
        AUTH_ROUTES_CONSULTANT.WEBSITE_INTEGRATION
    ]
    const [active, setActive] = useState(paths.map(path => path === history.location.pathname));
    const [open, setOpen] = useState(false);
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

    const widthLessThan1000px = useMediaQuery('(max-width:999px)');
    const widthLessThan650px = useMediaQuery('(max-width:649px)');
    const orientationPortrait = useMediaQuery('(orientation:portrait)');

    history.listen((location) => {
        setActive((oldActive) => {
            const newActive = oldActive.map((_, index) => paths[index] === location.pathname.split('#')[0])
            return newActive
        })
    })

    const onSelect = (id) => {
        dispatch(setIsTeamPage(id === 3))
        setOpen(false);
        removeBooking()
        changePage({
            pathname: paths[id]
        })
    }

    const handleDrawer = () => {
        setOpen(open => !open);
    };

    const handleLogOut = () => {
        setShowLogoutConfirmation(true);
    }

    const SideBarItems = (props) => {
        return (
            <>
                <SidebarItem
                    icon={<Dashboard />}
                    label={`${menuDashboard}`}
                    active={active[0]}
                    id={0}
                    onSelect={onSelect}
                    isMini={props.isMini}
                />
                {!isConsultant && config.type !== 'yaloop' &&
                    <SidebarItem
                        icon={<Group />}
                        id={5}
                        label={`Available ${menuConsultantsList}`}
                        active={active[5]}
                        onSelect={onSelect}
                        isMini={props.isMini}
                    />
                }
                < SidebarItem
                    icon={<Bookings />}
                    id={4}
                    label={`${menuHistory}`}
                    active={active[4]}
                    onSelect={onSelect}
                    isMini={props.isMini}
                />
                {isConsultant ?
                    <>
                        <SidebarItem
                            icon={<Person />}
                            id={1}
                            label={`${menuProfile}`}
                            active={active[1]}
                            onSelect={onSelect}
                            isMini={props.isMini}
                        />
                        {isTeamMaster &&
                            <SidebarItem
                                icon={<Group />}
                                id={3}
                                label="Team"
                                active={active[3]}
                                onSelect={onSelect}
                                isMini={props.isMini}
                            />
                        }
                    </>
                    :
                    <>
                        <SidebarItem
                            icon={<Person />}
                            id={2}
                            label="Account Details"
                            active={active[2]}
                            onSelect={onSelect}
                            isMini={props.isMini}
                        />
                    </>
                }
                {config.type === 'loki' && !integration && isConsultant && !isTeamMember &&
                    <SidebarItem
                        icon={<Language />}
                        id={7}
                        label="Website Integration"
                        active={active[7]}
                        onSelect={onSelect}
                        isMini={props.isMini}
                    />
                }
                <SidebarItem
                    icon={<SettingsPhone />}
                    id={6}
                    label="Test Call"
                    active={active[6]}
                    onSelect={onSelect}
                    isMini={props.isMini}
                />
            </>
        )
    }

    const appName = name
    const appLogo = logo

    const BigMenu = () => {
        return (
            <div className={`sidebar ${bannerOn && !widthLessThan1000px ? 'sidebar_banner_on' : ''}`}>
                <div className="sidebar_header">
                    <img className="sidebar_logo" alt="" src={appLogo} />
                </div>
                <div className="sidebar_label_1 heading">Welcome to {appName}</div>
                <div className="sidebar_items">
                    <SideBarItems />
                </div>
                {isConsultant && user && orientationPortrait && widthLessThan650px &&
                    <Button onClick={handleLogOut} color="inherit" style={{ textTransform: 'none', fontSize: '17px', textDecoration: 'underline' }}>
                        Logout
                    </Button>
                }
                {isConsultant &&
                    <div className="sidebar_copy_link">
                        <Button
                            className="home_copy_link_btn"
                            onClick={() => {
                                copy(dbUser.profileLink);
                            }}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Copy profile link
                        </Button>
                    </div>
                }
                <div className="sidebar_footer">
                    <img className='profile_pic' alt="" src={profilePic ?? require("../../images/user.png")} />
                    <div className="sidebar_footer_info">
                        <Typography className="heading">
                            {dbUser.name} {dbUser.surname}
                        </Typography>
                        <Typography className="expertise" color="textSecondary">
                            {dbUser.consultancyType}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {userID &&
                <>
                    {widthLessThan1000px ? (
                        <div className={`sidebar_mini ${bannerOn ? 'sidebar_banner_on' : ''}`}>
                            <div className="sidebar_header">
                                <img className="sidebar_logo_mini" alt="" src={appLogo} />
                            </div>
                            {isConsultant && userID && orientationPortrait && widthLessThan650px && <Notifications fromSideBar={true} />}
                            <IconButton
                                style={{ padding: 0, margin: 12, marginTop: 0, cursor: 'pointer' }}
                                color="inherit"
                                onClick={handleDrawer}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor='left'
                                open={open}
                                onClose={handleDrawer}
                            >
                                <BigMenu />
                            </Drawer>
                            <div className="sidebar_items">
                                <SideBarItems isMini />
                            </div>
                            {isConsultant && user && orientationPortrait && widthLessThan650px &&
                                <Button className="sidebar_logout" onClick={handleLogOut} color="inherit">
                                    <img className='navigation_logout_button' alt="" src={require("../../images/power.svg")} />
                                </Button>
                            }
                            {isConsultant &&
                                <div className="sidebar_copy_link">
                                    <Button
                                        className="home_copy_link_btn"
                                        onClick={() => {
                                            copy(dbUser.profileLink);
                                        }}
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                    >
                                        <Share />
                                    </Button>
                                </div>
                            }
                            <div className="sidebar_footer">
                                <img className='profile_pic' alt="" src={profilePic ?? require("../../images/user.png")} />
                            </div>
                        </div>
                    ) : (
                        <BigMenu />
                    )}
                </>
            }

            {showLogoutConfirmation &&
                <Confirmation
                    content="Are you sure you want to logout ?"
                    onClose={setShowLogoutConfirmation}
                    onAction={async () => {
                        setShowLogoutConfirmation(false)
                        await dispatch(executeLogout()).unwrap()
                        removeBooking()
                    }}
                />
            }
        </>
    )
};

import React from 'react'
import 'firebase/functions'
import ClientDetailsForm from './ClientDetailsForm';
import useCookieTheme from '../../../App/useCookieTheme';

const AdditionalInformation = () => {
  const { consultantTitle } = useCookieTheme()

  return (
    <div className='item'>
      <div className='margin_item'>
        <p className='confirm_booking_title'>ADDITIONAL INFORMATION FOR YOUR {consultantTitle.toUpperCase()}</p>
        <ClientDetailsForm />
      </div>
    </div>
  )
}

export default AdditionalInformation;

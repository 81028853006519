import React, { useState } from 'react'
import 'firebase/functions'
import { typeOptions } from '..';
import { formattedIntegerLocaleSeparator, localeToCurrency } from '../../../../utils/utils';
import moment from 'moment';
import momentTimezone from 'moment-timezone'
import { useBookingContext } from '../BookingContext';
import EditBirthInfosDialog from '../../../AccountDetailsClient/EditBirthInfosDialog';
import { Button, capitalize } from '@material-ui/core';
import AddToCalendar from 'react-add-to-calendar';
import { config } from '../../../../config/config';
import useCookieTheme from '../../../App/useCookieTheme';
import { useSelector } from 'react-redux';
import { userState } from '../../../../api/userSlice';

const BookingReviewDetails = (props) => {
  const { isGift, rescheduleBookingID } = props
  const { 
    bookingID,
    bookingData,
    contactInfoData,
    paymentInfoData,
    birthInfosData,
    setBirthInfosData,
    additionalInfoData,
    couponData,
    giftData,
    isDetailsForm,
    isAllSet,
    consultantData,
    isTenPercentDiscount
  } = useBookingContext()
  const userID = useSelector(userState).user?.userID
  const { name, consultantTitle, appointmentName } = useCookieTheme()
  
  const [birthInfosDialog, setBirthInfosDialog] = useState(false)
  
  const typeOption = typeOptions(false, true).find(elem => elem.id === bookingData.data.callType);
  const event = {
    title: `${config.type === 'astrologer' ? '[ASTROLOGER CONNECT] ': `[${name.toUpperCase()}]`}Your ${appointmentName ? capitalize(appointmentName) : 'Appointment'} with ${bookingData.data.consultantName}`,
    description: `At the time of your session, please head over to ${userID ? config.webSite : `${config.webSite}/call/${bookingID}`} and click "Start Call" in order to start your session. You can do this from both a desktop computer or a mobile device.
When the ${consultantTitle} answers the call, your appointment will begin.`,
    startTime: bookingData.data.startDate,
    endTime: moment(bookingData.data.startDate).add(bookingData.data.length, 'minutes').toDate(),
    location: ''
  }

  return (
    <div>
      <span className='review_call_type heading'>{typeOption.label} {config.type === 'astrologer' && capitalize(appointmentName)}</span><br />
      {bookingData.data.length} minutes
      {!couponData && !rescheduleBookingID && <span> - <span className={`${isTenPercentDiscount ? 'cut_text' : ''}`}>{localeToCurrency(bookingData.data.locale)}</span>{bookingData.data.estimatedPrice < 0 ? ' FREE' : <span className={`${isTenPercentDiscount ? 'cut_text' : ''}`}>{formattedIntegerLocaleSeparator(isTenPercentDiscount ? bookingData.data.oldPrice : bookingData.data.estimatedPrice, bookingData.data.locale)}</span>} {isDetailsForm && config.type === 'astrologer' && <i>(plus tax)</i>}</span>}<br />
      {isTenPercentDiscount && <b>Yout Price Today: {localeToCurrency(bookingData.data.locale)}{formattedIntegerLocaleSeparator(bookingData.data.estimatedPrice, bookingData.data.locale)}<br /></b>}
      {!isDetailsForm && <br />}
      {!isGift && 
        <>
          {`${moment(bookingData.data.startDate).format('MMMM D, YYYY @h:mma')} ${momentTimezone().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).zoneAbbr()} `}
          {isAllSet &&
            <AddToCalendar
              rootClass='add_to_calendar'
              buttonLabel='add to calendar'
              buttonClassOpen='add_to_calendar_button'
              buttonClassClosed='add_to_calendar_button'
              dropdownClass='add_to_calendar_dropdown'
              event={event}
            />
          }
      </>}
      {!isDetailsForm &&
        <>
          {!isGift && <><br /><br /></>}
          {config.type === 'astrologer' && !couponData && !rescheduleBookingID &&
            <>
              {localeToCurrency(bookingData.data.locale)}{formattedIntegerLocaleSeparator(bookingData.data.estimatedPrice * 1.04, bookingData.data.locale)} TOTAL<br />
              <i>includes tax</i><br /><br />
            </>
          }
          {isGift &&
            <span className='underline_text'><i>From:</i><br /></span>
          }
          {contactInfoData.firstName} {contactInfoData.lastName}<br />
          {contactInfoData.email}<br />
          {!isAllSet && !couponData && !rescheduleBookingID && <>{paymentInfoData.stripeDefaultPMHint}<br /></>}
          {isGift ?
            <>
              <br />
              <span className='underline_text'><i>To:</i><br /></span>
              {giftData.name}<br />
              {giftData.email}<br />
              <span className='underline_text'><b>Delivery date:</b></span> {moment(giftData.deliveryDate).format('MMMM D, YYYY')}<br />
              {giftData.customMessage && `${giftData.customMessage}`}
              {giftData.customMessage && <br />}
            </>
          :
            <>
              {consultantData.needBirthForm &&
                <>
                  <br />
                  {moment(birthInfosData.birthDate).format(isAllSet ? 'MMMM D, YYYY' : 'MMMM D, YYYY @h:mma')}{isAllSet ? 
                    <>
                      <Button
                        className='dismiss_buttton'
                        onClick={() => setBirthInfosDialog(true)}
                        color="primary"
                        variant="outlined"
                      >
                        <i>edit</i>
                      </Button>
                      <br />
                      {moment(birthInfosData.birthDate).format('@h:mma')}
                    </>
                  : ''}<br />
                  {birthInfosData.city}, {birthInfosData.state ? `${birthInfosData.state}, ` : ''}{birthInfosData.country}
                </>
              }
            </>
          }
        </>
      }

      {isAllSet && !isGift && consultantData.needBirthForm &&
        <>
          <br /><br />
          <i>What question would you like to ask your astrologer?</i><br />
          {additionalInfoData?.questionsText ?? '-'}<br /><br />
          <i>Have you ever had a reading before?</i><br />
          {additionalInfoData?.readingInThePast ?? '-'}<br /><br />
          <i>Anything else you'd like to share?</i><br />
          {additionalInfoData?.anythingElseText ?? '-'}
        </>
      }
        
      {birthInfosDialog && 
        <EditBirthInfosDialog
          handleClose={() => setBirthInfosDialog(false)}
          open={birthInfosDialog}
          bookingID={bookingID}
          birthInfosState={birthInfosData}
          setBirthInfoState={setBirthInfosData}
          sendEmail={true}
          consultantID={consultantData?.userID}
          clientEmail={contactInfoData.email}
        />
      }
      
    </div>
  )
}

export default BookingReviewDetails;
